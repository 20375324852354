<template>
    <div class="simple-table-wrapper simple-table-wrapper-lg d-flex flex-column flex-grow-1" v-if="!portal.isLoading">
        <form class="search-form mb-5" @submit.prevent="portal.searchModel = searchModel">
            <div class="search-fields d-flex flex-wrap align-items-center">
                <input
                    class="st-search w-100 w-md-auto"
                    type="search"
                    v-model="searchModel"
                    placeholder="Search..."
                />
                <button type="submit" class="btn btn-primary ml-2"><span class="fas fa-search"></span> APPLY FILTERS</button>
            </div>
        </form>

        <div class="st-hidden-sorts">
            <p @click="portal.sortBy('name')" :class="portal.sortClass('name')">Name</p>
            <p @click="portal.sortBy('email', null, 'date')" :class="portal.sortClass('email')">Email</p>
        </div>

        <div class="simple-table flex-grow-1 overflow-auto" style="--cols:4;flex-basis:0;align-content:start;">
            <p class="first-col st-header" @click="portal.sortBy('name')" :class="portal.sortClass('name')">Name</p>
            <p class="st-header">Active</p>
            <p class="st-header" @click="portal.sortBy('email', null, 'date')" :class="portal.sortClass('email')">Email</p>
            <p class="last-col st-header">Options</p>

            <template v-for="row in portal.displayedList">
                <div class="first-col" :key="row.id+'name'">
                    <span class="st-hidden">Name</span>
                    {{ row.name }}
                </div>
                <div :key="row.id+'email'">
                    <span class="st-hidden">Email</span>
                    {{ row.email }}
                </div>

                <p :key="row.id+'is_active'">
                    <span class="st-hidden">Active?</span>
                    {{ row.status }}
                </p>

                <div :key="row.id+'options'">
                    <span class="st-hidden">Options</span>
                    <div class="st-buttons">
                        <button type="button" class="btn btn-primary" @click.prevent="modifyPortal(row)">Modify</button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="recyclePortal(row)">Delete</button>
                    </div>
                </div>
            </template>
        </div>

        <p>{{ portal.rangeText }}</p>

        <Pagination
            :active-page="portal.activePage"
            :item-count="portal.itemCount"
            :items-per-page="portal.itemsPerPage"
            @on-update="portal.updateActivePage($event)"
        />
    </div>
    <VueLoader v-else />
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";
    import EloquentSSFP from '@j/core/global/EloquentSSFP';
    export default {
        name: "VpanelPortalList",
        data() {
            return {
                portal: new EloquentSSFP('/vpanel/modules/portal/user-list', {
                    activeSorts: ["email","name"],
                    sortDirs: ["desc"],
                    sortTypes: ["string","string"],
                    searchableData: ["name", "email"],
                    itemsPerPage: 10
                }),
                searchModel: ""
            }
        },
        methods: {
            modifyPortal(row){
                this.SET_ACTIVE_RECORD(row);
                this.setFormIsAdd(false);
                this.openModal(true);
            },
            recyclePortal(row){
                this.SET_ACTIVE_RECORD(row);
                this.openRecycleModal(null);
            },
            ...mapActions("portal",{openRecycleModal:"recyclePortalModal/openModal"}),
            ...mapMutations("portal/addModifyPortal",["SET_ACTIVE_RECORD"]),
            ...mapActions("portal/addModifyPortal",["setFormIsAdd","openModal"]),
        },
        computed: {
            ...mapState("portal/list", {
                cspNonce: (state) => state.cspNonce,
                isUpdatedIncrement: (state) => state.isUpdatedIncrement,
            }),
        },
        watch: {
            isUpdatedIncrement(n,o) {
                this.portal.refreshData(this.portal.options);
            },
            searchModel(n,o) {
                if (!n && o) {
                    this.portal.updateSearch(n);
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../../../../Core/Global/Components/VueTable/scss/_table-styles.scss';

    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(3, auto) max-content;
    }
</style>