<template>
  <div class="funeral-list-view d-flex flex-column m-n1 flex-grow-1">
    <div class="flex-grow-1">
      <div
        v-if="errorMessage"
        class="alert alert-danger form-errors alert-dismissible m-1 flex-shrink-0"
        v-html="errorMessage"
      ></div>
      <div
        v-if="successMessage"
        class="alert alert-success alert-dismissible m-1 flex-shrink-0"
        v-html="successMessage"
      ></div>
      <SiteFuneralList class="m-1" />
      <SiteAddModifyFuneralModal />
      <SiteRecycleFuneralModal />
    </div>
    <AddNewFuneralButtonPortal class="m-1 flex-shrink-0 align-self-end" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FuneralsIndex',
  computed: {
    ...mapState('siteFuneral/addModify', [
      'errorMessage',
      'successMessage',
    ]),
    ...mapState([
      'userData',
    ]),
  },
};
</script>
