<template>
    <div>
        <h4 class="mb-1">{{ section }}</h4>

        <p class="mb-4"><small>Basic information for legal forms.</small></p>

        <div class="input-grid">
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.known"
              name="Name Known As"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.first_name"
              name="First Name"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.middle_name"
              name="Middle Name"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.last_name"
              name="Last Name"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.title"
              name="Title"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.gender"
              name="Gender"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.suffix"
              name="Suffix"
            />
            <VueDate
              v-model="activeRecord.funeral_bio.biography_info_fe.birth_date"
              name="Birth Date"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.birth_city"
              name="Birth City"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.birth_state"
              name="Birth State"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.birth_country"
              name="Birth Country"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.citizen_of"
              name="Citizen Of"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.sex"
              name="Sex"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.race"
              name="Race"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.address"
              name="Address"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.city"
              name="City"
            />
            <VueUsStates
              v-model="activeRecord.funeral_bio.biography_info_fe.state"
              name="State"
            />
            <VueZipCode
              v-model="activeRecord.funeral_bio.biography_info_fe.zip"
              name="Zip"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.phone"
              name="Phone"
              type="tel"
            />
            <VueCheckbox
              v-model="activeRecord.funeral_bio.biography_info_fe.inside_city_limits"
              name="Inside City Limits?"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.father_name"
              name="Father's name"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.mother_name"
              name="Mother's name including Maiden Name"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.education_level"
              name="Highest Education Level"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.occupation"
              name="Occupation"
            />
            <VueCheckbox
              v-model="activeRecord.funeral_bio.biography_info_fe.us_armed_forces"
              name="US Armed Forces"
            />
            <VueTextarea
              v-show="activeRecord.funeral_bio.biography_info_fe.us_armed_forces"
              v-model="activeRecord.funeral_bio.biography_info_fe.dd_214"
              name="Where is a copy of DD-214"
            />
            <VueSelect
              v-model="activeRecord.funeral_bio.biography_info_fe.marital_status"
              name="Marital Status"
              :options="marital_options"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.nok"
              name="Next Of Kin / Spouse"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.nok_address"
              name="Next Of Kin Address"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.nok_city"
              name="Next Of Kin City"
            />
            <VueUsStates
              v-model="activeRecord.funeral_bio.biography_info_fe.nok_state"
              name="Next Of Kin State"
            />
            <VueZipCode
              v-model="activeRecord.funeral_bio.biography_info_fe.nok_zip"
              name="Next Of Kin Zip"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.nok_phone"
              name="Next Of Kin Phone"
              type="tel"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.nok_email"
              name="Next Of Kin Email"
              type="email"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.estate_executor"
              name="Executor Of Estate"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.executor_address"
              name="Executor of Estate Address"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.executor_city"
              name="Executor of Estate City"
            />
            <VueUsStates
              v-model="activeRecord.funeral_bio.biography_info_fe.executor_state"
              name="Executor of Estate State"
            />
            <VueZipCode
              v-model="activeRecord.funeral_bio.biography_info_fe.executor_zip"
              name="Executor of Estate Zip"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.executor_phone"
              name="Executor of Estate Phone"
              type="tel"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.executor_email"
              name="Executor of Estate Email"
              type="email"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.cemetery"
              name="Cemetery (if applicable)"
            />
            <VueInput
              v-model="activeRecord.funeral_bio.biography_info_fe.church_affiliation"
              name="Church Affiliation (if applicable)"
            />
            <VueCheckbox
              v-model="activeRecord.funeral_bio.update_ssn"
              v-if="hasSSN" name="Do you want to update the SSN?"
            />
            <VuePassword
              class="password"
              name="ssn"
              v-model="activeRecord.funeral_bio.ssn"
              v-if="!hasSSN || activeRecord.funeral_bio.update_ssn"
            />

            <!-- this seems redundant with the other info...todo ask client
            <VueCheckbox
              v-model="activeRecord.funeral_bio.biography_info_fe.hispanic_origin"
              name="Hispanic In Origin"
            />
            <VueTextarea
              v-show="activeRecord.funeral_bio.biography_info_fe.hispanic_origin"
              v-model="activeRecord.funeral_bio.biography_info_fe.hispanic_origin_specify"
              name="Hispanic Origin Specify"
            /> -->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DecedentBiographical',
  props: ['section'],
  data() {
    return {
      editSsn: null,
      marital_options: [
        { name: 'Married', value: 'married' },
        { name: 'Never Married', value: 'never_married' },
        { name: 'Widowed', value: 'widowed' },
        { name: 'Divorced', value: 'divorced' },
      ],
    };
  },
  computed: {
    ...mapState('siteFuneral/addModify', [
      'activeRecord',
    ]),
    hasSSN() {
      return !!(this.activeRecord.funeral_bio.has_ssn);
    },
  },
};
</script>
