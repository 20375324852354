<template>
  <div
    class="com-hub"
    :class="{
      'show-files': showFileManager,
      'show-left-bar': showChatList,
      'in-funeral': inFuneral
    }"
  >
    <div class="conversation-search">
      <VueSearch
        group-class="m-0"
        name="Search conversations"
        v-model="conversationSearch"
      />
    </div>

    <div class="chat-room-list">
      <ChatRoomList :search-value="conversationSearch" />
    </div>

    <div class="contact-list">
      <ContactList :contact-list="contactList" />
    </div>

    <div class="chat-top-bar">
      <div class="ctb-left">
        <p
          class="menu-toggle"
          @click="toggleChatList(!showChatList)"
        >
          <FontAwesomeIcon :icon="iconBars" />
        </p>
        <p class="header">{{ headerText }}</p>
      </div>
      <div class="ctb-right">
        <div class="form-group m-0 pr-0 pr-md-2 pb-2 pb-md-0">
          <div class="input-wrapper form-control">
            <input
              type="checkbox"
              name="Enable Emails?"
              id="enable_emails_69"
              title="By enabling emails you will receive an email notification that you have a new message from this specific person"
              @click="changeNotificationStatus"
              :checked="areNotificationsEnabled"
            />
            <label for="enable_emails_69" class="form-label">
              Enable Email Notifications?
            </label>
          </div>
        </div>
        <div class="form-group m-0">
          <div class="input-wrapper form-control">
            <input
              type="checkbox"
              name="Appear as offline?"
              id="appear_as_offline__12"
              title="Enter an appear as offline?"
              @click="changeStatus"
              :checked="userIsOffline"
            />
            <label
              for="appear_as_offline__12"
              class="form-label"
            >
              Appear as offline?
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="chat-window">
      <ChatLoader v-if="showChatLoader" />

      <p class="my-2" v-if="!activeChatContact && !showChatLoader">
        Select a conversation to start chatting
      </p>

      <div class="chat-messages" v-if="!showChatLoader">
        <ChatMessage
          v-for="(messageObj, index) in messages"
          :previous-message-from-same-user="previousMessageFromSameUser(index)"
          :previous-message-is-recent="previousMessageIsRecent(index)"
          :message-obj="messageObj"
          :key="`message_${index}`"
        />
      </div>
    </div>

    <div class="chat-composer">
      <form
        class="mx-n1 my-0"
        @submit.prevent="sendChat"
        @input="clearMessageEmptyText"
        novalidate
      >
        <div class="mb-0 mx-1 flex-grow-1">
          <VueInput
            autocomplete="off"
            required
            name="Type a message"
            v-model="chatMessage"
            group-class="m-0"
          />
          <div
            class="alert alert-danger"
            v-show="messageEmptyText"
            v-html="messageEmptyText"
          ></div>
        </div>

        <button
          class="text-primary text-24 border-0 bg-transparent"
          type="submit"
          title="Send message"
        >
          <FontAwesomeIcon :icon="iconPaperPlane" />
        </button>
        <button
          class="border-0 bg-transparent file-btn"
          @click.prevent="toggleFileManager(!showFileManager)"
          :title="[
            showFileManager
              ? 'Hide file uploader'
              : 'Show file uploader',
          ]"
        >
          <p
            class="m-0 text-primary text-24"
            v-show="showFileManager"
          >
            <FontAwesomeIcon :icon="iconFolderOpen" />
          </p>
          <p
            class="m-0 text-primary text-24"
            v-show="!showFileManager"
          >
            <FontAwesomeIcon :icon="iconFolder" />
          </p>
          <span>View/Download<br>Files</span>
        </button>
      </form>
    </div>
    <div class="files-wrapper">
      <FileManager />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faPaperPlane, faFolder, faFolderOpen, faBars,
} from '@fortawesome/free-solid-svg-icons';
import { mapState, mapActions } from 'vuex';
import VueInput from '../../../../../../Core/Global/Components/VueForms/VueInput.vue';
import VueSearch from '../../../../../../Core/Global/Components/VueForms/VueSearch';
import ChatRoomList from './ChatRoomList';
import ChatMessage from './ChatMessage';
import ChatLoader from './ChatLoader';
import ContactList from './ContactList';
import FileManager from './FileManager';

export default {
  props: ['chatRoomIds'],
  components: {
    ChatRoomList,
    ChatMessage,
    ChatLoader,
    ContactList,
    FileManager,
    VueInput,
    VueSearch,
    FontAwesomeIcon,
  },
  data() {
    return {
      chatMessage: '',
      conversationSearch: '',
      messageEmptyText: '',
      iconPaperPlane: faPaperPlane,
      iconFolder: faFolder,
      iconFolderOpen: faFolderOpen,
      iconBars: faBars,
    };
  },
  computed: {
    ...mapState({
      chatRoomList: (state) => state.chatrooms,
      user: (state) => state.userData,
    }),
    ...mapState('chat/chat', {
      messages: (state) => state.messages,
      activeChatContact: (state) => state.active_chat_contact,
      showChatLoader: (state) => state.show_chat_loader,
      contactList: (state) => state.contact_list,
      messageSending: (state) => state.message_sending,
      activeChatRoomId: (state) => state.active_chat_room_id,
      showFileManager: (state) => state.show_file_manager,
      showChatList: (state) => state.show_chat_list,
      userChatStatus: (state) => state.current_user_status,
      notificationsStatus: (state) => state.are_notifications_enabled,
    }),
    headerText() {
      if (this.showChatLoader) {
        return 'Loading conversation...';
      } if (this.activeChatContact) {
        return this.activeChatContact.username;
      }
      return 'Select a conversation';
    },
    userIsOffline() {
      return this.userChatStatus == 'offline';
    },
    areNotificationsEnabled() {
      return this.notificationsStatus == 'enabled';
    },
    inFuneral() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions('chat/chat', [
      'selectChatRoom',
      'getContactsList',
      'subscribeToChatRoom',
      'sendChatMessage',
      'listenToUserActivityChannel',
      'toggleFileManager',
      'toggleChatList',
      'setCurrentUserStatus',
      'setCurrentUserId',
      'setNotificationStatus',
    ]),
    sendChat() {
      const message = this.chatMessage;
      this.chatMessage = '';

      if (!message) {
        this.messageEmptyText = 'Please enter text to send message';
      } else {
        this.sendChatMessage(message);
      }
    },
    changeStatus() {
      const status = event.target.checked ? 'offline' : 'online';

      axios
        .post('/dashboard/messenger/change-status', {
          status,
        })
        .then((res) => this.setCurrentUserStatus(status))
        .catch((error) => console.error(error));
    },
    changeNotificationStatus() {
      const status = event.target.checked ? 'enabled' : 'disabled';

      this.setNotificationStatus({
        status,
        chatRoomId: this.activeChatRoomId,
      });
    },
    clearMessageEmptyText() {
      this.messageEmptyText = '';
    },
    previousMessageFromSameUser(index) {
      return index && ((index - 1) >= 0) && this.messages[index].name === this.messages[index - 1].name;
    },
    previousMessageIsRecent(index) {
      if (!index || (index - 1) < 0) return false;
      const prevDate = dayjs(this.messages[index - 1].date_sent);
      const currDate = dayjs(this.messages[index].date_sent);
      // Previous message was sent within 10 minutes of the current one
      return currDate.isBefore(prevDate.add(10, 'm'));
    },
  },
  created() {
    const vm = this;
    this.listenToUserActivityChannel();

    for (let index = 0; index < this.chatRoomList.length; index++) {
      const chatRoomId = this.chatRoomList[index].id;
      this.subscribeToChatRoom(chatRoomId);
    }

    if (vm.inFuneral) {
      const chatRoom = vm.chatRoomList.find((c) => c.funeral_id == vm.inFuneral);
      vm.selectChatRoom({ contact: chatRoom });
      vm.getContactsList(vm.inFuneral);
    }
  },

};
</script>

<style lang="scss" scoped>
@import "../../../../../../../sass/_vue-import.scss";
.com-hub {
  display: grid;
  border: 1px solid hsl(0deg,0%,45%);
  margin: 0;
  overflow: hidden;
  flex-grow: 1;

  &.in-funeral {
    .conversation-search { display: none; }
    .chat-room-list { display: none; }
    .menu-toggle { display: none; }
  }

  @include not-mobile {
    grid-template:
      "tl tr" max-content
      "tml tmr" 1fr
      "bml bmr" max-content
      "bl br" max-content
      / 300px 1fr;

    &.in-funeral {
      grid-template:
        "tr" max-content
        "tmr" 1fr
        "bmr" max-content
        "br" max-content
        / 100%;
    }

    .conversation-search { grid-area: tl; }
    .chat-room-list { grid-area: tml-start / tml-start / bl-end / bl-end; }
    .contact-list { grid-area: tmr; }
    .chat-window { grid-area: tmr-start / tmr-start / bmr-end / bmr-end; }
    .chat-composer { grid-area: br; }

    .files-wrapper {
      grid-area: tmr-start / tmr-start / bmr-end / bmr-end;
      position: relative;
      left: 100%;
      transition: 200ms ease-out;
    }

    &.show-files .files-wrapper { left: 0; }

    .chat-top-bar {
      grid-area: tr;
      align-items: center;
      justify-content: space-between;
    }

    .ctb-left { padding-right: 1rem; }

    .ctb-right {
      display: flex;
      flex-direction: row;
    }

    .menu-toggle { display: none; }
  }

  @include mobile {
    grid-template:
      "tt" max-content
      "t" max-content
      "tm" 1fr
      "bm" max-content
      "b" max-content
      / 1fr;

    .conversation-search {
      grid-area: t;
    }
    .chat-room-list {
      grid-area: tm;
    }
    .contact-list {
      grid-area: t;
    }
    .chat-top-bar {
      grid-area: tt;
    }
    .chat-window {
      grid-area: t-start / t-start / bm-end / bm-end;
    }
    .chat-composer {
      grid-area: b;
    }
    .files-wrapper {
      grid-area: tm-start / tm-start / bm-end / bm-end;
    }

    .conversation-search,
    .chat-room-list,
    .files-wrapper {
      position: relative;
      transition: 200ms ease-out;
      background-color: white;
    }

    .conversation-search,
    .chat-room-list {
      right: 100%;
      z-index: 3;
    }

    &.show-left-bar {
      .conversation-search,
      .chat-room-list {
        right: 0;
      }
    }

    .files-wrapper {
      left: 100%;
      z-index: 2;
    }

    &.show-files .files-wrapper {
      left: 0;
    }

    .chat-top-bar {
      align-items: stretch;
      flex-direction: column;
      padding: 1rem;
    }

    .ctb-left {
      margin-bottom: 0.5rem;
    }
    .ctb-right {
      display: flex;
      flex-direction: column;
    }

    .menu-toggle {
      font-size: 20px;
      margin: 0 1rem 0 0;
      cursor: pointer;
    }
  }
}

.ctb-left {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.chat-top-bar {
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: .5rem;

  .header {
    @include fluid-size(16px, 20px, font-size);
    font-weight: bold;
    color: var(--primary);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .form-group {
    flex-shrink: 0;

    &::v-deep {
      .input-wrapper {
        font-size: 14px;
        display: flex;
        align-items: center;

        input { margin-right: .25rem; }
      }

      label {
        margin: 0;
        line-height: 1;
      }
    }
  }
}

.conversation-search {
  padding: .5rem;
  background-color: #f0f0f0;
  border-right: 1px solid #ddd;
}

.chat-composer {
  padding: 1rem;
  border-top: 1px solid #ddd;

  form {
    display: flex;
    align-items: center;
  }

  .btn {
    border-radius: 50rem;
    flex-shrink: 0;
  }
}

.file-btn {
  display: flex;
  align-items: center;
  gap: .5rem;

  span {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
  }
}

.chat-room-list,
.chat-window {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: var(--light-purple);
  } // track
  &::-webkit-scrollbar-thumb {
    background: var(--primary);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  } // thumb on hover

  .chat-messages {
    padding-top: 2rem;
  }
}

.contact-list {
  width: 100%;
  z-index: 1;
  pointer-events: none;
}

.chat-room-list {
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.chat-window {
  padding: 1rem;
  display: flex;
  flex-direction: column-reverse;
}
</style>
