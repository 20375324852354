<template>
    <div class="message-item" :class="messageClass">
        <p class="message-when-group" v-if="!previousMessageIsRecent">{{ datetimeSentFormatted }}</p>
        <p class="message-sent" v-if="!previousMessageFromSameUser"><small>{{ isUser ? "You" : messageObj.name }}</small></p>
        <p class="message-bubble" @click="peek()" :title="datetimeSent">{{ messageObj.message }}</p>
        <p class="message-when" :class="{peek: whenPeek}"><small>{{ datetimeSentFormatted }}</small></p>
    </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

export default {
    props: [
        'messageObj',
        'previousMessageFromSameUser',
        'previousMessageIsRecent',
    ],
    data() {
        return {
            whenPeek: false
        }
    },
    computed: {
        isUser() {
            return this.user.messenger_id == this.messageObj.messenger_id;
        },
        messageClass() {
            return this.isUser ? 'us' : 'them';
        },
        datetimeSent() {
            return dayjs(this.messageObj.date_sent).format("MMM Do @ h:mm a");
        },
        datetimeSentFormatted() {
            return dayjs(this.messageObj.date_sent).fromNow();
        },
        ...mapState({
            user: state => state.userData,
        }),
    },
    methods: {
        peek() {
            let vm = this;
            if (vm.previousMessageIsRecent) {
                vm.whenPeek = true;
                setTimeout(() => vm.whenPeek = false, 7000)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .message-item {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        margin-bottom: 8px;

        &.us {
            align-items: flex-end;
        }

        &.them {
            align-items: flex-start;
        }
    }

    .message-sent {
        font-size: 14px;
        margin: 0;
        color: var(--primary);
        font-weight: bold;
    }

    .message-bubble {
        font-size: 14px;
        line-height: 1.25;
        width: max-content;
        max-width: 90%;
        padding: 1rem;
        background-color: #f0f0f0;
        border-radius: 5px;
        margin: 0;

        .message-item.us & {
            background-color: var(--primary);
            color: white;
        }

        .message-item.them & {
            background-color: var(--secondary);
        }
    }

    .message-when {
        font-size: 14px;
        margin: 0;
        font-weight: bold;
        height: 0;
        overflow: hidden;
        transition: 200ms ease-out;

        &.peek {
            height: 1rem;
            margin-bottom: 8px;
        }
    }

    .message-when-group {
        font-size: 12px;
        margin: 0;
        color: #767676;
        margin-top: 8px;
        align-self: center;
    }

</style>