<template>
    <div class="datatable-section p-3 simple-table-wrapper simple-table-wrapper-lg d-flex flex-column flex-grow-1" v-if="!cart.isLoading">
        <add-modify-cart-modal />
        <recycle-cart-modal @refresh="refreshPage()" />

        <form class="search-form mb-5" @submit.prevent="cart.searchModel = searchModel">
            <div class="search-fields d-flex flex-wrap align-items-center">
                <input
                    type="search"
                    v-model="searchModel"
                    placeholder="Search..."
                    class="st-search w-100 w-md-auto"
                />
                <button type="submit" class="btn btn-primary ml-2">
                    <span class="fas fa-search"></span> APPLY FILTERS
                </button>
            </div>
        </form>

        <div class="st-hidden-sorts">
            <p @click="cart.sortBy('decedent')" :class="cart.sortClass('decedent')">Decedent</p>
            <p>User</p>
            <p @click="cart.sortBy('created_at', null, 'date')" :class="cart.sortClass('created_at')">Order Date</p>
            <p>Options</p>
        </div>

        <div class="simple-table flex-grow-1 overflow-auto" style="--cols: 4;flex-basis:0;align-content:start;">
            <p class="first-col st-header" @click="cart.sortBy('name')" :class="cart.sortClass('name')">Decedent</p>
            <p class="st-header">User</p>
            <p class="st-header" @click="cart.sortBy('created_at', null, 'date')" :class="cart.sortClass('created_at')">Order Date</p>
            <p class="last-col st-header">Options</p>

            <template v-for="row in cart.displayedList">
                <div class="first-col" :key="row.id + 'decedent'">
                    <span class="st-hidden">Decedent</span>
                    {{ row.decedent }}
                </div>

                <div :key="row.id + 'user'">
                    <span class="st-hidden">User</span>
                    {{ row.user }}
                </div>

                <p :key="row.id + 'created_at'">
                    <span class="st-hidden">Order Date</span>
                    {{ row.created_at }}
                </p>

                <div :key="row.id + 'options'">
                    <span class="st-hidden">Options</span>
                    <div class="st-buttons">
                        <button type="button" class="btn btn-primary" @click.prevent="modifyCart(row)">Modify</button>
                        <button type="button" class="btn btn-secondary-blue" @click.prevent="goToUploads(row)">Uploads</button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="recycleCart(row)">Delete</button>
                    </div>
                </div>
            </template>
        </div>

        <p>{{ cart.rangeText }}</p>

        <Pagination
            :active-page="cart.activePage"
            :item-count="cart.itemCount"
            :items-per-page="cart.itemsPerPage"
            @on-update="cart.updateActivePage($event)"
        />
    </div>

    <VueLoader v-else />
</template>

<script>
import EloquentSSFP from "@j/core/global/EloquentSSFP";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    data() {
        return {
            cart: new EloquentSSFP("/vpanel/modules/cart/list", {
                activeSorts: ["created_at","decedent"],
                sortDirs: ["desc"],
                sortTypes: ["date","string"],
                searchableData: ["decedent","user_name"],
                itemsPerPage: 10,
            }),
            searchModel: "",
        };
    },
    computed: {
        ...mapState("cart/addModifyCart", {
            activeRecord: (state) => state.activeRecord,
        }),
    },
    watch: {
        isUpdatedIncrement(n, o) {
            this.cart.refreshData(this.cart.options);
        },
        searchModel(n, o) {
            if (!n && o) {
                this.cart.updateSearch(n);
            }
        },
    },
    methods: {
        ...mapActions("cart/addModifyCart", [
            "setFormIsAdd",
            "openModal",
            "closeModal",
        ]),
        ...mapMutations("cart/addModifyCart", ["SET_ACTIVE_RECORD"]),
        modifyCart(cart) {
            this.SET_ACTIVE_RECORD(cart);
            this.setFormIsAdd(false);
            this.openModal();
        },
        goToUploads(cart){

            this.SET_ACTIVE_RECORD(cart);
            this.$router.push({name:"CartDocuments"});
        },
        ...mapActions("cart/recycleCartModal", {
            openRecycleModal: "openModal",
        }),
        recycleCart(cart) {
            this.SET_ACTIVE_RECORD(cart);

            this.openRecycleModal();
        },
        refreshPage() {
            this.cart.refreshData(this.cart.options);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../../../../Core/Global/Components/VueTable/scss/_table-styles.scss";

.simple-table {
    // Custom col structure to make the last col only be as wide as it needs to be
    --custom-col-structure: repeat(3, auto) max-content;
}
</style>
