<template>
	<div>
		<h3>{{ activeRecord.order.funeral }}'s Funeral Documents </h3>

        <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>
		<div v-if="successMessage" class="alert alert-success">{{successMessage}}</div>

		<form
			id="document-form"
			method="post"
			enctype="multipart/form-data"
			@submit.prevent="upload()"
		>
			<input type="hidden" name="_token" :value="activeRecord._token" />
			<input type="hidden" name="cart_uuid" :value="activeRecord.order.cart_uuid" />
			<input type="hidden" name="cart_id" :value="activeRecord.order.id" />

			<VueSelect

				name="file_type"
				:float-label="false"
				v-model="activeRecord.file_type"
				autocomplete="off"
				:options="options"
				required
			/>

			<VueInput
				label="Document/File Name"
				name="file_name"
				:float-label="false"
				v-model="activeRecord.name"
				required
			/>

			<div class="custom-file">
				<label>
					Add Document/ File*
					<input type="file" name="file">
				</label>
			</div>

			<button class="btn btn-primary">Upload</button>
		</form>

        <div
			v-for="file in fileList"
			:key="file.id"
		>
			<p> <strong>Type: </strong>  {{file.attributes.for}} <strong>| Name: </strong>  {{file.attributes.file_name}}</p>
            <div class="file-actions mt-1">
				<button @click="download(file.value)">Download File</button>
			</div>

		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	data() {
		return {
			options: [
				{ name: "Identification", value: "Identification" },
				{ name: "Military Discharge", value: "Military Discharge" },
				{ name: "Other", value: "other" },
			],
		};
	},
	computed: {
        ...mapState([
			"orders",
		]),
		...mapState("order/document", {
			activeRecord: (state) => state.activeRecord,
			errorMessage: (state) => state.errorMessage,
			successMessage: (state) => state.successMessage,
		}),
		fileList() {
			if (!this.orders || !this.orders.length) return [];
            let orderId = this.activeRecord.order.id;
			let order = this.orders.find((order) => order.id === orderId);

			if (!order) return [];
			return order.submissions.filter((submission) => {
				return submission.key === "file"
			});
		},
	},

	methods: {
        ...mapActions("order/document",[
			"submitForm"
		]),
        upload() {
            this.submitForm(new FormData(event.target));
        },
        download(file) {
			let formData = {
				url: file,
			};
			axios
				.post("/dashboard/documents/download", formData, {
					responseType: "blob",
				})
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data])
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", formData.url);
					document.body.appendChild(link);
					link.click();
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>

<style>
</style>