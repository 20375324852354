<template>
    <div class="simple-table-wrapper simple-table-wrapper-lg" v-if="!obituaries.isLoading">
        <form class="search-form mb-5" @submit.prevent="obituaries.searchModel = searchModel">
            <div class="search-fields d-flex flex-wrap align-items-center">
                <input
                    class="st-search w-100 w-md-auto"
                    type="search"
                    v-model="searchModel"
                    placeholder="Search..."
                />
                <button type="submit" class="btn btn-primary ml-2"><span class="fas fa-search"></span> APPLY FILTERS</button>
            </div>
        </form>

        <div class="st-hidden-sorts">
            <p @click="obituaries.sortBy('name')" :class="obituaries.sortClass('name')">Name</p>
            <p @click="obituaries.sortBy('date_of_death', null, 'date')" :class="obituaries.sortClass('date_of_death')">Date of Passing</p>
            <p>Condolences</p>
            <p>Options</p>
        </div>

        <div class="simple-table" style="--cols:4">
            <p class="first-col st-header" @click="obituaries.sortBy('name')" :class="obituaries.sortClass('name')">Name</p>
            <p class="st-header" @click="obituaries.sortBy('date_of_death', null, 'date')" :class="obituaries.sortClass('date_of_death')">Date of Passing</p>
            <p class="st-header">Condolences</p>
            <p class="last-col st-header">Options</p>

            <template v-for="row in obituaries.displayedList">
                <div class="first-col" :key="row.id+'name'">
                    <span class="st-hidden">Name</span>
                    {{ row.name }}
                </div>

                <div :key="row.id+'date_of_death'">
                    <span class="st-hidden">Date of Passing</span>
                    {{ row.date_of_death }}
                </div>

                <p :key="row.id+'condolences'">
                    <span class="st-hidden">Condolences</span>
                    {{ row.condolences }}
                </p>

                <div :key="row.id+'options'">
                    <span class="st-hidden">Options</span>
                    <div class="st-buttons">
                        <button type="button" class="btn btn-secondary" @click.prevent="modifyObituary(row)">Modify</button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="recycleObituary(row)">Delete</button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="syncObituary(row)">Sync</button>
                    </div>
                </div>
            </template>
        </div>

        <p>{{ obituaries.rangeText }}</p>
        <Pagination
            :active-page="obituaries.activePage"
            :item-count="obituaries.itemCount"
            :items-per-page="obituaries.itemsPerPage"
            @on-update="obituaries.updateActivePage($event)"
        />
    </div>
    <VueLoader v-else />
</template>

<script>
    import EloquentSSFP from '@j/core/global/EloquentSSFP';
    import { mapState, mapActions, mapMutations } from "vuex";

    export default {
        name: "VpanelObituaryList",
        data() {
            return {
				obituaries: new EloquentSSFP("/vpanel/modules/obituary/list", {
					activeSorts: ["date_of_death"],
					sortDirs: ["desc"],
					sortTypes: ["date"],
					searchableData: ["name"],
					itemsPerPage: 10
				}),
                searchModel: ""
            }
        },
        created() {
            document.addEventListener("click", this.buttonEvent);
        },
        beforeDestroy() {
            document.removeEventListener("click", this.buttonEvent);
        },
        computed: {
            ...mapState("obituary/list", {
                cspNonce: (state) => state.cspNonce,
                tableData: (state) => state.tableData,
                isUpdatedIncrement: (state) => state.isUpdatedIncrement,
            }),
        },
        methods: {
            ...mapActions("obituary/list", ["addClickEventsToListButtons"]),
            ...mapMutations("obituary/addModifyObituary", ["SET_ACTIVE_RECORD"]),
            ...mapActions("obituary/addModifyObituary", [
                "setFormIsAdd",
                "fetchPassareRecord",
            ]),
            ...mapActions("obituary/recycleObituaryModal", ["openModal"]),
            modifyObituary(obit) {
                this.SET_ACTIVE_RECORD(obit)
                this.setFormIsAdd(false);
                this.$router.push({ name: "ObituaryForm" });
            },
            recycleObituary(obit) {
                this.SET_ACTIVE_RECORD(obit)
                this.openModal(null)
            },
            syncObituary(obit) {
                this.SET_ACTIVE_RECORD(obit)
                this.fetchPassareRecord();
            }
        },
        watch: {
            isUpdatedIncrement(n,o) {
                this.obituaries.refreshData(this.obituaries.options);
            },
            searchModel(n,o) {
                if (!n && o) {
                    this.obituaries.updateSearch(n);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '../../../../../Core/Global/Components/VueTable/scss/_table-styles.scss';

    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(3, auto) max-content;
    }
</style>