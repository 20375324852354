<template>
  <div class="flex-grow-1">
    <h3 class="mb-4">Your Orders</h3>
    <div class="order-cards">
      <OrderCard
        v-for="order in orders"
        :key="order.id"
        :order="order"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState([
      'orders',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  .order-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
</style>
