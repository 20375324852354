<template>
  <div class="funeral-list" :class="{'dashboard-view': dashboardView}">
    <template v-if="Object.keys(funerals)">
      <div
        class="funeral-list-item"
        :class="{'is-hidden': funeral.is_hidden}"
        v-for="(funeral,id) in funerals"
        :key="id"
      >
        <div class="images">
          <div class="cover-sizer"></div>
          <div class="cover-wrapper">
            <img
              class="cover-bg"
              :src="fallbackImg(funeral.cover_image_path)"
              :alt="funeral.cover_image_alt"
            />
          </div>
          <div class="title-bar-wrapper">
            <div class="title-bar">
              <img
                class="profile-img"
                :src="fallbackImg(funeral.profile_image_path)"
                :alt="funeral.profile_image_alt"
              />
              <h5 class="title" :data-title="funeral.name">{{funeral.name}}</h5>
            </div>
          </div>
        </div>

        <div class="buttons">
          <RouterLink
            :to="`/dashboard/funeral/${id}`"
            title="View funeral"
            class="btn btn-outline-primary border"
          >View{{ funeral.role_name == 'organizer' ? ' / Modify' : '' }}</RouterLink>
        </div>

        <div class="icon-bar" v-if="!dashboardView">
          <button
            class="icon-show-hide"
            @click="toggleHidden(id, funeral.is_hidden ? 0 : 1)"
            :title="`${funeral.is_hidden ? 'Show' : 'Hide'} funeral from sidebar nav`"
          >
            <FontAwesomeIcon fixed-width :icon="icons.eye" v-if="funeral.is_hidden" />
            <FontAwesomeIcon fixed-width :icon="icons.eyeSlash" v-else />
            <span class="icon-text">{{funeral.is_hidden ? 'Show' : 'Hide'}}</span>
          </button>

          <button v-if="funeral.role_name=='organizer'"
            class="icon-delete"
            @click="openModal(id)"
            title="Delete funeral"
          >
            <FontAwesomeIcon fixed-width :icon="icons.trash" />
            <span class="icon-text">Delete</span>
          </button>
        </div>

        <div class="status-badge" v-if="funeral.role_name == 'organizer'">
          <div class="status">
            Organizer
          </div>
        </div>
      </div>
    </template>
    <p v-else>
      There are no funerals associated with this account.
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashAlt, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'SiteFuneralList',
  props: { dashboardView: Boolean },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      icons: {
        trash: faTrashAlt,
        eye: faEye,
        eyeSlash: faEyeSlash,
      },
    };
  },
  computed: {
    ...mapState([
      'funerals',
    ]),
    ...mapState(['funerals']),
    funeral() {
      return this.funerals[this.$route.params.id];
    },
  },
  methods: {
    ...mapActions('siteFuneral/list', [
      'openRecycleModal',
      'openModModal',
      'toggleShow',
    ]),
    ...mapActions('siteFuneral/addModify', ['toggleShow']),
    openModal(id) {
      this.openRecycleModal(id);
    },
    toggleHidden(id, value) {
      this.toggleShow({
        id,
        is_hidden: value,
      });
    },
    filterFuneralsByRole(role) {
      let res = {};
      Object.entries(this.funerals).forEach(([k, v]) => {
        if (v.role_name === role) res[k] = v;
      });
      return res;
    },
    fallbackImg(path) {
      return path || '/images/custom/site/pt-bg-default.jpg';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../../../../../sass/_vue-import.scss';

  .funeral-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(calc(250px + 2rem), 100%), 1fr));
    grid-auto-rows: max-content;
    gap: 1rem;

    &.dashboard-view .is-hidden {
      display: none;
    }

    .funeral-list-item {
      position: relative;
      display: flex;
      flex-direction: column;
      position: relative;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      justify-content: space-between;
      border-radius: 5px;
      transform: translateY(0);
      transition: 200ms ease-out;

      &.is-hidden {
        opacity: .5;
      }

      &:hover, &:focus-within {
        opacity: 1;
        box-shadow: 0 0 0 2px var(--primary);

        .icon-bar {
          opacity: 1;
          pointer-events: all;
          transform: translateY(-50%);
        }
      }

      @include not-mobile {
        min-width: max-content;
      }

      .images {
        display: grid;
        grid-template:
          "a" max-content
          "b" 1fr
          "c" 1fr
          / 100%;
        position: relative;
        top: 0;
        left: 0;

        .cover-sizer {
          grid-area: a;
          padding-top: clamp(80px, 20%, 150px);
        }

        .cover-wrapper {
          position: relative;
          grid-area: a-start / a-start / b-end / b-end;

          .cover-bg {
            border-radius: 5px 5px 0 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .title-bar-wrapper {
          grid-area: b-start / b-start / c-end / c-end;
          z-index: 1;
        }

        .title-bar {
          @include fluid-size(60px, 80px, --profile-width);
          position: relative;
          display: flex;
          align-items: center;
          padding: 0 1rem;

          .profile-img {
            max-width: var(--profile-width);
            max-height: 80px;
            border-radius: 5px;
          }

          .title {
            padding: .5rem 0 .5rem 1rem;
            color: var(--primary);

            &:before {
              position: absolute;
              content: attr(data-title);
              padding: .5rem 1rem .5rem calc(2rem + var(--profile-width));
              width: 100%;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              background-color: var(--light-tan);
              color: transparent;
              z-index: -1;
            }
          }
        }
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        margin: .75rem;

        > * {
          flex-grow: 1;
          margin: .25rem;
        }
      }

      .icon-bar {
        opacity: 0;
        pointer-events: none;
        transition: 200ms ease-out;
        display: flex;
        gap: .5rem;
        position: absolute;
        top: 0;
        right: .5rem;
        transform: translateY(-25%);
        z-index: 1;

        @include not-desktop {
          opacity: 1;
          pointer-events: all;
          transform: translateY(-50%);
        }

        button {
          font-size: 12px;
          display: flex;
          align-items: center;
          min-height: 24px;
          min-width: 24px;
          padding: 0;
          justify-content: center;
          border: 0;
          border-radius: 50rem;
          outline: 0;
          background-color: white;
          color: var(--primary);
          box-shadow: 0 0 5px rgba(0,0,0,0.2);
          transition: 200ms ease-out;

          &:hover, &:focus {
            box-shadow: 0 0 0 2px var(--primary);
            padding: 0 .5rem;

            .icon-text {
              max-width: 100px;
              margin-left: .25rem;
            }
          }

          .icon-text {
            max-width: 0;
            margin-left: 0;
            overflow: hidden;
            transition: 200ms ease-out;
          }

          &.icon-delete {
            background-color: var(--danger);
            color: white;
          }
        }
      }

      .status-badge {
        position: absolute;
        top: 0;
        left: .5rem;
        transform: translateY(-50%);
        z-index: 1;
        font-size: 12px;
        display: flex;
        align-items: center;
        padding: 0 .5rem;
        justify-content: center;
        border: 0;
        border-radius: 50rem;
        background-color: var(--primary);
        color: white;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
      }
    }
  }

  .funeral-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-indent: -9999px;
  }

</style>
