<template>
    <div class="m-0 wrap-gap flex-column">
        <div class="link-group" :class="currentCheckName(['FuneralCommunicationsHub'])">
            <RouterLink
                :to="{ name: $route.params.id ? 'FuneralCommunicationsHub' : 'FuneralsIndex' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens the communication hub for this funeral"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-comments"></span></div>
                    Communication Hub
                </div>
            </RouterLink>
        </div>

        <div class="link-group" :class="currentCheckName(['SiteScheduleIndex'])">
            <RouterLink
                :to="{ name: $route.params.id ? 'SiteScheduleIndex' : 'FuneralsIndex' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens the event calendar for this funeral"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-calendar"></span></div>
                    Event Calendar
                </div>
            </RouterLink>
        </div>

        <div class="link-group" :class="currentCheckName(['BioIndex'])" v-show="isOrganizer">
            <RouterLink
                :to="{ name: $route.params.id ? 'BioIndex' : 'FuneralsIndex' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens the biographical information for this funeral"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-book"></span></div>
                    Biographical Info
                </div>
            </RouterLink>
        </div>

        <div class="link-group" :class="currentCheckName(['UsersIndex'])" v-show="isOrganizer">
            <RouterLink
                :to="{ name: $route.params.id ? 'UsersIndex' : 'FuneralsIndex' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens the user management page"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-users"></span></div>
                    Manage Users
                </div>
            </RouterLink>
        </div>

        <!--removing this for now we can place orders here
         <div class="link-group" :class="currentCheckName(['OptionsIndex'])">
            <RouterLink
                :to="{ name: $route.params.id ? 'OptionsIndex' : 'FuneralsIndex' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens the options page for this funeral"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-cog"></span></div>
                    Funeral Options
                </div>
            </RouterLink>
        </div> -->
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: ['inFunerals'],
    computed: {
        ...mapState([
            'isLoading',
            'funerals',
        ]),
        isOrganizer() {
            return this.funerals?.[this.$route.params.id]?.role_name === 'organizer' || false;
        }
    },
    methods: {
        currentCheckName(check) {
            return { current: this.$route.matched.some(({name}) => check.includes(name)) && !this.isLoading };
        },
        currentCheckPath(check) {
            return { current: (this.$route.fullPath === check || this.$route.fullPath.includes(`${check}/`)) && !this.isLoading };
        }
    }
}
</script>

<style>

</style>