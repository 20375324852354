<template>
	<div class="content container my-5">
		<h4 class="text-center text-primary title-option"><span>{{ serviceType == 'cremation' ? 'Cremation ' : '' }}Casket Options</span></h4>
		
		<h4 class="text-primary text-center" v-if="serviceType == 'cremation'">Cremation must take place in a rigid leakproof container. That requirement can be met with a Cremation Casket or an Alternative Container. For Cremation Options where the body will be present for a visitation or service and cremated afterward, Cremation Caskets can be selected or a Rental Casket can be used in which case the body is removed from the Rental Casket and cremated in an Alternative Container.</h4>

		<template v-if="serviceType == 'burial' || serviceType == 'other'">
			<div class="planning-cards">
				<PlanningCards
					v-for="product in productsInCategory('Caskets')"
					:key="product.id"
					:cardTitle="product.ep_name"
					:cardImage="product.image"
					:cardId="parseInt(product.ep_id)"
					:cardPrice="product.ep_store_price"
					cardStep=3
					:product="product"
				><span v-html="product.ep_description"></span></PlanningCards>
			</div>
		</template>
        <template v-if="serviceType == 'cremation'">
			<div class="planning-cards">
				<PlanningCards
					v-for="product in productsInCategory('Cremation Caskets')"
					:key="product.id"
					:cardTitle="product.ep_name"
					:cardImage="product.image"
					:cardId="parseInt(product.ep_id)"
					:cardPrice="product.ep_store_price"
					cardStep=3
					:product="product"
				><span v-html="product.ep_description"></span></PlanningCards>
			</div>
		</template>
		<template v-if="serviceType == 'cremation'">
			<h3 class="text-center text-primary title-option mt-4">
				<span>Urn Options</span>
			</h3>

			<h4 class="text-primary text-center">You are not required to purchase an urn, but we have a variety of urns available.  Some in stock and some we can order and personalize.  If no urn is selected, cremated remains are placed in a temporary black plastic container.</h4>

			<div class="planning-cards">
				<PlanningCards
					v-for="product in productsInCategory('Urns')"
					:key="product.id"
					:cardTitle="product.ep_name"
					:cardImage="product.image"
					:cardId="parseInt(product.ep_id)"
					:cardPrice="product.ep_store_price"
					cardStep=4
					:product="product"
				><span v-html="product.ep_description"></span></PlanningCards>
			</div>
		</template>

		<template v-if="serviceType == 'burial'">
			<h3 class="text-center text-primary title-option mt-4">
				<span>Vault Options</span>
			</h3>

			<h4 class="text-primary">In most areas of the country, state or local law does not require that you buy a container to surround the casket in the grave. However, many cemeteries require that you have such a container so the grave will not sink in. Either a grave liner or a burial vault will satisfy these requirements.</h4>

			<h4 class="text-primary">The price of the Outer Burial Container listed below is the price of the container, it does not include delevery and setup at the cemetery. The delivery price varies by day of the week and distance from Waterloo.</h4>

			<div class="planning-cards">
				<PlanningCards
					v-for="product in productsInCategory('Casket Vaults')"
					:key="product.id"
					:cardTitle="product.ep_name"
					:cardImage="product.image"
					:cardId="parseInt(product.ep_id)"
					:cardPrice="product.ep_store_price"
					cardStep=5
					:product="product"
				><span v-html="product.ep_description"></span></PlanningCards>
			</div>
		</template>

		<template v-if="serviceType == 'cremation'">
			<h3 class="text-center text-primary title-option mt-4">
				<span>Memorial Capsule Urn Vault Options</span>
			</h3>

			<h4 class="text-primary text-center mb-2">The purpose of an Urn Vault is not only to protect the urn and contents, but to also insure that an urn is not disturbed if there are additional interments on a single space. Some cemeteries require an Urn Vault. A Memory Capsule serves as a receptacle for, and protects, an urn, memorabilia, photos, or keepsakes.</h4>

			<h4 class="text-primary text-center">The price of the Outer Burial Container listed below is the price of the container, it does not include delevery and setup at the cemetery. The delivery price varies by day of the week and distance from Waterloo.</h4>

			<div class="planning-cards">
				<PlanningCards
					v-for="product in productsInCategory('Urn Vaults')"
					:key="product.id"
					:cardTitle="product.ep_name"
					:cardImage="product.image"
					:cardId="parseInt(product.ep_id)"
					:cardPrice="product.ep_store_price"
					cardStep=5
					:product="product"
				><span v-html="product.ep_description"></span></PlanningCards>
			</div>
		</template>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	computed: {
		...mapState("cart/addModifyCart", {
			activeRecord: (state) => state.activeRecord,
			products: (state) => state.products,
			cartCount: (state) => state.cartCount,
			subTotal: (state) => state.subTotal,
			tax: (state) => state.tax,
			total: (state) => state.total,
			serviceType: (state) => state.activeRecord.serviceType.serviceType,
		}),
		needCasket() {
			if (!Object.keys(this.activeRecord.casketOption)) return false;
			return !!this.activeRecord.casketOption.needCasket;
		},
		needUrn() {
			if (!Object.keys(this.activeRecord.casketOption)) return false;
			return !!this.activeRecord.casketOption.needUrn;
		},
	},
	methods: {
		...mapActions("cart/addModifyCart", ["fetchproducts"]),
		productsInCategory(category) {
			return this.products.filter((product) => product.cat === category);
		},
	},
	created() {
		// This will never run???
		// Object.keys is a function that has a length one so this will always return false
		if (!Object.keys.length) this.fetchProducts();
	},
};
</script>
