<template>
    <div class="grid-content">
        <div class="grid-item" v-for="id in locationIds" :key="id">
            <div class="location-image" v-if="locations[id].image_path">
                <img :src="locations[id].image_path" :alt="locations[id].image_alt">
            </div>
            <div class="location-overview">
                <preferred-location-button :id="id" />
                <p class="title">{{ locations[id].title }}</p>
                <p class="m-0" v-if="locations[id].type">{{ locations[id].type }}</p>
                <frontend-location-short-hours
                    v-for="(hours, index) in locations[id].hours"
                    :key="index + '_gv'"
                    :hours="hours"
                />
            </div>
            <frontend-location-address :location="locations[id]"/>
            <view-location-details-button :id="id" />
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: "FrontendLocationGridView",
    computed: {
        ...mapState('location', [
            'locations'
        ]),
        ...mapGetters('location', [
            'locationIds'
        ])
    }
}
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../../sass/_vue-import.scss';

    // eventually move this to the base, maybe clean it up a bit
    @mixin border-widths($t, $r: null, $b: null, $l: null) {
        // conditionally handle shorthand
        @if not $r {$r:$t;$b:$t;$l:$t;}
        @else if not $b {$b:$t;$l:$r;}
        @else if not $l {$l:$r;}

        border-style: solid;
        border-color: var(--light);
        border-width: $t $r $b $l;
    }

    .grid-content {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: .5rem .375rem;
        font-size: 14px;
        min-height: 350px;

        @include desktop { @include border-widths(0, 1px, 1px); }
        @include not-desktop { @include border-widths(0, 0, 1px); }

        .grid-item {
            padding: .5rem .375rem;
            display: grid;
            grid-template:
                "img" max-content
                "overview" max-content
                "address" 1fr
                "button" max-content
                / 100%;

            @include bp-up($bp-laptop + 1) { flex: 0 0 25%; }
            @include bp-between($bp-tablet + 1, $bp-laptop) { flex: 0 0 math.div(100%, 3); }
            @include bp-between($bp-mobile-l + 1, $bp-tablet) { flex: 0 0 50%; }
            @include bp-down($bp-mobile-l) { flex: 0 0 100%; }

            .location-image {
                grid-area: img;
                padding-bottom: 1rem;

                img {
                    width: 100%;
                    object-fit: cover;
                    aspect-ratio: 16 / 9;
                }
            }

            .location-overview { grid-area: overview; }
            .address { grid-area: address; }
            .btn { grid-area: button; }

            .heading {
                margin-top: 1rem;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: .25rem;
                border-bottom: 1px solid var(--light);

                + .group {
                    padding: .5rem 0;

                    p { margin: 0; }

                    > *:first-child { margin-top: 0; }
                    > *:last-child { margin-bottom: 0; }
                }
            }

            .title {
                @include fluid-size(22px, 30px, font-size);
                font-weight: bold;
                line-height: 1.1;
                margin-bottom: .5rem;
            }

            .hour-overview { margin: 0; }
        }
    }
</style>