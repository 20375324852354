<template>
  <div class="content container my-5">
    <h2 class="top-title">Account Verification</h2>
    <div class="planning-cards">
      <template v-if="!hasUser">
        <PlanningCards
          cardTitle="Create New Account"
          cardImage="/images/custom/site/cremation-center/create-new-account.jpeg"
          :product="register"
          cardStep="10"
          cardId="register"
          class="h-auto"
        />

        <PlanningCards
          cardTitle="Sign In To Existing Account"
          cardImage="/images/custom/site/cremation-center/existing-account.jpeg"
          :product="login"
          cardStep="9"
          cardId="login"
          class="h-auto"
        />

        <PlanningCards
          cardTitle="Continue As Guest"
          cardImage="/images/custom/site/cremation-center/continue-guest.jpeg"
          cardDescription="This allows you to step through a selection process where you can explore options and costs. <strong>If you Continue As Guest, you will not be able to save your cart and submit for approval.</strong>"
          :product="guest"
          cardStep="11"
          cardId="guest"
          class="h-auto"
        />
      </template>

      <PlanningCards
        v-else
        cardTitle="You are Signed In"
        cardImage="/images/custom/site/Placeholder.png"
        cardDescription="This allows you to step through the selection process. You will be able to save your cart and submit for approval."
        :product="guest"
        cardStep="11"
        cardId="guest"
        class="h-auto"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      register: {},
      login: {},
      guest: {},
    };
  },
  computed: {
    ...mapState('cart/addModifyCart', [
      'user',
    ]),
    hasUser() {
      if (!this.user) return false;
      const loggedIn = !!Object.keys(this.user).length;

      if (loggedIn) {
        // Side effect in computed value
        this.$router.push({ name: 'LockeAssistance' });
      }

      return loggedIn;
    },
  },
  updated() {
    if (this.hasUser) {
      this.$router.push({ name: 'LockeAssistance' });
    }
  },
};
</script>
