<template>
    <div class="portal-sidebar-nav" :class="{expanded: expanded, 'is-loading': isLoading}">
        <div class="nav-wrapper">
            <div class="app-secondary-nav wrap-gap">
                <div class="link-group" :class="currentCheckName(['DashboardIndex'])">
                    <RouterLink
                        :to="{ name: 'DashboardIndex' }"
                        class="app-nav-btn"
                        active-class="current"
                        title="Internal link that opens the portal index page"
                        exact
                    >
                        <div class="icon-group">
                            <div class="icon"><span class="fas fa-fw fa-home"></span></div>
                            {{ inFuneral ? 'Back to ': '' }}Dashboard
                        </div>
                    </RouterLink>
                </div>

                <FuneralNavItems v-show="inFuneral" />
                <DashboardNavItems v-show="!inFuneral" :in-funerals="inFunerals" />
            </div>

            <div class="legal">
                <p>© {{ new Date().getFullYear() }} Locke Funeral Services.<br>All rights reserved.</p>
            </div>
        </div>

        <button
            class="expand-toggle"
            @click.prevent="expandToggle()"
            tabindex="-1"
        >
            <span class="fas fa-chevron-right"></span>
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PortalSidebarNav',
  data() {
    return {
      expanded: true,
      funeralsExpanded: true,
    };
  },
  computed: {
    ...mapState([
      'isLoading',
      'funerals',
    ]),
    inFunerals() {
      return this.$route.matched.some(({ name }) => ['FuneralsIndex', 'FuneralIndex'].includes(name));
    },
    inFuneral() {
      return this.$route.matched.some(({ name }) => name === 'FuneralIndex');
    },
  },
  methods: {
    expandToggle() {
      this.expanded = !this.expanded;
    },
    currentCheckName(check) {
      return { current: this.$route.matched.some(({ name }) => check.includes(name)) && !this.isLoading };
    },
  },
};
</script>

<style lang="scss">
    .portal-sidebar-nav {
        position: relative;
        border-right: 1px solid hsl(210deg, 14%, 89%);
        flex: 0 0 max-content;
        background-color: white;

        &.expanded {
            background-color: hsl(0deg,0%,98%);
        }
    }

    .nav-wrapper {
        height: 100%;
        padding: .75rem 0;
        width: 18px;
        overflow: hidden;
        opacity: 0;
        transition: 300ms cubic-bezier(0.2, 0, 0, 1);
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .portal-sidebar-nav.expanded &, &:focus-within {
            padding: .75rem calc(.75rem + 12px) .75rem .75rem;
            width: 250px;
            opacity: 1;
            pointer-events: all;
        }
    }

    .link-group {
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            opacity: 0;
            transition: 100ms ease-out;
            background-color: var(--primary);
            top: 0;
            height: 100%;
            pointer-events: none;
        }

        &:hover, &:focus-within, .portal-sidebar-nav:not(.is-loading) &.current {
            color: var(--primary);
            &:before { opacity: .1; }
        }
    }

    .link-group-children {
        display: flex;
        flex-direction: column;
        padding: .25rem;

        &, * {
            transition: 100ms ease-out;
        }

        .link-group.current {
            color: var(--primary);
            &:before { opacity: .1; }
        }

        .link-group {
            margin: .25rem;
        }
    }

    .app-secondary-nav {
        min-width: max-content;
        width: 100%;
        flex-direction: column;
        margin: 0;

        .app-nav-btn {
            display: block;
            text-align: left;
            text-decoration: none;
            font-size: 14px;
            padding: .5rem;
            color: hsl(0deg, 0%, 20%);

            &:focus {
                outline: 2px solid var(--primary);
            }

            .icon-group {
                display: flex;
                align-items: center;

                .icon {
                    font-size: 20px;
                    margin-right: .5rem;
                }
            }
        }
    }

    .expand-toggle {
        position: absolute;
        top: 2rem;
        right: 0;
        transform: translateX(50%) rotate(0);
        height: 24px;
        width: 24px;
        background-color: white;
        outline: none;
        border: 0;
        border-radius: 50rem;
        font-size: 12px;
        color: hsl(0deg,0%,20%);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        transition: 100ms ease-out;
        opacity: 1;

        &:hover, &:focus {
            background-color: var(--primary);
            color: white;
        }

        .portal-sidebar-nav.expanded & {
            transform: translateX(50%) rotate(180deg);
            opacity: 0;
        }

        .portal-sidebar-nav.expanded:hover & {
            opacity: 1;
        }
    }

    .link-group {
        &.expanded {
            .funerals-btn-wrapper {
                + .link-group-children { border-top: 1px solid rgba(0,0,0,0.1); }
            }

            .funeral-expand-btn { transform: rotate(.5turn); }

            .link-group-children {
                &, * {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                }
            }
        }

        &:not(.expanded) {
            .link-group-children {
                &, * {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    line-height: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                    border-top-width: 0;
                    border-bottom-width: 0;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    height: 0;
                }
            }
        }
    }

    .funerals-btn-wrapper {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0,0,0,0);
        transition: 100ms ease-out;

        .funerals-btn {
            flex-grow: 1;
        }

        .funeral-expand-btn {
            border: none;
            transition: 100ms ease-out;
            background-color: transparent;
            transform: rotate(1turn);
            padding: .5rem;

            &:not(:focus-within) {
                outline: none;
            }
        }
    }

    .legal {
        min-width: max-content;

        p {
            text-align: center;
            margin: 0;
            font-size: 10px;
        }
    }
</style>
