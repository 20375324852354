<template>
  <FuneralBioDynamicSection
    name="Organization"
    property="organization_info"
    :default-obj="defaultObj"
    :section="section"
    :sub-title="subTitle"
    v-slot="slotProps"
  >
    <VueTextarea
      class="m-0"
      name="Organization Description"
      v-model="slotProps.item.organization_description"
    />
  </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentOrganizations',
  props: ['section'],
  data() {
    return {
      subTitle: 'List civic, fraternal, service and veteran organizations belonged to. Also leadership or offices held.',
      defaultObj: {
        organization_description: '',
      },
    };
  },
};
</script>
