<template>
  <FuneralBioDynamicSection
    name="Hobby / Interest"
    property="hobby_info"
    :default-obj="defaultObj"
    :section="section"
    sub-title="List important interests, hobbies and travels."
    v-slot="slotProps"
  >
    <VueTextarea
      class="m-0"
      name="Hobby Description"
      v-model="slotProps.item.hobby_description"
    />
  </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentHobbies',
  props: ['section'],
  data() {
    return {
      defaultObj: {
        hobby_description: '',
      },
    };
  },
};
</script>
