<template>
    <div class="h-100">
        <ForgotForm />
    </div>
</template>

<script>
    import ForgotForm from "./components/ForgotForm.vue"

    export default {
        components: {
            ForgotForm
        }
    }
</script>
