<template>
    <div class="datatable-section p-2">

        <vpanel-condolence-list />
        <add-modify-condolence-modal />
        <recycle-condolence-modal />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "CondolenceIndex"
}
</script>
