<template>
	<div class="obituary-details-section">
		<div class="obituary-cover">
			<img
				class="obituary-cover-image"
				:src="coverImg"
				alt="Obituary Cover Image"
			/>
			<div class="header-bar js-break-out">
				<img
					class="obituary-profile-image"
					:src="profileImg"
					alt="Obituary Profile Image"
				>
				<p class="obituary-name">{{ obituary.name }}</p>
				<div class="obituary-lifetime">
					<div class="wrap-gap">
						<p>{{ getFormattedDate(obituary.date_of_birth) }} - {{ getFormattedDate(obituary.date_of_death) }}</p>
						<div class="sharethis-inline-share-buttons"></div>
					</div>
				</div>
			</div>

			<div class="quick-access-bar desktop">
				<div class="qab-links">
					<a
						class="qab-link"
						href="https://flowers.lockefuneralservices.com"
						title="External link in the same tab to our flower ordering website"
					><span class="custom-flower-icon"></span>Order Flowers</a>
					<button
						class="qab-link"
						@click="triggerShareThis()"
						title="Opens sharethis popup"
					><span class="fas fa-share"></span>Share</button>
					<button
						class="qab-link"
						@click="scrollTo('.obituary-condolences .condolence-form .title')"
						title="Scrolls to the condolence form"
					><span class="fas fa-hand-holding-heart"></span>Send Condolences</button>
				</div>
			</div>

			<div
				class="services"
				v-if="obituary.schedule_items || obituary.memorial_donations"
			>
				<template v-if="obituary.schedule_items">
					<div
						class="service"
						v-for="(item, index) in obituary.schedule_items"
						:key="index"
					>
						<p class="service-header">{{ item.name }}</p>
						<p
							class="service-text"
							v-if="getServiceDateText(item)"
						>
							<span class="service-icon fas fa-calendar"></span>
							<span>{{ getServiceDateText(item) }}</span>
						</p>
						<p
							class="service-text"
							v-if="getServiceTimeText(item)"
						>
							<span class="service-icon fas fa-clock"></span>
							<span>{{ getServiceTimeText(item) }}</span>
						</p>
						<div
							class="service-text"
							v-if="item.location_name || (item.location_address && getFormattedAddress(item.location_address))"
						>
							<span class="service-icon fas fa-map-marker-alt"></span>
							<p
								class="m-0 font-weight-bold"
								v-if="item.location_name"
							>{{ item.location_name }}</p>
							<p
								class="text-14 lh-sm"
								v-if="item.location_address && getFormattedAddress(item.location_address)"
								v-html="getFormattedAddress(item.location_address)"
							></p>
						</div>
					</div>
				</template>
				<template v-if="obituary.memorial_donations">
					<div class="service">
						<p class="service-header">Memorials</p>
						<p
							class="service-text"
							v-for="(donation, index) in obituary.memorial_donations"
							:key="'mem_' + index"
						>
							<span class="service-icon fas fa-monument"></span>{{ donation.name }}
						</p>
					</div>
				</template>
			</div>
		</div>
		<div class="obituary-layout">
			<div class="obituary-left">
				<div
					class="obituary-details"
					v-if="!isLoading"
				>
					<div
						class="content"
						v-html="obituary.content"
					></div>

					<a
						v-if="obituary.live_stream_link"
						:href="obituary.live_stream_link"
						class="btn btn-light-gray my-4"
					>
						<span class="fas fa-play-circle mr-1"></span>View live stream of funeral services
					</a>

					<div
						class="vimeo-embed my-2"
						v-if="obituary.vimeo_embed"
					>
						<span class="fas fa-play-circle"></span>
					</div>

					<div
						class="tribute-embed my-2"
						v-if="obituary.tribute_link"
					>
						<iframe
							class="tribute-frame"
							:src="obituary.tribute_link"
							:title="`${obituary.name} Tribute Video`"
						/>
					</div>

					<site-obituary-condolences :obituary="obituary"></site-obituary-condolences>
				</div>
				<VueLoader v-else />
			</div>
			<SiteObituarySidebar />
		</div>
		<div class="quick-access-bar mobile">
			<div class="qab-links">
				<a
					class="qab-link"
					href="https://flowers.lockefuneralservices.com"
					title="External link in the same tab to our flower ordering website"
				><span class="custom-flower-icon"></span>Order Flowers</a>
				<button
					class="qab-link"
					@click="triggerShareThis()"
					title="Opens sharethis popup"
				><span class="fas fa-share"></span>Share</button>
				<button
					class="qab-link"
					@click="scrollTo('.obituary-condolences .condolence-form .title')"
					title="Scrolls to the condolence form"
				><span class="fas fa-hand-holding-heart"></span>Send Condolences</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	props: ["obituary"],
	computed: {
		...mapState("obituary", ["isLoading"]),
		...mapGetters("obituary", [
			"getFormattedDate",
			"getFormattedTime",
			"getFormattedAddress",
			"getServiceDateText",
			"getServiceTimeText",
		]),
		profileImg() {
			return (
				this.obituary.profile_image_path ||
				"/images/custom/site/pt-bg-default.jpg"
			);
		},
		coverImg() {
			return (
				this.obituary.cover_image_path ||
				"/images/custom/site/pt-bg-default.jpg"
			);
		},
	},
	methods: {
		triggerShareThis() {
			let shareThisButton = document.querySelector('.st-btn[data-network="sharethis"]')
			if (shareThisButton) shareThisButton.click()
		},
		scrollTo(qs) {
			let el = document.querySelector(qs);

			if (el) {
				let topOffset = el.getBoundingClientRect().top, // distance between the top of the screen and the top of the element
					scroll = window.pageYOffset, // distance the page has been scrolled
					hdHeight = parseInt(
						document.body.style.getPropertyValue("--hd-height")
					),
					offset = window.innerWidth > 786 ? hdHeight + 16 : 250; // Magic numbers because mobile devices + fixed/sticky position = weird stuff

				window.scroll(0, topOffset + scroll - offset);
			}
		},
	},
};
</script>

<style lang="scss">
body[data-obituary-details] main {
	grid-area: header-start / header-start / main-end / main-end;
}
</style>

<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

.obituary-cover {
	padding-top: var(--cover-size);

	.obituary-cover-image {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		height: var(--cover-size);
		width: 100%;
	}

	.header-bar {
		@include fluid-size(16px, 32px, --topOffset);
		position: relative;
		top: calc(var(--topOffset) * -1);
		display: grid;
		grid-template:
			". i . ." var(--topOffset)
			"n n n n" max-content
			"l l l l" max-content
			". b . ." max-content
			/ var(--faux-container-padding) max-content 1fr var(--faux-container-padding);

		.obituary-profile-image {
			@include fluid-size(5px, 10px, border-width);
			grid-area: i-start / i-start / b-end / b-end;
			border: solid var(--yellow);
			@include fluid-size(16px, 48px, border-radius);
			width: var(--profile-img-size);
			z-index: 1;
		}

		.obituary-name {
			@include fluid-size(22px, 64px, font-size);
			@include fluid-size(8px, 24px, padding-top);
			@include fluid-size(8px, 24px, padding-bottom);
			padding-right: 0.5rem;
			padding-left: calc(
				var(--faux-container-padding) + var(--profile-img-size) + 1rem +
					1vw
			);
			line-height: 1;
			grid-area: n;
			margin: 0;
			background-color: var(--light-tan);
			font-family: "Libre Caslon Display", serif;
			color: var(--blue);
		}

		.obituary-lifetime {
			@include fluid-size(8px, 24px, padding-top);
			@include fluid-size(8px, 24px, padding-bottom);
			padding-right: var(--faux-container-padding);
			padding-left: calc(
				var(--faux-container-padding) + var(--profile-img-size) + 1rem +
					1vw
			);
			grid-area: l;
			background-color: var(--light-yellow);

			.wrap-gap {
				margin: -0.5rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;

				> * {
					margin: 0.5rem;
				}
			}

			p {
				@include fluid-size(14px, 24px, font-size);
				line-height: 1;
				text-transform: uppercase;
				font-family: "Roboto Condensed", sans-serif;
				font-weight: bold;
				color: var(--blue);
			}
		}
	}

	.services {
		display: flex;
		margin-left: -1rem;
		margin-right: -1rem;
		align-items: stretch;
		flex-wrap: wrap;

		.service {
			flex: 1 1 250px;
			padding: 1rem 0;
			margin: 0 2rem;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 1px;
				width: 100vw;
				background-color: var(--yellow);
			}

			&:not(:first-child) {
				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: calc(-2rem - 1px);
					transform: translateY(-50%);
					height: calc(100% - 2rem);
					width: 2px;
					background-color: var(--light);
				}
			}

			.service-header {
				font-size: 24px;
				color: var(--blue);
				font-weight: bold;
			}

			.service-text {
				padding-left: 1.5rem;
				position: relative;
				color: var(--font);

				svg {
					position: absolute;
					top: 0.25rem; // this accounts for the 8px difference in line height
					left: 0;
					color: var(--light-gray);
				}
			}
		}
	}
}

.obituary-details {
	margin: 0 0 2rem;
}

.tribute-embed {
	position: relative;
	padding-top: calc(56.25% + 42px);

	.tribute-frame {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

.obituary-top-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 1rem;
}

.obituary-info {
	display: flex;
	flex-wrap: wrap;
	margin: -0.25rem -0.5rem;
	font-size: 14px;

	.obituary-date {
		margin: 0.25rem 0.5rem;
		display: flex;
		align-items: center;
		line-height: 1;

		&::v-deep svg {
			margin-right: 0.5rem;
		}
	}
}

.obituary-image img {
	max-width: 100%;
}
.content {
	margin-top: 1rem;
}

.vimeo-embed {
	width: 100%;
	aspect-ratio: 16/9;
	background-color: var(--light-gray);
	color: var(--light);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 100px;
}

.quick-access-bar {
	background-color: var(--primary);
	overflow: hidden;

	@include desktop {
		&.desktop {
			border: 1px solid white;
			border-radius: 10px;
			margin-bottom: 1.5rem;
			display: inline-flex;

			.qab-link {
				span,
				svg {
					margin-right: 0.5rem;
				}
			}
		}

		&.mobile {
			display: none;
		}
	}

	@include not-desktop {
		&.mobile {
			position: sticky;
			bottom: -1px;
			transform: translateX(-15px);
			border-bottom: 1px solid white;

			// Stealing from js-break-out as it doesn't have responsive breakpoints
			body[data-scrollbarwidth="0"] & {
				width: 100vw;
				left: calc((100vw - 100%) / -2);
			}

			@for $i from 1 through 20 {
				body[data-scrollbarwidth="#{$i}"] & {
					width: calc(100vw - #{$i * 1px});
					left: calc(((100vw - #{$i * 1px}) - 100%) / -2);
				}
			}

			.qab-link {
				flex: 1 0 0px;
				flex-direction: column;
				line-height: 1;

				span,
				svg {
					font-size: 24px;
					margin-bottom: 0.5rem;
				}
			}
		}

		&.desktop {
			display: none;
		}
	}

	.qab-links {
		display: flex;

		.qab-link {
			font-size: 14px;
			font-weight: 700;
			color: white;
			background-color: rgba(0, 0, 0, 0);
			transition: 200ms ease-out;
			padding: 0.5rem 1rem;
			border: 0;
			outline: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.custom-flower-icon {
		--size: 27%; // Magic numbers to make everything fit nicely
		--center-size: 25%;
		--radius: 19%;
		--posP: calc(50% + var(--radius));
		--posN: calc(50% - var(--radius));
		--petal: currentColor var(--size), transparent calc(var(--size) + 1px);
		height: 1em;
		width: 1em;
		display: inline-block;
		-webkit-mask-image: radial-gradient(
			circle at 50% 50%,
			transparent var(--center-size),
			black calc(var(--center-size) + 1px)
		);
		background-image: radial-gradient(
				circle at var(--posN) var(--posN),
				var(--petal)
			),
			radial-gradient(circle at var(--posP) var(--posN), var(--petal)),
			radial-gradient(circle at var(--posN) var(--posP), var(--petal)),
			radial-gradient(circle at var(--posP) var(--posP), var(--petal));
	}
}
</style>