<template>
    <div class="nav">
        <a href="/" title="internal link to locke funeral services home page in same tab" class="logo-form">
            <img class="m-2" src="/images/custom/site/favicons/favicon-32x32.png" alt="Locke Funeral Home Icon" />
            <h5 class="m-2">Back to Locke Funeral Services</h5>
        </a>
        <div class="top-bar">
            <div class="tooltip-tab" :class="{ active: contactTooltipVisible }">
                <button class="tooltip-btn btn btn-light-tan btn-sm rounded-pill text-uppercase" @click="contactTooltipToggle">
                    <span class="fas fa-comments d-md-none"></span><span class="d-none d-md-inline">Contact Us</span>
                    <span class="fas fa-chevron-down ml-1"></span>
                </button>
                <div class="tooltip">
                    <p class="tooltip-heading">Need Help?</p>
                    <p><VueLink link-path="/contact-us" link-text="Contact Us Online" /></p>
                    <p><VueLink link-path="/locations" link-text="View All Locations &amp; Hours" /></p>
                    <p class="d-flex justify-content-between">
                        Locke on Fourth
                        <VueLink class="ml-2" link-path="tel:3192336138" link-text="319-233-6138" />
                    </p>
                    <p class="d-flex justify-content-between">
                        Locke at Tower Park
                        <VueLink class="ml-2" link-path="tel:3192333146" link-text="319-233-3146" />
                    </p>
                    <p class="d-flex justify-content-between">
                        Locke Garden View Chapel
                        <VueLink class="ml-2" link-path="tel:3192322222" link-text="319-232-2222" />
                    </p>
                    <p class="d-flex justify-content-between mb-0">
                        Locke in La Porte City
                        <VueLink class="ml-2" link-path="tel:3193423131" link-text="319-342-3131" />
                    </p>
                </div>
            </div>

            <div class="tooltip-tab" :class="{ active: memberTooltipVisible }">
                <button class="tooltip-btn btn btn-tan btn-sm rounded-pill text-uppercase" @click="memberTooltipToggle">
                    <span class="fas fa-user-circle d-md-none"></span><span class="d-none d-md-inline">My Account</span>
                    <span class="fas fa-chevron-down ml-1"></span>
                </button>
                <div class="tooltip">
                    <p class="tooltip-heading">Account Info</p>
                    <template v-if="hasUser">
                        <p><VueLink @click="logout" link-text="Log Out" /></p>
                        <p><VueLink link-path="/dashboard/orders" link-text="Order History" /></p>
                        <p><VueLink link-path="/dashboard/profile" link-text="Account Settings" /></p>
                        <p><VueLink link-path="/locke-planning-center" link-text="Advance Planning" /></p>
                    </template>
                    <template v-else>
                        <p><VueLink class="btn btn-blue" link-path="/login?lpc" link-text="Log In" /></p>
                        <p>
                            New here?
                            <VueLink link-path="/register?lpc" link-text="Create Your Account" />
                        </p>
                    </template>
                </div>
            </div>
            <RouterLink :to="{ name: 'ReviewCart' }" class="step-link" active-class="current" exact>
                <VueLink class="cart-button text-decoration-none flex-shrink-0" link-icon="fas fa-shopping-cart" :link-text="cartCountString" />
            </RouterLink>
            <div>
                <button class="tooltip-btn btn btn-light-tan btn-sm rounded-pill text-uppercase" @click="clearCart()">
                    <span class="fas fa-comments d-md-none"></span><span class="d-none d-md-inline">Clear Cart</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { navMixin } from '@v/Core/Site/Mixins/navMixin';
import { headerMixin } from '@v/Core/Site/Mixins/headerMixin';
import { themingMixin } from '@v/Core/Site/Mixins/themingMixin';

export default {
    mixins: [ themingMixin, navMixin, headerMixin ],
    props: ['primaryLinks', 'secondaryLinks', 'primaryCta', 'secondaryCta'],
    data() {
        return {
            isLoggedIn: true, // This will need to be dynamic at some point
            contactTooltipVisible: false,
            memberTooltipVisible: false,
        };
    },
    computed: {
        combineLinksAndCtas() {
            return [
                ...(this.primaryCta || []),
                ...(this.secondaryCta || []),
                ...(this.primaryLinks || []),
                ...(this.secondaryLinks || []),
            ];
        },
        ...mapState('cart/addModifyCart', [
            'user',
            'cartCount',
            'activeRecord',
        ]),
        hasUser() {
            return this.user && this.user !== 'false' && Object.keys(this.user).length;
        },
        cartCountString() {
            if (this.cartCount) return this.cartCount.toString();
            return '';
        },
    },
    methods: {
        contactTooltipToggle() {
            this.contactTooltipVisible = !this.contactTooltipVisible;
            if (this.memberTooltipVisible) this.memberTooltipVisible = false;
        },
        memberTooltipToggle() {
            this.memberTooltipVisible = !this.memberTooltipVisible;
            if (this.contactTooltipVisible) this.contactTooltipVisible = false;
        },
        logout() {
            axios.post('/logout')
                .then((response) => {
                // If successful
                location.href = '/login';
                });
        },
        clearCart(){
            if(confirm("Are you sure you want to clear your cart and start over?")== true){
                location.replace('/locke-planning-center');
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../../sass/vue-import";

.nav {
    justify-content: space-between;
    display: flex;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: white;
        z-index: -1;
        box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 14%);
    }

    .logo-form {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        align-items: center
    }

    .top-bar {
        padding: 0.25rem 1rem;
        font-size: 14px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--blue) !important;

        .tooltip-tab {
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            flex-shrink: 0;
            --top-arrow-size: min(8px, 1vw);

            .tooltip-btn {
                &:before {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: calc(var(--top-arrow-size) * 2);
                    width: calc(var(--top-arrow-size) * 10);
                    border-width: 0 calc(var(--top-arrow-size) * 5) calc(var(--top-arrow-size) * 2);
                    border-style: solid;
                    border-color: var(--light-tan) transparent;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: 200ms ease-out;
                }
            }

            .tooltip {
                text-transform: none;
                position: absolute;
                width: max-content;
                right: 0;
                top: 100%;
                padding: 0.75rem 1rem;
                background-color: var(--light-tan);
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: 200ms ease-out;
                z-index: 3;

                p {
                    font-size: 14px;
                    color: black;
                    margin: 0.25rem 0;
                }

                .tooltip-heading {
                    font-size: 20px;
                    color: var(--dark-tan);
                    margin-top: 0;
                }
            }

            &.active {
                .tooltip-btn:before {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                    bottom: calc(-1rem - 1px);
                }

                .tooltip {
                    top: calc(100% + 1rem + 1px);
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                }
            }
        }
    }
}
</style>
