<template>
    <div class="datatable-section p-2">
        <vpanel-obituary-list />
        <recycle-obituary-modal />
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "ObituaryIndex",
    created() {
        this.fetchBranches();
    },
    computed: {
        ...mapState("obituary/list", {
            isLoading: (state) => state.isLoading
        })
    },
    methods: {
        ...mapActions("obituary", {
            fetchBranches: "list/fetchBranches"
        })
    }
};
</script>
