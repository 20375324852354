<template>
    <div
        class="chat-room-list-item"
        :class="itemClass"
        @click.prevent="selectChatRoom(chatRoom);getContacts()"
         >
        <div class="user-info">
            <p class="username">{{ chatRoom.funeral.name }}</p>
        </div>
        <p class="unread-messages" v-if="chatRoom.unread_messages">{{ chatRoom.unread_messages }}</p>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    props: ['chatRoom'],
    computed: {
        ...mapState('chat/chat', {
            activeChatRoomId: state => state.active_chat_room_id,
            messages: state => state.message
        }),
        contact() {
            return this.chatRoom.funeral.name;
        },
        itemClass() {
            return {
                'active': this.chatRoom.id == this.activeChatRoomId
            }
        }
    },
    methods: {
        selectChatRoom(contact) {
            this.$store.dispatch("chat/chat/selectChatRoom", { contact: contact });
        },
        getContacts(){
             this.$store.dispatch("chat/chat/getContactsList",this.chatRoom.funeral_id);
        },
          ...mapActions("chat/chat", [
            "subscribeToChatRoom",
        ]),
    },
};
</script>

<style lang="scss" scoped>
    .chat-room-list-item {
        padding: 1rem;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        background-color: #ddd;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover { background-color: #bbb; }

        .profile-img {
            height: 50px;
            flex: 0 0 50px;
            border: 1px solid #bbb;
            border-radius: 50rem;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;

            .status-dot {
                position: absolute;
                bottom: 0;
                right: 0;
                border: 2px solid #bbb;
                background-color: white;
                border-radius: 50rem;
                height: 12px;
                width: 12px;

                &:before {
                    content: "";
                    position: absolute;
                    height: 6px;
                    width: 6px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50rem;
                }

                &.online:before { background-color: var(--success) }
                &.offline:before { background-color: var(--lightgray) }
            }
        }

        .user-info {
            margin-left: .5rem;
            flex-grow: 1;
            overflow: hidden;

            .username {
                font-size: 14px;
                font-weight: bold;
                color: var(--primary);
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .unread-messages {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: 14px;
        font-family: sans-serif;
        font-weight: bold;
        height: 16px;
        width: max-content;
        min-width: 16px;
        background-color: var(--tertiary);
        margin: 0 0 0 1rem;
        flex-shrink: 0;
        border-radius: 50rem;
        padding: 4px;
    }

    .active { background-color: var(--secondary); }
</style>
