<template>
    <div class="order-card">
        <div class="order-top-bar">
            <p class="order-title">{{ order.funeral }}</p>
            <div class="order-actions" v-if="showActions">

                <button
                    v-if="order.approval === 'Approved' && transactionComplete === false"
                    class="btn btn-primary"
                    @click.prevent="goToCheckout(order)"
                >Complete Order</button>
                <button
                    class="btn btn-secondary"
                    @click.prevent="goToDocuments(order)"
                >Order Document Uploader</button>
                <button
                    class="btn btn-outline-primary border"
                    @click.prevent="toggleDropdown()"
                >
                    <FontAwesomeIcon :icon="icons.chevronUp" v-if="active" />
                    <FontAwesomeIcon :icon="icons.chevronDown" v-else />
                </button>
            </div>
        </div>
        <div class="order-dropdown" :class="{active: !showActions || active}">
            <div class="order-statuses">
                <p class="order-status" v-if="order.cart_uuid">Order Id: <span>{{ order.cart_uuid.substring(0,5) }}</span></p>
                <p class="order-status">Status: <span>{{ order.approval }}</span></p>
            </div>
            <div class="order-products-grid">
                <p class="opg-heading">Name</p>
                <p class="opg-heading">Price</p>
                <template v-for="product in order.cart_items">
                    <p><span class="opg-hidden-label">Name</span>{{ product.name }}{{ product.qty > 1 ? ` x${product.qty}` : '' }}</p>
                    <p><span class="opg-hidden-label">Price</span>${{ product.line_total }}</p>
                </template>
                <p class="opg-total"><strong>Subtotal:</strong> ${{ order.subtotal }}</p>
                <p class="opg-total"><strong>Tax:</strong> ${{ order.tax_total }}</p>
                <p class="opg-total"><strong>Total:</strong> ${{ order.total }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapState } from "vuex";

export default {
    props: {
        order: {
            type: Object,
            required: true
        },
        showActions: {
            type: Boolean,
            default: true
        }
    },
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            active: false,
            icons: {
                chevronDown: faChevronDown,
                chevronUp: faChevronUp
            }
        }
    },
    computed: {
        ...mapState([
            "userData"
        ]),
        transactionComplete(){
            if(this.order.authnet_transaction_response === null || this.order.authnet_transaction_response.responseCode === null){
                return false;
            }else{
                return (this.order.authnet_transaction_response.responseCode === "1") ? true:false ;
            }

         }
        },
    methods: {
        ...mapActions("order/checkout", [
            "setOrder"
        ]),
        ...mapActions("order/document", {
            setOrderForDocs: "setOrder"
        }),
        goToCheckout(order) {
            this.setOrder([order, this.userData]);
            this.$router.push({ name: "OrderPayment" });
        },
        goToDocuments(order) {
            this.setOrderForDocs([order, this.userData]);
            this.$router.push({ name: "OrderDocuments" });
        },
        toggleDropdown() {
            this.active = !this.active
        }
    },
}
</script>

<style lang="css">
    @media only print {
        body.advanced-planning-portal main,
        .app-main,
        .content-wrapper,
        .main-content-wrapper {
            overflow: unset !important;
        }
    }
</style>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';

    .order-card {
        padding: .5rem 1rem;
        border: 1px solid var(--light-gray);
        border-radius: 5px;
    }

    .order-top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .order-title {
        margin: 0;
    }

    .order-actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: .25rem;

        button {
            display: block;
            flex-grow: 1;
            border-radius: 1rem;
        }
    }

    .order-dropdown {
        height: 0;
        overflow: hidden;
        padding-top: 0;
        margin-top: 0;
        border-top: 0 solid transparent;
        transition: 200ms ease-out;

        &.active {
            height: auto;
            overflow: hidden;
            padding-top: .5rem;
            margin-top: .5rem;
            border-top: 1px solid var(--light-gray)
        }

        @media only print {
            height: auto;
            overflow: hidden;
            padding-top: .5rem;
            margin-top: .5rem;
            border-top: 1px solid var(--light-gray);
        }
    }

    .order-statuses {
        display: flex;
        gap: .5rem;
        flex-wrap: wrap;
    }

    .order-status {
        font-size: .8em;
        border: 1px solid var(--light-gray);
        border-radius: 1em;
        padding: .125rem .75rem;
        font-weight: bold;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        gap: 0 .5rem;

        span {
            font-weight: normal;
        }
    }

    .order-products-grid {
        margin-top: 1rem;
        display: grid;
        grid-auto-rows: max-content;
        gap: .5rem;

        @include not-desktop {
            grid-template-columns: 100%;
        }

        @include desktop {
            grid-template-columns: repeat(2, auto);
        }

        .opg-heading {
            font-weight: bold;

            @include not-desktop {
                display: none;
            }
        }

        .opg-hidden-label {
            font-weight: bold;

            @include desktop {
                display: none;
            }
        }

        p {
            margin: 0;

            @include desktop {
                &:not(:nth-child(2n+1)) {
                    text-align: right;
                }
            }

            @include not-desktop {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 0 .5rem;

                &:nth-child(2n):not(:last-child) {
                    margin-bottom: .25rem;
                    padding-bottom: .25rem;
                    border-bottom: 1px solid var(--light-gray);
                }
            }
        }

        @media only print {
            display: flex;
            flex-wrap: wrap;

            > * {
                flex-basis: calc(50% - .5rem);

                &:not(:nth-child(2n+1)) {
                    text-align: right;
                }
            }

            .opg-hidden-label {
                display: none;
            }

            .opg-total {
                flex-basis: calc(100% - .5rem);
            }
        }
    }

    .opg-total {
        grid-column: 1 / -1;
        border-top: 1px solid var(--light-gray);
        text-align: right;
        padding-top: .5rem;
        font-weight: bold;
    }
</style>