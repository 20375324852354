<template>
    <vue-modal
        :visible="isModalOpen"
        @on-close="closeRecycleModal"
        id="user-recycle-modal"
    >
        <template #header>
            <h4 class="modal-title">
                <span class="fas fa-trash mr-1 text-tertiary"></span>Confirm Remove User
            </h4>
        </template>

        <div class="text-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x my-6"></span>
            <p class="text-22 font-weight-bold mb-1">
                Are you sure you want to remove this user from this funeral?
            </p>
            <p>
                This action is permanent.
            </p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-end flex-wrap" style="gap: 1rem">
                <button
                    type="button"
                    class="btn btn-outline-primary border cancel-btn"
                    @click="closeRecycleModal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    class="btn btn-danger confirm-btn"
                    @click="recycleUser"
                    :disabled="isModalProcessing"
                >
                    {{
                        isModalProcessing
                            ? "Processing..."
                            : "Remove user"
                    }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "SiteRecycleUserModal",

    computed: {
        ...mapState("user", {
            activeRecord: state => state.addModifyUser.activeRecord,
            isModalOpen: state => state.siteRecycleUserModal.isModalOpen,
            isModalProcessing: state =>
                state.siteRecycleUserModal.isModalProcessing
        })
    },
    methods: {
        ...mapActions("user", {
            openModal: "siteRecycleUserModal/openModal",
            closeModal: "siteRecycleUserModal/closeModal",
            recycleRecord: "siteRecycleUserModal/recycleRecord"
        }),
          ...mapActions("user/addModifyUser", ["removeUserFromFuneral"]),
        closeRecycleModal() {

            this.closeModal();
        },
        recycleUser() {
            this.removeUserFromFuneral();
            this.closeRecycleModal();
        }
    }
};
</script>
