<template>
	<div>
		<div class="user-cards" v-if="tableData">
			<div class="card" v-for="(data, index) in tableData" :key="index">
				<div class="card-body">
					<h4 class="card-title text-primary">{{ data.name }}</h4>
					<div v-if="data.expires_at">
						<p class="card-text mb-0"><span class="text-primary font-weight-bold">Status:</span> <span class="text-dark-tan font-weight-bold em">Invited to join</span></p>
						<p class="card-text"><span class="text-primary font-weight-bold">Expires:</span> {{ data.expires_at }}</p>
					</div>
					<div v-else>
						<p class="card-text mb-0"><span class="text-primary font-weight-bold">Status:</span> <span class="text-success font-weight-bold">Active</span></p>
						<p class="card-text"><span class="text-primary font-weight-bold">Role:</span> {{ data.role_name == 'organizer' ? 'Organizer' : 'User' }}</p>
					</div>
					<p class="card-text"><span class="text-primary font-weight-bold">Email: </span>{{ data.email }}</p>
					<add-user-to-funeral-button :id="data.user_id" v-if="!data.expires_at && role=='organizer' ">Edit User
					</add-user-to-funeral-button>
					<button class="card-link btn btn-danger" @click="openModal(data.user_id)" title="Delete funeral"  v-if="role=='organizer'" >Delete</button>
				</div>
			</div>
		</div>
		<p v-else>
			There are no users associated with this funeral or you do not have access to view.
		</p>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {

	name: "SiteUsersInFuneralList",
    props:['role'],
	computed: {
		...mapState("user/list", {
			tableData: (state) => state.tableData,
		}),
        ...mapState(["funerals"]),

	},

	methods: {
		...mapActions("user/list", [
			"openRecycleModal",
			"openModModal",
			"toggleShow",
		]),
		openModal(id) {
			this.openRecycleModal(id);
		},
		toggleHidden(id, value) {
			this.toggleShow({
				id: id,
				is_hidden: value,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.user-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}

.card {
	border-top: 5px solid var(--tan);
	background-color: #fffaed;
    flex-basis: 300px;
}
</style>
