<template>
    <div
        class="contact-list-item"
        :class="itemClass"
        :title="`${contact.name} is ${contact.chat_status}`"
    >
        <p class="m-0">{{ contact.name }}</p>
    </div>
</template>

<script>
export default {
    props: ['contact'],
    computed: {
        itemClass() {
            return {
                'online': this.contact.chat_status == 'online',
            }
        }
    },
    methods: {
        selectChatRoom() {
            this.$store.dispatch('chat/chat/selectChatRoom', {contact: this.contact});
        }
    }
}
</script>

<style lang="scss" scoped>
    .contact-list-item {
        pointer-events: all;
        cursor: pointer;
        position: relative;
        line-height: 1;
        margin: .25rem;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        opacity: .8;

        p {
            position: relative;
            padding: .25rem .5rem;
            background-color: white;
            border-radius: 50rem;
            border: 2px solid #f0f0f0;
        }

        &.online {
            opacity: 1;

            p {
                border: 2px solid var(--success);
                color: var(--success);
            }

            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                background-color: var(--success);
                box-shadow: 0 0 5px var(--success);
                height: 100%;
                width: 100%;
                border-radius: 50rem;
                opacity: .5;
            }
        }
    }
</style>