<template>
    <vue-modal
        :visible="isModalOpen"
        @on-close="closeRecycleModal"
        id="event-recycle-modal"
    >
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-trash mr-1 text-tertiary"></span>Confirm
                Event Delete
            </h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">
                Are you sure you want to delete this event?
            </p>
            <p>
                This action is permanent. Consider marking the event as
                private.
            </p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button
                    type="button"
                    class="btn btn-outline-primary cancel-btn"
                    @click="closeRecycleModal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    class="btn btn-primary confirm-btn ml-2"
                    @click="recycleEvent"
                    :disabled="isModalProcessing"
                >
                    {{
                        isModalProcessing
                            ? "Processing..."
                            : "Delete event"
                    }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "RecycleEventModal",

    computed: {
        ...mapState("schedule", {
            activeRecord: state => state.addModifySchedule.activeRecord,
            isModalOpen: state => state.recycleEventModal.isModalOpen,
            isModalProcessing: state =>
                state.recycleEventModal.isModalProcessing
        })
    },
    methods: {
        ...mapActions("schedule", {
            closeModal: "recycleEventModal/closeModal",
            resetEvent: "addModifySchedule/resetEvent",
             recycleRecord: "recycleEventModal/recycleRecord"
        }),
        closeRecycleModal() {
            this.resetEvent();
            this.closeModal();
        },
        recycleEvent() {
            this.recycleRecord({
                ajaxRoute:
                    "/vpanel/modules/obituary/schedule/destroy/" +
                    this.activeRecord.id,
                recordId: this.activeRecord.id,
                dispatchAction: "schedule/list/removeRecordFromDataTable"
            });
            this.resetEvent();
        }
    }
};
</script>
