<template>
    <form
        id="add-modify-portal-form"
        class="portal-form needs-validation"
        name="add-modify-portal-form"
        novalidate
        @submit.prevent="submitForm"
    >
        <div  v-if="errorMessage"
                class="alert alert-danger form-errors"
                v-html="errorMessage"></div>
        <input type="hidden" name="_token" :value="activeRecord._token" />
        <input
            type="hidden"
            name="id"
            :value="activeRecord.id"
            v-if="!isActionAdd"
        />
        <div
            class="d-flex flex-column justify-content-between align-items-stretch h-100"
        >
            <div
                class="document-form-fields d-flex flex-column align-items-stretch"
            >
                <h5>Basic Information</h5>

                 <div class="form-group has-label">
                        <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                            <label for="title"> Name <span class="required">*</span></label>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            name="firstname"
                            id="firstname"
                            v-model="activeRecord.name"
                            title="Enter a first name"
                            required

                        />
                        <p class="invalid-feedback">Please enter a first and last name.</p>
                    </div>
                      <div class="form-group has-label">
                        <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                            <label for="title">Email <span class="required">*</span></label>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            name="Email"
                            id="Email"
                            v-model="activeRecord.email"
                            title="Enter an email address"
                            required

                        />
                        <p class="invalid-feedback">Please enter an email address.</p>
                    </div>
                            <div class="form-group has-label">
                        <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                            <label for="title">Phone <span class="required">*</span></label>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            name="phone"
                            id="phone"
                            v-model="activeRecord.phone"
                            title="Enter a phone number"
                            required

                        />
                        <p class="invalid-feedback">Please enter a phone number.</p>
                    </div>
                     <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label  for="address1">Address</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide the address of the location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        type="text"
                        id="address1"
                        name="address1"
                        class="form-control"
                        title="Please enter the address"
                        v-model="activeRecord.address"
                        data-invalid-feedback="Please provide an address"
                    />
                    <div class="invalid-feedback">
                        Please provide an address
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label  for="city">City</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide in what city the location is located in"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input
                        id="city"
                        name="city"
                        type="text"
                        class="form-control"
                        title="Please enter the city"
                        v-model="activeRecord.city"
                        data-invalid-feedback="Please provide a city"
                    />
                    <div class="invalid-feedback">
                        Please provide a city
                    </div>
                </div>
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="state">State</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide what state this location is in"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                     <select
                        id="state"
                        name="state"
                        type="text"
                        class="form-control"
                        title="Please enter the state"
                        v-model="activeRecord.state"
                        data-invalid-feedback="Please provide a state"
                       >
                        <option
                            :key="index"
                            :value="option.value"
                            v-for="(option, index) in options"
                        >
                            {{ option.name }}
                        </option>
                    </select>
                    <div class="invalid-feedback">
                        Please provide a state
                    </div>
                </div>
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="postal_code">Zip</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide the postal code for this location"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input

                        type="text"
                        id="postal_code"
                        name="postal_code"
                        class="form-control"
                        title="Please enter the postal code"
                        v-model="activeRecord.zip_code"
                        data-invalid-feedback="Please provide a postal code"
                    />
                    <div class="invalid-feedback">
                        Please provide a postal code
                    </div>
                </div>

            </div>
                   <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="password">Password </label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide a password for this user"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input

                        type="password"
                        id="password"
                        name="password"
                        class="form-control"
                        title="Please enter the postal code"
                        v-model="activeRecord.password"
                        data-invalid-feedback=" Please provide password. "
                    />
                    <div class="invalid-feedback">
                        Please provide password.
                    </div>
                </div>
                </div>
                <div class="col form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="password">Password </label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Provide a password for this user"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <input

                        type="password"
                        id="password_confirmation"
                        name="password_confirmation"
                        class="form-control"
                        title="Please enter the postal code"
                        v-model="activeRecord.password_confirmation"
                        data-invalid-feedback=" Please confirm password. "
                    />
                    <div class="invalid-feedback">
                        Please confirm password.
                    </div>
                </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "AddModifyPortalForm",
    props: ["isActionAdd"],

    computed: {
        getHeader() {
            let action = this.isActionAdd ? "Add a New " : "Modify ";
            return action + "User";
        },
        ...mapState("portal/addModifyPortal", {
            activeRecord: state => state.activeRecord,
            isFormProcessing: state => state.isFormProcessing,
            didSaveDraft: state => state.didSaveDraft,
            options: state => state.states,
            errorMessage: (state) => state.errorMessage,
        }),

        ...mapState("portal/list", {
            users: state => state.tableData
        })
    },
    methods: {}
};
</script>
