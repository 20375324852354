<template>
    <tr>
        <td data-label="Preview">
            <img
                class="preview"
                :src="previewPath || '/images/custom/site/Placeholder-Thumbnail.png'"
                :alt="previewAlt || 'Placeholder image frank update with dynamic images'"
            />
        </td>
        <td data-label="Category">{{ category }}</td>
        <td data-label="Item">{{ item }}</td>
        <!-- TODO: add dynamic text -->
        <td data-label="Description" v-html="description"></td>
        <td data-label="Has Tax">{{ hasTax }}</td>
        <td data-label="Tax">{{ tax }}</td>
        <td data-label="Price">{{ price }}</td>
    </tr>
</template>

<script>
export default {
    props: {
        previewPath: {
            type: String,
            default: "/images/custom/site/Placeholder-Thumbnail.png"
        },
        previewAlt: {
            type: String,
            default: "Placeholder image frank update with dynamic images"
        },
        category: {
            type: String
        },
        item: {
            type: String
        },
        description: {
            type: String,
            default: "NEED TO ADD DYNAMIC DESCRIPTION"
        },
        hasTax: {
            type: String,
            default: "--"
        },
        tax: {
            type: String,
            default: "--"
        },
        price: {
            type: String,
            default: "--"
        }
    }
}
</script>