<template>
    <button
        type="button"
        class="btn btn-primary m-0"
        @click.prevent="openModal()"
    >
        Add Funeral
    </button>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "AddNewFuneralButton",
    methods:{
          ...mapActions("funeral/addModify", [
            "openModal",
        ]),
    }

};
</script>
