<template>
    <div class="obituary-condolences">
        <template v-if="!condolencesArePrivate && obituary.condolences.length">
            <p class="title">Condolences</p>

            <div class="condolence-list">
                <site-obituary-condolence-item
                    v-for="condolence in obituary.condolences"
                    :key="condolence.name"
                    :condolence="condolence"
                />
            </div>
        </template>

        <site-obituary-condolence-form
            :obituary="obituary"
        />
    </div>
</template>

<script>
    export default {
        props: ["obituary"],
        computed: {
            condolencesArePrivate() {
                return parseInt(this.obituary.condolences_are_private);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .obituary-condolences {
        margin-top: 3rem;
        border-top: 1px solid hsl(0deg,0%,95%);
        padding-top: 1.5rem;

        .title {
            margin-bottom: 1rem;
            font-size: 24px;
            font-weight: bold;
            color: var(--blue);
        }

        .condolence-list {
            margin-top: 1.5rem;
            border-top: 1px solid hsl(0deg,0%,95%);
            padding-top: .5rem;
        }
    }
</style>