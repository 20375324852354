<template>
    <vue-modal
        :visible="isModalOpen"
        @on-close="closeForgotModal"
        id="forgot-modal"
    >
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-condolence mr-1 text-tertiary"></span
                >&nbsp;Forgot Password
            </h5>

        </template>
        <!-- Form Include -->
        <forgot-form />
        <template #footer>
            <div class="d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-outline-primary cancel-button"
                    @click.prevent="closeModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-primary ml-1"
                    :disabled="isFormProcessing"
                    form="forgot-form"
                    @click.prevent="submitForgot"
                >
                    {{ isFormProcessing ? "Processing..." : "Apply" }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
import ForgotForm from "../ForgotForm.vue";
import VueModal from "@v/Core/Global/Components/VueModals/VueModal.vue";
export default {
    name: "ForgotModal",
    components: { ForgotForm, VueModal },
    computed: {
        ...mapState("forgot", {
            isModalOpen: (state) => state.isModalOpen,
            activeRecord: (state) => state.activeRecord,
            isFormProcessing: (state) => state.isFormProcessing,
            successMessage: (state) => state.successMessage,
            errorMessage:(state) => state.errorMessage
        }),
    },
    methods: {
        ...mapActions("forgot", ["forgot", "closeModal"]),
        closeForgotModal() {
            this.closeModal();
        },
        submitForgot() {
            var formId = "#forgot-form";
            var path = "/forgot-password";
            this.forgot(formId, path);
        },
    },
};
</script>
