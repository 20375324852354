<template>
    <div class="bio-form flex-grow-1" :class="{'vp': vp}">
        <div class="top-bar">
            <button
                type="button"
                class="btn btn-primary btn-lg flex-shrink-0"
                @click="download()"
                v-if="hasBio && activeRecord.role_name=='organizer'"
            >Download Bio PDF</button>
            <BioNav />
        </div>

        <form
            novalidate
            @submit.prevent="save()"
            id="add-modify-funeral-form"
            :class="vp ? 'm-0 overflow-hidden h-100' : ''"
        >
            <div :class="vp ? 'flex-grow-1 overflow-auto' : ''">
                <div
                    v-show="errorMessage"
                    v-html="errorMessage"
                    class="alert alert-danger form-errors alert-dismissible"
                ></div>

                <div
                    v-show="successMessage"
                    class="alert alert-success alert-dismissible"
                    v-html="successMessage"
                ></div>

                <BiographyForm v-if="activeRecord.role_name=='organizer' || vp" />

                <p v-else>
                    You do not have access to this information please contact the funeral organizer for more information.
                </p>
            </div>

            <button
                v-if="activeRecord.role_name == 'organizer'"
                class="btn btn-primary btn-lg align-self-end flex-shrink-0"
            >Save Biographical Info</button>
        </form>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name: 'BioIndex',
    data() {
        return {
            showBio: false,
            vp: false,
        };
    },
    computed: {
        ...mapState('siteFuneral/addModify', [
            'activeRecord',
            'isFormProcessing',
            'didSaveDraft',
            'family_info',
            'errorMessage',
            'successMessage',
        ]),
        ...mapState('siteFuneral/list', [
            'branches',
        ]),
        ...mapState(['funerals']),
        hasBio() {
            return this.activeRecord?.funeral_bio?.biography_info_fe ? Object.keys(this.activeRecord.funeral_bio.biography_info_fe).length : false;
        },
    },
    methods: {
        ...mapActions('siteFuneral/list', ['selectFuneral']),
        ...mapActions('siteFuneral/addModify', ['submitBioForm', 'downloadPdf']),
        ...mapMutations('siteFuneral/addModify', ['ADD_OBJ_TO_ACTIVE_RECORD']),
        addObj(payload) {
            this.ADD_OBJ_TO_ACTIVE_RECORD(payload);
        },
        save() {
            this.submitBioForm(this.vp);
        },
        download() {
            this.downloadPdf(this.vp);
        },
    },
    mounted() {
        if (!this.vp) this.selectFuneral(this.$route.params.id);
    },
    created() {
        this.vp = window.location.href.search('dashboard') < 0;
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

.bio-form {
    display: flex;
    flex-direction: column;

    &.vp {
        overflow: hidden;

        &::v-deep {
            .input-grid {
                margin: 0;
            }
        }
    }

    .top-bar {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        margin: -.5rem -.5rem 1.5rem;
        flex-shrink: 0;

        > * {
            margin: .5rem;
        }

        @include not-desktop {
            flex-wrap: wrap;

            > button {
                flex-grow: 1;
            }
        }

        > div:first-child {
            flex-grow: 1;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        margin: -.5rem;

        > * {
            margin: .5rem;
        }
    }

    &::v-deep {
        .sections {
            display: flex;
            flex-direction: column;
        }

        h5 {
            color: var(--primary);
            margin-bottom: 0;
        }

        .input-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
            grid-auto-rows: max-content;
            margin: -.5rem;

            > * {
                margin: .5rem;
            }
        }

        .float-wrapper label {
            font-size: 12px;
            top: 0;
            left: 0.25rem;
            opacity: 1;
            background-color: #fff;
            transform: translateY(-50%);
        }
    }
}
</style>
