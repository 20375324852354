<template>
	<div>
		<h3 class="mb-4">Checkout</h3>

        <OrderCard
            :order="activeRecord.order"
            :show-actions="false"
        />

        <div class="mt-4">
            <form
                id="checkout-form"
                name="checkout-form"
                @submit.prevent="submitForm"
                ref="formEl"
                v-if="activeRecord.billing"
            >
                <span class="text-muted text-14 mb-1 d-block">All fields marked with * are required</span>
                <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>
                <div v-if="successMessage" class="alert alert-success">{{successMessage}}</div>

                <input type="hidden" name="_token" :value="activeRecord._token" />
                <input type="hidden" name="id" :value="activeRecord.order.id" />

                <div class="row">
                    <div class="col-md">
                        <VueInput
                            label="First Name"
                            name="firstName"
                            :float-label="false"
                            v-model="activeRecord.billing.firstName"
                            required
                            autocomplete="off"
                        />
                    </div>
                    <div class="col-md">
                        <VueInput
                            label="Last Name"
                            name="lastName"
                            :float-label="false"
                            v-model="activeRecord.billing.lastName"
                            required
                            autocomplete="off"
                        />
                    </div>
                    <div class="col-md">
                        <VueInput
                            label="Phone"
                            name="phone"
                            :float-label="false"
                            v-model="activeRecord.billing.phone"
                            required
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            autocomplete="off"
                        />
                        <p class="assistive-text">Expected Format: 123-456-7890</p>
                    </div>
                </div>

                <VueInput
                    name="Address"
                    :float-label="false"
                    v-model="activeRecord.billing.address"
                    required
                    autocomplete="off"
                />

                <div
                    class="row"
                >
                    <div class="col-md">
                        <VueInput
                            style="flex:1 0 100px"
                            name="City"
                            :float-label="false"
                            v-model="activeRecord.billing.city"
                            required
                            autocomplete="off"
                        />
                    </div>
                    <div class="col-md">
                        <VueUsStates
                            style="flex:1 0 100px"
                            name="State"
                            :float-label="false"
                            v-model="activeRecord.billing.state"
                            required
                        />
                    </div>
                    <div class="col-md">
                        <VueZipCode
                            style="flex:1 0 100px"
                            name="Zip Code"
                            :float-label="false"
                            v-model="activeRecord.billing.zip_code"
                            required
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-md">
                        <VueInput
                            label="Name on Card"
                            required
                            :float-label="false"
                            name="cardname"
                            v-model="activeRecord.cardName"
                        />
                    </div>

                    <div class="col-md">
                        <VueInput
                            label="Card Number"
                            required
                            :float-label="false"
                            name="cardnumber"
                            v-model="activeRecord.cardNumber"
                        />
                    </div>

                    <div class="col-md">
                        <VueInput
                            label="Expiration Date"
                            required
                            :float-label="false"
                            name="cardExpiration"
                            v-model="activeRecord.cardExpiration"
                        />
                    </div>

                    <div class="col-md">
                        <VueInput
                            label="CVV"
                            required
                            :float-label="false"
                            name="cardCode"
                            v-model="activeRecord.cardCode"
                        />
                    </div>
                </div>

                <button class="btn btn-primary">Pay</button>
            </form>
        </div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueInput from "@v/Core/Global/Components/VueForms/VueInput.vue";
import VuePassword from "@v/Core/Global/Components/VueForms/VuePassword.vue";
import VueUsStates from "@v/Core/Global/Components/VueForms/VueUsStates.vue";
import VueZipCode from "@v/Core/Global/Components/VueForms/VueZipCode.vue";

export default {
	name: "OrderPayment",
	components: {
		VueInput,
		VuePassword,
		VueUsStates,
		VueZipCode,
	},
	computed: {
		...mapState(["activeRecord.orders", "userData"]),
		...mapState("order/checkout", [
			'activeRecord',
			'errorMessage',
			'successMessage',
        ]),
	},
	methods: {
		...mapActions("order/checkout", [
            "submitForm"
        ]),
	},
};
</script>

<style lang="scss" scoped>
    .assistive-text {
        font-size: 14px;
        color: var(--quaternary);
        margin-top: -1rem;
    }
</style>