import { render, staticRenderFns } from "./SitePortalLoader.vue?vue&type=template&id=43345bef&scoped=true&"
var script = {}
import style0 from "./SitePortalLoader.vue?vue&type=style&index=0&id=43345bef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43345bef",
  null
  
)

export default component.exports