<template>
    <div class="d-flex flex-md-row flex-column flex-grow-1">
        <div class="w-100">
            <div class="register">
                <p class="title">Profile</p>
                <form id="profile-form" name="profile-form" @submit.prevent="submitForm" ref="formEl">
                    <span class="text-muted text-14 mb-1 d-block">All fields marked with * are required</span>
                    <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage"></div>
                    <div v-if="successMessage" class="alert alert-success ">{{successMessage}}</div>

                    <input type="hidden" name="_token" :value="activeRecord._token" />

                    <VueInput name="Name" :float-label="false" v-model="activeRecord.name" required autocomplete="off" />
                    <VueInput name="Phone" :float-label="false" v-model="activeRecord.phone" required type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" autocomplete="off" />
                    <p class="assistive-text">Expected Format: 123-456-7890</p>

                    <VueInput name="Address" :float-label="false" v-model="activeRecord.address" required autocomplete="off" />

                    <div class="wrap-gap mb-1" style="--gap: 1rem;">
                        <VueInput style="flex:1 0 100px" name="City" :float-label="false" v-model="activeRecord.city" required autocomplete="off" />
                        <VueUsStates style="flex:1 0 100px" name="State" :float-label="false" v-model="activeRecord.state" required />
                        <VueZipCode style="flex:1 0 100px" name="Zip Code" :float-label="false" v-model="activeRecord.zip_code" required />
                    </div>
                    <div class="d-flex justify-content-end flex-wrap" style="gap: 1rem">
                        <button type="button" @click.prevent="resetPassword()" class="btn btn-outline-primary border">Reset Password</button>
                        <button class="btn btn-primary">Update Profile</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import VueInput from '@v/Core/Global/Components/VueForms/VueInput.vue';
import VuePassword from '@v/Core/Global/Components/VueForms/VuePassword.vue';
import VueUsStates from '@v/Core/Global/Components/VueForms/VueUsStates.vue';
import VueZipCode from '@v/Core/Global/Components/VueForms/VueZipCode.vue';

import { mapState, mapActions } from "vuex";

export default {
    name: "ProfileIndex",
    components: {
        VueInput,
        VuePassword,
        VueUsStates,
        VueZipCode,
    },
    computed: {
        ...mapState("profile/mod", {
            activeRecord: (state) => state.activeRecord,
            formAction: (state) => state.form_action,
            isFormProcessing: (state) => state.isFormProcessing,
            errorMessage: (state) => state.errorMessage,
            successMessage: (state) => state.successMessage,
        }),
        ...mapState([
            'userData'
        ]),
    },
    methods: {
        ...mapActions("profile/mod", [
            "submitForm",
            "setUser",
            "resetPassword"
        ]),
    },
    watch: {
        // For when you refresh on the profile page, wait for userData
        userData: {
            deep: true,
            handler(n) {
                this.setUser(n);
            }
        }
    },
    mounted() {
        // For when you navigate using router and userData is already set in the rootState
        if (this.userData) this.setUser(this.userData);
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    .assistive-text {
        font-size: 12px;
        font-weight: bold;
        color: hsl(0deg,0%,40%);
        margin-top: -1rem;
    }

    .register {
        height: auto;

        // This needs to be fixed in the base at some point but not now
        &::v-deep .password {
            position: relative;

            .password-preview {
                height: calc(1.5em + .75rem + 2px);
                bottom: 0;
                top: auto;
                transform: none;
                display: flex;
                align-items: center;
            }
        }

        .title {
            margin: 0;
            color: var(--blue);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
        }

        input {
            width: 100%;
            margin: 0 .2rem;
        }

        select { padding: 3px; }

        form {margin: auto;}
    }

    .register-img {
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

</style>