<template>
    <vue-modal
        :visible="isModalOpen"
        @on-close="closeEventAddModifyModal"
        id="event-add-modify-modal"
    >
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-event mr-1 text-tertiary"></span>&nbsp;{{
                    getModalHeader
                }}
            </h5>
        </template>

        <div class="page-settings-details pb-3 pt-0" v-if="!isActionAdd">
            <p class="psd-published">
                Date Added: <span>{{ activeRecord.created_at }}</span>
            </p>
            <p class="psd-modified">
                Last Modified: <span>{{ activeRecord.updated_at }}</span>
            </p>
        </div>

        <!-- Form Include -->
        <add-modify-event-form
            :class="{ 'd-none': isFormProcessing }"
            :is-action-add="isActionAdd"
            :location="location"
            :schedule_id="schedule_id"
            @submitForm="submitForm"
            @closeModal="closeEventAddModifyModal"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-outline-primary cancel-button"
                    @click.prevent="closeEventAddModifyModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-primary ml-1"
                    :disabled="!scheduleIdIsSet"
                    form="add-modify-event-form"
                    @click.prevent="submitForm"
                >
                    {{ isFormProcessing ? "Processing..." : "Apply" }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "AddModifyEventModal",
    props:["location" ,"schedule_id"],
    computed: {
        ...mapState("schedule/addModifySchedule", {
            isModalOpen: (state) => state.isModalOpen,
            activeRecord: (state) => state.activeRecord,
            isActionAdd: (state) => state.formActionIsAdd,
            isFormProcessing: (state) => state.isFormProcessing,
        }),
        getModalHeader() {
            let action = this.isActionAdd ? "Add a New " : "Modify ";

            if(this.obitActiveRecord){
                 return action + "Event " + this.obitActiveRecord.name;
            }
            return action + "Event" + this.activeRecord.decedent

        },
        ...mapState("obituary/addModifyObituary", {
     		 obitActiveRecord: (state) => state.activeRecord
   		 }),
        scheduleIdIsSet(){
                let sched=false;
                (this.activeRecord.schedule_id > 0) ? sched= true : sched;
                return sched;
    }
    },
    methods: {
        ...mapActions("schedule/addModifySchedule", [
            "openModal",
            "closeModal",
            "resetEvent",
            "submitForm",
            "setSched"
        ]),
        ...mapActions("schedule", {
            fetchSched: "list/fetchSched",
        }),

        closeEventAddModifyModal() {
            this.closeModal();
            this.resetEvent();
        },
    },
    created() {
        this.fetchSched();
    },
    mounted(){
        this.setSched(this.schedule_id);
    }
};
</script>
