<template>
    <div class="obituary-sidebar">
        <LockeSubscriptionForm view="Obituary"/>

        <div class="cta-section">
            <p class="title">Create Your Funeral Plan</p>

            <p>The team at Locke Funeral Services has the knowledge and expertise to help you plan your funeral according to your wishes. Most importantly, we give you the tools to create your plan yourself. Create a profile to log into the Locke Funeral Services planning portal to start creating a plan for how you want your funeral handled.</p>

            <a class="btn btn-primary mt-2 mb-0" href="/what-we-do/planning-and-services/planning-options" title="Internal link in same tab to our planning and services page">
                Create Your Funeral Plan
            </a>
        </div>

        <div class="order-flowers">
            <p class="h1 font-weight-light">
                Wishing to express your condolences?
            </p>
            <p class="mt-2">
                Take the guess work and uncertainty out of sending a sympathy
                expression by ordering online through our website. We will
                coordinate delivery with the florist and ensure that your
                expression arrives on time.
            </p>
            <a class="btn btn-primary mt-1" href="https://flowers.lockefuneralservices.com" title="External link in the same tab to our flower ordering website">Order Flowers</a>
        </div>
        <vue-modal :visible="successModalVisible" @on-close="closeModal">
            <template #header>
                <p class="h2 m-0 text-success" v-if="response == 'Success'">
                    <span class="fas fa-check mr-1"></span>{{ response }}
                </p>
                <p class="h2 m-0" v-else>{{ response }}</p>
            </template>
            <p class="m-0">{{ message }}</p>
            <div id="form-errors" class="form-errors mb-0"></div>
        </vue-modal>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    computed: {
        ...mapState("condolences", [
            "successModalVisible",
            "message",
            "response"
        ])
    },
    methods: {
        ...mapMutations("condolences", ["SET_SUCCESS_MODAL", "SET_MESSAGE"]),
        closeModal() {
            this.SET_SUCCESS_MODAL(false);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

.cta-section {
    padding: 4rem 0 4rem 2rem;
    position: relative;
    margin-bottom: 2rem;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100vw;
        border-radius: 25px;
        background-color: var(--lighter-tan);
        z-index: -1;
    }

    .title {
        padding-top: 0.5rem;
        font-size: 24px;
        color: var(--darker-tan);
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 0.5rem;

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 1px;
            width: 100vw;
            background-color: var(--tan);
        }
    }
}

.order-flowers {
    @include fluid-size(80px, 160px, padding-top);
    @include fluid-size(120px, 240px, padding-bottom);
    padding-right: var(--faux-container-padding);
    padding-left: 2rem;
    margin: 2rem 0;
    position: relative;
    width: calc(100% + var(--faux-container-padding) + 1rem);

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(33%, -50%);
        width: calc(100% - 100px);
        max-height: calc(100% - 100px);
        aspect-ratio: 1/1;
        background-color: var(--light-tan);
        border-radius: 500rem;
        box-shadow: 0 0 0 5px #fff, 0 0 0 50px var(--light-tan);
        z-index: -1;
        background-image: url(/images/custom/site/lily.jpg);
        background-blend-mode: multiply;
        background-position: center bottom 28%;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .order-flowers {
        @include fluid-size(80px, 160px, padding-top);
        @include fluid-size(120px, 240px, padding-bottom);
        padding-right: var(--faux-container-padding);
        padding-left: 2rem;
        margin: 2rem 0;
        position: relative;
        width: calc(100% + var(--faux-container-padding) + 1rem);

        @supports (aspect-ratio: 1/1) {
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(33%,-50%);
                width: calc(100% - 100px);
                max-height: calc(100% - 100px);
                aspect-ratio: 1/1;
                background-color: var(--light-tan);
                border-radius: 500rem;
                box-shadow: 0 0 0 5px #fff,0 0 0 50px var(--light-tan);
                z-index: -1;
                background-image: url(/images/custom/site/lily.jpg);
                background-blend-mode: multiply;
                background-position: center bottom 28%;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}
</style>
