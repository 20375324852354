<template>
	<vue-modal
		:visible="isModalOpen"
		@on-close="closeFuneralUserAddModifyModal"
		id="login-modal"
	>
		<template #header>
			<h5 class="modal-title">
				<span class="fas fa-event mr-1 text-tertiary"></span>&nbsp;{{
                    getModalHeader
                }}
			</h5>
		</template>
		<!-- Form Include -->
        <LoginForm></LoginForm>

		<vpanel-loader v-if="isFormProcessing" />

		<template #footer>
			<div class="d-flex justify-content-end">
				<button
					type="button"
					class="btn btn-outline-primary cancel-button"
					@click.prevent="closeFuneralUserAddModifyModal"
				>
					Cancel
				</button>
				<button
					type="submit"
					class="btn btn-primary ml-1"
					:disabled="isFormProcessing"
					form="add-modify-event-form"
					@click.prevent="submitForm"
				>
					{{ isFormProcessing ? "Processing..." : "Apply" }}
				</button>
			</div>
		</template>
	</vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
	name: "SiteLoginModal",
    props:['visible'],
	computed: {
		...mapState("cart/addModifyCart", {
			isModalOpen: (state) => state.isModalOpen,
			// activeRecord: (state) => state.activeRecord,
			// isActionAdd: (state) => state.isActionAdd,
			isFormProcessing: (state) => state.isFormProcessing,
		}),
		getModalHeader() {
			let action = this.isActionAdd ? "Invite a New " : "Modify ";
			return action + "Funeral User";
		},
        isVisible()
        {
            if(this.visible){

                    this.openModal;
                    return true;
            }else{

                return false;
            }
        }

	},
	methods: {
		...mapActions("cart/addModifyCart", [
			"openModal",
			"closeModal",
			// "resetFuneralUser",
			// "submitForm",
			"setFormIsAdd",
		]),

		closeFuneralUserAddModifyModal() {
			this.setFormIsAdd(true);
			this.resetFuneralUser();
			this.closeModal();
		},
	},
};
</script>
