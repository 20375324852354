<template>

  <div class="simple-table-wrapper simple-table-wrapper-lg" v-if="!condolences.isLoading">
        <form class="search-form mb-5" @submit.prevent="condolences.searchModel = searchModel">
            <div class="search-fields d-flex flex-wrap align-items-center">
                <input
                    class="st-search w-100 w-md-auto"
                    type="search"
                    v-model="searchModel"
                    placeholder="Search..."
                />
                <button type="submit" class="btn btn-primary ml-2"><span class="fas fa-search"></span> APPLY FILTERS</button>
            </div>
        </form>

        <div class="st-hidden-sorts">
            <p @click="condolences.sortBy('decedent')" :class="condolences.sortClass('decedent')">Decedent</p>

            <p @click="condolences.sortBy('created_at', null, 'date')" :class="condolences.sortClass('created_at')">Created At</p>
        </div>

        <div class="simple-table" style="--cols:5">
            <p class="first-col st-header" @click="condolences.sortBy('decedent')" :class="condolences.sortClass('decedent')">Decedent</p>
            <p class="st-header">Name</p>
             <p class="st-header" @click="condolences.sortBy('created_at', null, 'date')" :class="condolences.sortClass('created_at')">Created</p>
         <p class="last-col st-header">Options</p>
            <template v-for="row in condolences.displayedList">

                <div class="first-col" :key="row.id+'decedent'">
                    <span class="st-hidden">Decedent</span>
                    {{ row.decedent }}
                </div>
                <p :key="row.id+'name'">
                    <span class="st-hidden">Name</span>
                    {{ row.name }}
                </p>
                   <div :key="row.id+'created_at'">
                    <span class="st-hidden">Created</span>
                    {{ row.created_at }}
                </div>

                <div :key="row.id+'options'">
                    <span class="st-hidden">Options</span>
                    <div class="st-buttons">
                        <button type="button" class="btn btn-secondary" @click.prevent="modifyCondolence(row)">Modify</button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="recycleCondolence(row)">Delete</button>
                    </div>
                </div>
            </template>
        </div>

        <p>{{ condolences.rangeText }}</p>
        <Pagination
            :active-page="condolences.activePage"
            :item-count="condolences.itemCount"
            :items-per-page="condolences.itemsPerPage"
            @on-update="condolences.updateActivePage($event)"
        />
    </div>
    <VueLoader v-else />

</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import EloquentSSFP from '@j/core/global/EloquentSSFP';
export default {
  name: "VpanelCondolenceList",
  data() {
        return {
          condolences: new EloquentSSFP("/vpanel/modules/obituary/condolence/condolence-list", {
          activeSorts: ["created_at","decedent"],
          sortDirs: ["desc"],
          sortTypes: ["date","string"],
          searchableData: ["decedent"],
    itemsPerPage: 10
    }),
            searchModel: ""
        }
    },
  computed: {
    ...mapState("condolence/list", {
      cspNonce: (state) => state.cspNonce,
      isUpdatedIncrement: (state) => state.isUpdatedIncrement,
    }),
  },
  methods: {

    modifyCondolence(row){
      this.SET_ACTIVE_RECORD(row);
      this.setFormIsAdd(false);
      this.openModal(true);
    },
    recycleCondolence(row){
       this.SET_ACTIVE_RECORD(row);
      this.openRecycleModal(null);

    },

    ...mapMutations("condolence/addModifyCondolence",["SET_ACTIVE_RECORD"]),
    ...mapActions("condolence/addModifyCondolence",["setFormIsAdd","openModal"]),
    ...mapActions("condolence",{openRecycleModal:"recycleCondolenceModal/openModal"})
  },
    watch: {
            isUpdatedIncrement(n,o) {
                this.condolences.refreshData(this.condolences.options);
            },
            searchModel(n,o) {
                if (!n && o) {
                    this.condolences.updateSearch(n);
                }
            }
        }
};
</script>
<style lang="scss" scoped>
    @import '../../../../../Core/Global/Components/VueTable/scss/_table-styles.scss';

    .simple-table {
        // Custom col structure to make the last col only be as wide as it needs to be
        --custom-col-structure: repeat(3, auto) max-content;
    }
</style>