<template>
    <div class="d-flex flex-md-row flex-column flex-grow-1">
        <div class="flex-grow-1 d-flex align-items-center justify-content-center">
            <div class="register">
                <p class="title">Register</p>
                <form
                    id="register"
                    name="register"
                    @submit.prevent="submitForm"
                    ref="formEl"
                >
                    <span class="text-muted text-14 mb-1 d-block">All fields marked with * are required</span>
                    <div
                        v-if="errorMessage"
                        class="alert alert-danger form-errors"
                        v-html="errorMessage"
                    ></div>

                    <input
                        type="hidden"
                        name="_token"
                        :value="activeRecord._token"
                    />

                    <VueInput
                        name="Name"
                        :float-label="false"
                        v-model="activeRecord.name"
                        required
                    />
                    <VueInput
                        name="Email"
                        :float-label="false"
                        v-model="activeRecord.email"
                        required
                        type="email"
                    />
                    <VueInput
                        name="Phone"
                        :float-label="false"
                        v-model="activeRecord.phone"
                        v-mask="'###-###-####'"
                        required
                        type="tel"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    />
                    <p class="assistive-text">Expected Format: 123-456-7890</p>

                    <VueInput
                        name="Address"
                        :float-label="false"
                        v-model="activeRecord.address"
                        required
                    />

                    <div
                        class="wrap-gap mb-1"
                        style="--gap: 1rem;"
                    >
                        <VueInput
                            style="flex:1 0 100px"
                            name="City"
                            :float-label="false"
                            v-model="activeRecord.city"
                            required
                        />
                        <VueUsStates
                            style="flex:1 0 100px"
                            name="State"
                            :float-label="false"
                            v-model="activeRecord.state"
                            required
                        />
                        <VueZipCode
                            style="flex:1 0 100px"
                            name="Zip Code"
                            :float-label="false"
                            v-model="activeRecord.zip_code"
                            required
                        />
                    </div>

                    <VuePassword
                        class="password"
                        name="Password"
                        :float-label="false"
                        v-model="activeRecord.password"
                        required
                    />
                    <VuePassword
                        class="password"
                        name="Confirm Password"
                        :float-label="false"
                        v-model="activeRecord.password_confirmation"
                        required
                    />
                    <!--
                        ^^^^^
                        This should be removed, the only reason confirm password as a concept
                        exists is because people weren't able to see what they typed. This
                        component removes that limitation with a visibility toggle.
                    -->
                    <VueHcaptcha
                        :sitekey="captcha"
                        @verify="onVerify"
                        @expired="onExpire"
                        @error="onError"
                    />

                    <button
                        type="submit"
                        class="btn btn-primary align-self-end"
                        :disabled="!verified"
                    >Submit</button>

            </form>
            </div>
        </div>
        <div class="flex-grow-1 register-img">
            <img
                src="/images/custom/site/Collaboration-Image_square.jpg"
                alt="Decorative image of two people collaborating"
            />
            <div class="login-text text-center w-100">
                <p class="reg-title">Already have an account?</p>
                <p class="sub-text">
                    Click the button below and log in.
                </p>
                <a
                    href="/login"
                    title="internal link in new tab to login page"
                    class="btn btn-primary"
                >Login</a>
            </div>
        </div>
    </div>
</template>

<script>
import VueInput from '@v/Core/Global/Components/VueForms/VueInput.vue';
import VuePassword from '@v/Core/Global/Components/VueForms/VuePassword.vue';
import VueUsStates from '@v/Core/Global/Components/VueForms/VueUsStates.vue';
import VueZipCode from '@v/Core/Global/Components/VueForms/VueZipCode.vue';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import { mask } from 'vue-the-mask';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'RegisterForm',
  props: ['captcha'],
  directives: { mask },
  components: {
    VueInput,
    VuePassword,
    VueUsStates,
    VueZipCode,
    VueHcaptcha,
  },
  data() {
    return {
      verified: false,
      expired: false,
      error: null,
      token: null,
      eKey: null,
    };
  },
  computed: {
    ...mapState('register', {
      activeRecord: (state) => state.activeRecord,
      formAction: (state) => state.form_action,
      isFormProcessing: (state) => state.isFormProcessing,
      errorMessage: (state) => state.errorMessage,
      states: (state) => state.states,
    }),
  },
  methods: {
    ...mapActions('register', ['submitForm']),

    onVerify(token, ekey) {
      this.verified = true;
      this.token = token;
      this.eKey = ekey;
    },
    onExpire() {
      this.verified = false;
      this.token = null;
      this.eKey = null;
      this.expired = true;
    },
    onError(err) {
      this.token = null;
      this.eKey = null;
      this.error = err;
    },
  },

};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

.assistive-text {
    font-size: 12px;
    font-weight: bold;
    color: hsl(0deg, 0%, 40%);
    margin-top: -1rem;
}

.register {
    @include fluid-size(40px, 80px, padding);
    height: auto;

    // This needs to be fixed in the base at some point but not now
    &::v-deep .password {
        position: relative;

        .password-preview {
            height: calc(1.5em + 0.75rem + 2px);
            bottom: 0;
            top: auto;
            transform: none;
            display: flex;
            align-items: center;
        }
    }

    .title {
        margin: 0;
        color: var(--blue);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
    }

    input {
        width: 100%;
        margin: 0 0.2rem;
    }

    select {
        padding: 3px;
    }

    form {
        margin: auto;
    }
}

.register-img {
    position: relative;
    display: flex;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: hsla(0deg, 0%, 100%, 65%);
        z-index: -1;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .login-text {
        @include fluid-size(40px, 80px, padding);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        display: flex;

        .login-title {
            margin: 0;
            color: var(--blue);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 24px;
        }

        .sub-text {
            margin: 0;
            font-weight: bold;
            font-size: 18px;
            padding: 5px;
        }
    }
}
</style>