<template>
    <div class="cremation-main">
        <div class="content container my-5">
            <h2 class="top-title">Complete Your Planning</h2>

            <div v-if="successMessage" class="alert alert-success">
                {{ successMessage }}
            </div>
            <div class="form-errors"></div>
            <div class="d-flex justify-content-center text-center">
                <div class="col-md-6">
                    <h3 class="text-center text-primary title-option my-4">
                        <span>Who Is This Funeral For?</span>
                    </h3>
                    <vue-input
                        name="Name"
                        v-model="activeRecord.funeralInfo.funeral_name"
                        required
                    ></vue-input>
                    <h3 class="text-center text-primary title-option my-4">
                        <span>Choose Locke Location</span>
                    </h3>
                    <select
                        name="Filter by Location"
                        :options="branches"
                        v-model="activeRecord.funeralInfo.funeral_location"
                        class="form-control"
                        autocomplete="Select Location"
                        required
                    >
                        <option v-for="branch in branches" :key="branch.id">
                        {{ branch.name }}
                        </option>
                        <option>Other</option>
                    </select>
                    <vue-input
                        v-if="activeRecord.funeralInfo.funeral_location == 'Other'"
                        name="Other Location"
                        v-model="activeRecord.funeralInfo.other_location"
                        required
                        class="my-1"
                    ></vue-input>
                    <h3 class="text-center text-primary title-option my-4">
                        <span>Your Information</span>
                    </h3>
                    <vue-input
                        name="Relationship to Decedent"
                        v-model="activeRecord.funeralInfo.relationship"
                        required
                    ></vue-input>
                    <p>Your Info</p>
                    <vue-checkbox
                        name="My Info is correct"
                        v-model="activeRecord.useAuthUser"
                        :checked="true"
                    ></vue-checkbox>
                    <div v-if="activeRecord.useAuthUser">
                        <vue-input
                        name="Your Name"
                        v-model="user.name"
                        disabled
                        ></vue-input>

                        <vue-input name="Phone" v-model="user.phone" disabled></vue-input>
                        <vue-input name="Email" v-model="user.email" disabled></vue-input>
                    </div>
                    <div v-else>
                        <vue-input
                            name="Your Name"
                            v-model="activeRecord.funeralInfo.your_info.name"
                            required
                        ></vue-input>
                        <vue-input
                            name="Phone"
                            v-model="activeRecord.funeralInfo.your_info.phone"
                            required
                        ></vue-input>
                        <vue-input
                            name="Email"
                            v-model="activeRecord.funeralInfo.your_info.email"
                            required
                        ></vue-input>
                    </div>

                    <p class="my-5">When you submit your form we will review the steps you have taken. We will review the submission during our next business hours or sooner. <strong>Again if you have an immediate need for our help because of a death and have not talked to us by phone please call us at <a href="tel:3192336138" title="Opens our number in your default calling application">(319)&nbsp;233&#8209;6138</a> so that we may begin helping you immediately.</strong></p>

                    <p class="my-5">We will reach out to you to gather more information, answer questions, review your submission and choices, discuss timing, and arrange for payment.  </p>

                    <button class="btn btn-primary" @click="submitForm">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    data() {
        return {
            userInfoIsCorrect: true,
        };
    },
    computed: {
        ...mapState("cart/addModifyCart", {
            activeRecord: (state) => state.activeRecord,
            products: (state) => state.products,
            cart: (state) => state.cart,
            cartCount: (state) => state.cartCount,
            customerIsProvidingContainer: (state) => state.customerIsProvidingContainer,
            user: (state) => state.user,
            branches: (state) => state.branches,
            successMessage: state => state.successMessage,
            errorMessage: state => state.errorMessage,
        }),
        hasUser() {
            if (!this.user) return false;
            return !!Object.keys(this.user);
        },
    },
    methods: {
        ...mapActions("cart/addModifyCart", ["fetchBranches", "submitForm"]),
    },
    created() {
        this.fetchBranches();
    },
};
</script>
<style lang="scss" scoped>
@import "../../../../../sass/_vue-import.scss";

    .app-main {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
</style>