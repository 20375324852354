<template>
  <div>
    <div
      id="form-errors"
      v-if="errorMessage"
      class="alert alert-danger form-errors alert-dismissible"
      v-html="errorMessage"
    ></div>
    <div
      v-if="successMessage"
      class="alert alert-success alert-dismissible"
      v-html="successMessage"
    ></div>

    <form
      id="site-add-modify-event-form"
      class="event-form needs-validation"
      name="add-modify-event-form"
      novalidate
      @submit.prevent="submitForm"
    >
      <div class="form-errors"></div>
      <input
        type="hidden"
        name="_token"
        :value="activeRecord._token"
      />
      <input
        type="hidden"
        name="id"
        :value="activeRecord.id"
        v-if="!isActionAdd"
      />

      <h3 class="mb-2">Basic Information</h3>

      <div class="row">
        <div class="col-md">
          <VueInput
            required
            :float-label="false"
            label="Event Name"
            name="name"
            v-model="activeRecord.name"
          />
        </div>
        <div class="col-md">
          <VueInput
            required
            :float-label="false"
            label="Location Name"
            name="location_name"
            v-model="activeRecord.location_name"
          />
        </div>
      </div>

      <div class="row align-items-end">
        <div class="col-md">
          <VueCheckbox
            label="Show day of week?"
            name="show_day"
            v-model="activeRecord.show_day_of_week"
          />
        </div>
        <div class="col-md">
          <VueDate
            required
            class="un-float-label"
            label="Date"
            name="date"
            v-model="activeRecord.date"
          />
        </div>
      </div>

      <div class="row align-items-end">
        <div class="col-md">
          <VueCheckbox label="Show start time?" name="show_start_time" v-model="showStartTime" />
        </div>
        <div class="col-md" v-show="showStartTime">
          <VueTime
            class="un-float-label"
            label="Start Time"
            name="start_time"
            v-model="activeRecord.start_time"
          />
        </div>
        <div class="col-md">
          <VueCheckbox
            label="Show end time?"
            name="show_end_time"
            v-model="showEndTime"
          />
        </div>
        <div class="col-md" v-show="showEndTime">
          <VueTime
            class="un-float-label"
            label="End Time"
            name="end_time"
            v-model="activeRecord.end_time"
          />
        </div>
      </div>

      <VueInput
        :float-label="false"
        label="Address"
        name="address"
        v-model="activeRecord.location_address.address1"
      />

      <div class="row">
        <div class="col-md">
          <VueInput
            :float-label="false"
            label="City"
            name="city"
            v-model="activeRecord.location_address.city"
          />
        </div>
        <div class="col-md">
          <VueUsStates
            :float-label="false"
            label="State"
            name="state"
            v-model="activeRecord.location_address.state"
          />
        </div>
        <div class="col-md">
          <VueZipCode
            :float-label="false"
            label="Zip Code"
            name="zip"
            v-model="activeRecord.location_address.zip"
          />
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="btn btn-primary">Save Event</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: ['eventId', 'funeralId'],
  data() {
    return {
      showStartTime: true,
      showEndTime: true,
    };
  },
  methods: {
    ...mapActions('schedule/siteAddModifySchedule', [
      'submitForm',
      'fetchEvent',
    ]),
  },
  computed: {
    ...mapState('schedule/siteAddModifySchedule', [
      'activeRecord',
      'errorMessage',
      'successMessage',
      'isActionAdd',
      'resetEvent'
    ]),
    ...mapState(['funerals']),
    ...mapState('schedule/siteAddModifySchedule', {
      options: (state) => state.states,
    }),
    funeral() {
      return this.funerals[this.$route.params.id];
    },
  },
  watch: {
    showStartTime(n) {
      if (!n) this.activeRecord.start_time = '';
    },
    showEndTime(n) {
      if (!n) this.activeRecord.end_time = '';
    },
  },
  mounted() {
    if (this.eventId === 'add') this.actionIsAdd = true;
    else this.fetchEvent({ eventId: this.eventId, funeralId: this.funeralId });
    this.activeRecord.schedule_id = this.funeral.schedule_id;
  },
  unmounted() {
    this.resetEvent(false);
  }
};
</script>

<style lang="scss" scoped>
  .un-float-label {
    &::v-deep label {
      position: static !important;
      padding: 0 !important;
      font-size: 14px !important;
      transform: none !important;
      line-height: inherit !important;
    }
  }

  .un-float-legend {
    margin-top: 1rem;

    &::v-deep .fieldset-label {
      transform: translateY(-100%);
      line-height: inherit !important;
      background-color: transparent;
    }
  }
</style>
