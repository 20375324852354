<template>
	<div
		class="simple-table-wrapper simple-table-wrapper-lg"
		v-if="!funeral.isLoading"
	>
		<form
			class="search-form mb-5"
			@submit.prevent="funeral.searchModel = searchModel"
		>
			<div class="search-fields d-flex flex-wrap align-items-center">
				<input
					class="st-search w-100 w-md-auto"
					type="search"
					v-model="searchModel"
					placeholder="Search..."
				/>
				<button
					type="submit"
					class="btn btn-primary ml-2"
				><span class="fas fa-search"></span> APPLY FILTERS</button>
			</div>
		</form>

		<div class="st-hidden-sorts">
			<p
				@click="funeral.sortBy('name')"
				:class="funeral.sortClass('name')"
			>Decedent</p>

            <p
				@click="funeral.sortBy('case_type')"
				:class="funeral.sortClass('case_type')"
			>Case Type</p>

			<p
				@click="funeral.sortBy('created_at', null, 'date')"
				:class="funeral.sortClass('created_at')"
			>Created At</p>
		</div>

		<div
			class="simple-table"
			style="--cols:4"
		>
			<p
				class="first-col st-header"
				@click="funeral.sortBy('name')"
				:class="funeral.sortClass('name')"
			>Decedent</p>

            <p
				class="st-header"
				@click="funeral.sortBy('case_type', null, 'date')"
				:class="funeral.sortClass('case_type')"
			>Case Type</p>
			<p
				class="st-header"
				@click="funeral.sortBy('created_at', null, 'date')"
				:class="funeral.sortClass('created_at')"
			>Created</p>
			<p class="last-col st-header">Options</p>
			<template v-for="row in funeral.displayedList">

				<div
					class="first-col"
					:key="row.id+'name'"
				>
					<span class="st-hidden">Decedent</span>
					{{ row.name }}
				</div>
				<p :key="row.id+'case_type'">
					<span class="st-hidden">Case Type</span>
					{{ row.case_type }}
				</p>
				<div :key="row.id+'created_at'">
					<span class="st-hidden">Created</span>
					{{ row.created_at }}
				</div>

				<div :key="row.id+'options'">
					<span class="st-hidden">Options</span>
					<div class="st-buttons">
						<button
							type="button"
							class="btn btn-secondary"
							@click.prevent="modifyFuneralBio(row)"
						>Modify Bio</button>
						<button
							type="button"
							class="btn btn-secondary"
							@click.prevent="invite(row)"
						>Invite</button>
						<button
							type="button"
							class="btn btn-outline-primary"
							@click.prevent="recycleFuneral(row)"
						>Delete</button>

					</div>
				</div>
			</template>
		</div>

		<p>{{ funeral.rangeText }}</p>
		<Pagination
			:active-page="funeral.activePage"
			:item-count="funeral.itemCount"
			:items-per-page="funeral.itemsPerPage"
			@on-update="funeral.updateActivePage($event)"
		/>
	</div>
	<VueLoader v-else />

</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import EloquentSSFP from "@j/core/global/EloquentSSFP";
export default {
	name: "VpanelFuneralList",

	data() {
		return {
			funeral: new EloquentSSFP(
				"/vpanel/modules/obituary/funeral/funeral-list",
				{
					activeSorts: ["created_at", "name","case_type"],
					sortDirs: ["desc","desc","desc"],
					sortTypes: ["date", "string","string"],
					searchableData: ["name","case_type"],
					itemsPerPage: 10,
				}
			),
			searchModel: "",
		};
	},
	methods: {
		modifyFuneral(row) {
			this.SET_ACTIVE_RECORD(row);
			this.setFormIsAdd(false);
			this.openModal(true);
		},
		recycleFuneral(row) {
			this.SET_ACTIVE_RECORD(row);
			this.openRecycleModal(null);
		},
		modifyFuneralBio(row) {
			this.setRecord(row);
			this.$router.push({
				name: "BioIndex",
				params: { id: row.id, site: false },
			});
		},

		...mapActions("funeral", {
			openRecycleModal: "recycleFuneralModal/openModal",
		}),
		...mapMutations("funeral/addModify", ["SET_ACTIVE_RECORD"]),
		...mapMutations("siteFuneral/addModify", {
			setRecord: "SET_ACTIVE_RECORD",
		}),

		...mapActions("funeral/addModify", [
			"setFormIsAdd",
			"openModal",
			"fetchFuneral",
		]),
		...mapActions("user/addModifyUser", {
			openInviteModal: "openModal",
			closeInviteModal: "closeModal",
		}),

		...mapMutations("user/addModifyUser", ["SET_VAR"]),
		invite(row) {
			var payload = null;
			//  console.log(row);
			payload = { variable: "funeral_id", value: row.funeral_id };
			this.SET_VAR(payload);
			payload = { variable: "organizer", value: "vpanel" };
			this.SET_VAR(payload);
			payload = { variable: "isVpanel", value: true };
			this.SET_VAR(payload);
			this.openInviteModal();
		},
	},
	computed: {
		...mapState("funeral/list", {
			cspNonce: (state) => state.cspNonce,
			isUpdatedIncrement: (state) => state.isUpdatedIncrement,
		}),
		...mapState("user/addModifyUser", [
			"activeRecord",
			"isFormProcessing",
			"didSaveDraft",
			"isActionAdd",
			"formErrors"
    ]),
	},
	watch: {
		isUpdatedIncrement(n, o) {
			this.funeral.refreshData(this.funeral.options);
		},
		searchModel(n, o) {
			if (!n && o) {
				this.funeral.updateSearch(n);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../../../../Core/Global/Components/VueTable/scss/_table-styles.scss";

.simple-table {
	// Custom col structure to make the last col only be as wide as it needs to be
	--custom-col-structure: repeat(3, auto) max-content;
}
</style>
