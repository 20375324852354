<template>
    <div id="obituary-module">
        <SiteObituaryDetails v-bind="{...$props, ...$attrs}" v-if="activeObituary" />
        <SiteObituaryList v-bind="{...$props, ...$attrs}" v-else />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SiteObituaryIndex',
    props: ['obituaries', 'obituary', 'ao', 'hcaptchaSiteKey'],
    computed: {
        ...mapState('obituary', [
            'isLoading',
            'activeObituary'
        ]),
    },
    methods: {
        ...mapActions('obituary', [
            'setHcaptchaSiteKey',
            'setActiveObituary'
        ])
    },
    mounted() {
        this.setHcaptchaSiteKey(this.hcaptchaSiteKey);
        this.setActiveObituary(this.ao);
    }
};
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../sass/_vue-import.scss';

    #obituary-module::v-deep {
        .obituary-layout {
            display: flex;
            margin: 1rem -1rem;

            @include desktop {
                .obituary-left { flex: 1 1 math.div(200%, 3); }
                .obituary-sidebar {
                    max-width: 500px;
                    flex: 1 1 math.div(100%, 3);
                }
            }

            @include not-desktop {
                flex-direction: column;

                .obituary-sidebar { order: 1; }
            }

            .obituary-left, .obituary-sidebar { margin: 1rem; }
        }
    }
</style>

<style lang="scss">
    body[data-obituary-details] {
        --cover-size: calc(var(--hd-height) + 200px + 5vw);
        --profile-img-size: clamp(120px, 16vw, 300px);
        --faux-container-padding: max(15px, calc((100vw - 17px - 1570px) / 2));
    }

    .static-warning {
        position: relative;

        &:before {
            content: "This is static content and is subject to change";
            position: absolute;
            height: 100%;
            width: 100%;
            border: 2px solid var(--warning);
            z-index: 1;
            color: transparent;
            background-image: repeating-linear-gradient(
                45deg,
                white 2px,
                var(--warning) 2px,
                var(--warning) 6px,
                white 6px,
                white 15px
            );
            top: 0;
            left: 0;
            opacity: .05;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            transition: 200ms ease-out;
            text-shadow:
                0 0 0 transparent,
                0 0 0 transparent,
                0 0 0 transparent,
                0 0 0 transparent,
                0 0 0 transparent,
                0 0 0 transparent,
                0 0 0 transparent,
                0 0 0 transparent;
        }

        &:hover:before {
            opacity: 1;
            color: black;
            text-shadow:
                2px 0 0 white,
                2px 2px 0 white,
                0 2px 0 white,
                -2px 2px 0 white,
                -2px 0 0 white,
                -2px -2px 0 white,
                0 -2px 0 white,
                2px -2px 0 white;
        }
    }
</style>