<template>
    <div id="portal-module">
        <portal-secondary-nav />
        <router-view />
    </div>
</template>
<script>
    export default {
        name: "ModuleIndex",
    };
</script>
<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';
    #portal-module {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        &::v-deep {
            .datatable-section {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow: hidden;

                #datatable_wrapper {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;

                    &.container-fluid { padding: 0; }
                }

                input[type="search"] { margin: 0 !important; }

                .datatable-info .dataTables_info { padding: 0; }

                .datatable-header,
                .datatable-footer {
                    display: flex;
                    justify-content: space-between;
                }

                .datatable-footer { padding: .5rem 0; }

                .datatable-body {
                    flex-grow: 1;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;

                    .dataTables_scroll {
                        height: 100%;
                        display: flex;
                        flex-grow: 1;
                        flex-direction: column;

                        .dataTables_scrollHead { overflow: inherit !important; }

                        .dataTables_scrollBody {
                            @extend %minimal-scrollbar;
                            max-height: none !important;
                            border: 1px solid #e9ecef;
                            overflow-x: hidden !important;
                            overflow-y: auto !important;

                            thead tr th.table-head { padding: 0 !important; }
                        }
                    }
                }
            }
        }
    }
</style>