<template>
    <div class="d-flex flex-md-row flex-column" style="min-height:100%">
        <div class="d-flex align-items-center justify-content-center" style="flex:1 0 min(100%, 300px)">
            <div class="reset-password">
                <p class="title">Reset Password</p>
                <form id="reset-form" name="reset-form" @submit.prevent="submitForm" ref="formEl" >
                    <span class="text-muted text-14 mb-1 d-block">All fields marked with * are required</span>

                    <div v-if="errorMessage" class="alert alert-danger form-errors" v-html="errorMessage" ></div>

                    <div v-if="successMessage" class="alert alert-success" >
                        {{ successMessage }}
                        <a href="/login" title="Internal link to return to the login page">Click here to return to the login page</a>
                    </div>

                    <input type="hidden" name="token" v-model="activeRecord.token" />

                    <VueInput name="Email" v-model="activeRecord.email" required :float-label="false" type="email" />
                    <VuePassword name="Password" v-model="activeRecord.password" required :float-label="false" class="password" />
                    <VuePassword name="Password" v-model="activeRecord.password_confirmation" required :float-label="false" class="password" />

                    <button class="btn btn-primary">Update Password</button>
                </form>
            </div>
        </div>
        <div class="flex-grow-1 d-md-block d-none register-img" style="flex:1 0 min(100%, 300px)">
            <img src="/images/custom/site/login-reg-flower.jpg" alt="Decorative image of flowers"/>
        </div>
	</div>
</template>

<script>
    import VueInput from '@v/Core/Global/Components/VueForms/VueInput.vue';
    import VuePassword from '@v/Core/Global/Components/VueForms/VuePassword.vue';
    import { mapState, mapActions } from "vuex";

    export default {
        name: "ResetPasswordForm",
        props: ["token"],
        components: {
            VueInput,
            VuePassword,
        },
        computed: {
            ...mapState("reset", [
                "activeRecord",
                "isFormProcessing",
                "errorMessage",
                "successMessage",
            ]),
        },
        methods: {
            ...mapActions("reset", [
                "submitForm"
            ]),
        },
        mounted() {
            var pathArray = window.location.pathname.split("/");

            this.activeRecord.token = pathArray[2];
        },
    };
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    .reset-password {
        @include fluid-size(40px, 80px, padding);
        height: auto;

        // This needs to be fixed in the base at some point but not now
        &::v-deep .password {
            position: relative;

            .password-preview {
                height: calc(1.5em + .75rem + 2px);
                bottom: 0;
                top: auto;
                transform: none;
                display: flex;
                align-items: center;
            }
        }

        .title {
            margin: 0;
            color: var(--blue);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
        }

        input {
            width: 100%;
            margin: 0 .2rem;
        }

        select { padding: 3px; }

        form {margin: auto;}
    }

    .register-img {
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
</style>