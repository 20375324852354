<template>
    <div class="header-template-locke">
        <div class="dynamic-container">
            <div class="logo-container">
                <img :src="logoPath" alt="Company logo">
                <vue-link link-path="/" link-text="Company name home" link-class="absolute-fill text-throw"></vue-link>
            </div>
            <div class="top-bar">
                <!-- <vue-site-search
                    :search-template="searchTemplate"
                ></vue-site-search> -->
                <div class="links" v-if="primaryLinks || primaryCta">
                    <a class="locke-blog-link btn" href="/blog" title="Opens internal link to our blog page in the same tab">Our Blog</a>
                    <div class="primary-links" v-if="primaryLinks">
                        <vue-link
                            v-for="link in primaryLinks"
                            :key="link.text"
                            :link-obj="link"
                        ></vue-link>
                    </div>
                    <div class="ctas" v-if="primaryCta">
                        <vue-link v-if="primaryCta" :link-obj="primaryCta"></vue-link>
                    </div>
                </div>
                <div class="tooltip-tab" :class="{active: contactTooltipVisible}">
                    <button
                        class="tooltip-btn btn btn-light-tan btn-sm rounded-pill text-uppercase"
                        @click="contactTooltipToggle"
                    ><span class="fas fa-comments d-md-none"></span><span class="d-none d-md-inline">Contact Us</span> <span class="fas fa-chevron-down ml-1"></span></button>
                    <div class="tooltip">
                        <p class="tooltip-heading">Need Help?</p>
                        <p><vue-link link-path="/contact-us" link-text="Contact Us Online"></vue-link></p>
                        <p><vue-link link-path="/locations" link-text="View All Locations &amp; Hours"></vue-link></p>
                        <p class="d-flex justify-content-between">Locke on Fourth<vue-link class="ml-2" link-path="tel:3192336138" link-text="319-233-6138"></vue-link></p>
                        <p class="d-flex justify-content-between">Locke at Tower Park<vue-link class="ml-2" link-path="tel:3192333146" link-text="319-233-3146"></vue-link></p>
                        <p class="d-flex justify-content-between">Locke Garden View Chapel<vue-link class="ml-2" link-path="tel:3192322222" link-text="319-232-2222"></vue-link></p>
                        <p class="d-flex justify-content-between mb-0">Locke in La Porte City<vue-link class="ml-2" link-path="tel:3193423131" link-text="319-342-3131"></vue-link></p>
                    </div>
                </div>
                <div class="tooltip-tab" :class="{ active: memberTooltipVisible }">
                    <button
                        class="tooltip-btn btn btn-light-tan btn-sm rounded-pill text-uppercase"
                        @click="memberTooltipToggle"
                    >
                        <span class="fas fa-user-circle d-md-none"></span><span class="d-none d-md-inline">My Account</span>
                        <span class="fas fa-chevron-down ml-1"></span>
                    </button>
                    <div class="tooltip">
                        <p class="tooltip-heading">Account Info</p>
                        <template v-if="isLoggedIn">
                            <p><vue-link @click="logout" link-text="Log Out" key="tooltip_0"/></p>
                            <p><vue-link link-path="/dashboard/orders" link-text="Order History" key="tooltip_1"/></p>
                            <p><vue-link link-path="/dashboard/profile" link-text="Account Settings" key="tooltip_2" /></p>
                            <p><vue-link link-path="/locke-planning-center" link-text="Advance Planning" key="tooltip_3" /></p>
                        </template>
                        <template v-else>
                            <a class="btn btn-blue" href="/login" title="Internal link to our login page in the same tab" key="tooltip_4">Log In</a>
                            <p>New here? <a href="/register" title="Internal link to our registration page in the same tab" key="tooltip_5">Create Your Account</a></p>
                        </template>
                    </div>
                </div>
            </div>
            <div class="bottom-bar">
                <div class="d-flex align-items-center">
                    <vue-nav-template-locke
                        :mobile-expanded="mobileNavExpanded"
                        :template="headerTemplate"
                        nav-template="locke"
                        :nav-info="navInfo"
                        :current-page-id="currentPageId"
                        @open-mobile-menu="openMobileMenu()"
                        @close-mobile-menu="closeMobileMenu()"
                    ></vue-nav-template-locke>
                    <div class="mobile-controls">
                        <a class="btn btn-outline-white" href="/obituaries" title="Opens internal link to our obituaries page in the same tab">Obituaries</a>
                        <p class="open-mobile-menu" @click="openMobileMenu()">
                            <span class="fas fa-bars"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { themingMixin } from '@v/Core/Site/Mixins/themingMixin';
import { navMixin } from '@v/Core/Site/Mixins/navMixin';
import { headerMixin } from '@v/Core/Site/Mixins/headerMixin';

export default {
    mixins: [
        themingMixin,
        navMixin,
        headerMixin
    ],
    props: [
        "primaryLinks",
        "secondaryLinks",
        "primaryCta",
        "secondaryCta",
    ],
    data() {
        return {
            isLoggedIn: true, // This will need to be dynamic at some point
            contactTooltipVisible: false,
            memberTooltipVisible: false,
            cartAmount: "4" // Will need to be set up as well, needs to be string unfortunately
        }
    },
    computed: {
        combineLinksAndCtas() {
            return [
                ...this.primaryCta || [],
                ...this.secondaryCta || [],
                ...this.primaryLinks || [],
                ...this.secondaryLinks || [],
            ];
        },
    },
    methods: {
        contactTooltipToggle() {
            this.contactTooltipVisible = !this.contactTooltipVisible;
            if (this.memberTooltipVisible) this.memberTooltipVisible = false;
        },
        memberTooltipToggle() {
            this.memberTooltipVisible = !this.memberTooltipVisible;
            if (this.contactTooltipVisible) this.contactTooltipVisible = false;
        },
        logout() {
            axios.post('/logout')
                .then((res) => location.href = '/login')
                .catch(err => console.error(err))
        },
    },
    mounted() {
        // Determine if user is logged in or not, if not the request will fail, and we will just mark them as not logged in
        axios.get('/dashboard/user')
            .then((res) => this.isLoggedIn = true)
            .catch(() => this.isLoggedIn = false)
    }
}
</script>

<style lang="scss">
    @import '../../../sass/_vue-import.scss';

    body[data-header-template="TemplateLocke"] {
        --container-width: 1600px;
        @include fluid-size(4px, 8px, --top-arrow-size, $min-vw: $lg-screen, $max-vw: 1400px);

        section.page-title {
            grid-area: header-start / header-start / pagetitle-end / pagetitle-end;
            padding: 0;
            margin: 0;
            max-width: 100%;

            > .page-title {
                padding: calc(var(--hd-height) + 2rem) 0 2rem;
                background-color: var(--themeComplement-hsl);

                .pt-container {
                    max-width: 1140px;
                }

                .pt-title {
                    color: var(--themeColor-hsl);
                }

                .pt-breadcrumbs {
                    &, & * {
                        color: var(--themeColor-hsl);
                    }
                }

                .pt-bg .overlay {
                    background-color: var(--themeComplement-hsl);
                    opacity: .8;
                }
            }
        }

        #vue-header {
            position: sticky;
            top: 0;
        }
    }
</style>

<style lang="scss" scoped>
    @import '../../../sass/vue-import';

    $light-tan: hsl(calc(var(--themeComplement-h) - 4deg), calc(var(--themeComplement-s) + 61.57%), calc(var(--themeComplement-l) + 16.08%));
    $dark-tan: hsl(calc(var(--themeComplement-h) + 1deg), calc(var(--themeComplement-s) + 3.52%), calc(var(--themeComplement-l) - 28.24%));
    $border-radius: 20px;

    .header-template-locke {
        @include fluid-size(80px, 175px, --logo-width);
        position: relative;

        .dynamic-container {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
            display: grid;
            grid-template:
                "l t" max-content
                "l b" max-content
                "l ." max-content
                / var(--logo-width) 1fr;
        }

        .logo-container { grid-area: l; }
        .top-bar { grid-area: t; }
        .bottom-bar { grid-area: b; }

        .logo-container {
            position: relative;
            background-color: white;
            border-radius: 0 0 $border-radius $border-radius;
            @include fluid-size(8px, 16px, padding);
            height: var(--logo-width);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            img {
                max-width: 100%;
                max-height: 100%;
                vertical-align: bottom;
            }
        }

        .top-bar {
            padding: .25rem 0;
            font-size: 14px;
            position: relative;
            grid-area: t;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-transform: uppercase;
            text-decoration: none;
            color: var(--themeComplementCompliant-hsl) !important;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
                background-color: var(--themeComplement-hsl);
                z-index: -1;
            }

            .locke-blog-link {
                text-transform: none;
                color: var(--themeColor-hsl);
                border-width: 1px;
                border-color: var(--themeColor-hsl);
                padding: .25rem 1rem;
                background-color: transparent;
                @include desktop { display: none; }
            }

            .links {
                flex-shrink: 0;
                margin: 0 -1rem;
                display: flex;
                align-items: center;

                .ctas { padding: 0 1rem; }
            }

            .tooltip-tab {
                @include fluid-size(8px, 16px, margin-left);
                height: 100%;
                display: flex;
                align-items: center;
                position: relative;
                flex-shrink: 0;
                --top-arrow-size: min(8px, 1vw);

                .tooltip-btn {
                    &:before {
                        background-color: $light-tan;
                        clip-path: polygon(0% calc(100% + 1px), 50% calc(100% + 1px), 100% calc(100% + 1px));
                        content: "";
                        position: absolute;
                        bottom: -1px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: calc(var(--top-arrow-size) * 2);
                        width: calc(var(--top-arrow-size) * 10);
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                        transition: 200ms ease-out;
                    }
                }

                .tooltip {
                    text-transform: none;
                    position: absolute;
                    width: max-content;
                    right: 0;
                    top: 100%;
                    padding: .75rem 1rem;
                    background-color: $light-tan;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: 200ms ease-out;
                    z-index: 3;

                    p {
                        font-size: 14px;
                        color: var(--themeComplementCompliant-hsl);
                        margin: .25rem 0;
                    }

                    .tooltip-heading {
                        font-size: 20px;
                        color: $dark-tan;
                        margin-top: 0;
                    }
                }

                &.active {
                    .tooltip-btn:before {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: all;
                        clip-path: polygon(0% calc(100% + 1px), 50% 0%, 100% calc(100% + 1px));
                        bottom: calc(-1rem - 1px);
                    }

                    .tooltip {
                        top: calc(100% + 1rem + 1px);
                        opacity: 1;
                        visibility: visible;
                        pointer-events: all;
                    }
                }
            }

        }

        .bottom-bar {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
                background-color: var(--themeColor-hsl);
                opacity: .8;
                z-index: -1;
            }
        }

        .top-bar, .bottom-bar {
            &:before {
                width: 100%;
                width: calc(100vw - var(--scrollbar-width, 0px));
                right: -15px;
                right: calc(((100vw - var(--scrollbar-width, 0px)) - min((100vw - var(--scrollbar-width, 0px)), var(--container-width, 1140px)) + var(--grid-gutter, 30px)) / -2);
            }
        }

        .mobile-controls {
            @include desktop { display: none; }

            @include not-desktop {
                display: flex;
                align-items: center;
            }

            .btn-outline-white {
                color: white;
                border-width: 1px;
                border-color: white;
                padding: .25rem 1rem;
                background-color: transparent;
            }

            .open-mobile-menu {
                color: var(--themeCompliant-hsl);
                font-size: 24px;
                margin: 0 0 0 1rem;
            }
        }

        .primary-links {
            display: flex;
            padding: 0 .5rem;

            a { padding: .25rem .5rem; }
        }

        .cart-button {
            @include fluid-size(8px, 16px, margin-left);
        }
    }
</style>
