import { render, staticRenderFns } from "./SiteObituaryList.vue?vue&type=template&id=07dd4a09&scoped=true&"
import script from "./SiteObituaryList.vue?vue&type=script&lang=js&"
export * from "./SiteObituaryList.vue?vue&type=script&lang=js&"
import style0 from "./SiteObituaryList.vue?vue&type=style&index=0&id=07dd4a09&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07dd4a09",
  null
  
)

export default component.exports