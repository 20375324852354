<template>
    <FuneralBioDynamicSection
        name="Service"
        property="service_info"
        :default-obj="defaultObj"
        :section="section"
        sub-title="How will family and friends gather and celebrate a life?"
        v-slot="slotProps"
    >
        <div class="input-grid">
            <VueSelect
              label="Service Type"
              name="service_type"
              v-model="slotProps.item.service_type"
              :options="serviceTypeOptions"
            />
            <VueInput label="Name" name="name" v-model="slotProps.item.name" />
            <VueInput label="Address" name="address" v-model="slotProps.item.address" />
            <VueInput label="City" name="city" v-model="slotProps.item.city" />
            <VueUsStates label="State" name="state" v-model="slotProps.item.state" />
            <VueZipCode label="Zip" name="zip" v-model="slotProps.item.zip" />
            <VueInput label="Phone" name="phone" v-model="slotProps.item.phone" type="tel"/>
            <VueInput label="Email" name="email" v-model="slotProps.item.email" type="email"/>
        </div>

        <VueTextarea
          label="Special Instructions"
          name="service_description"
          v-model="slotProps.item.special_instructions"
          class="mt-2 mb-0 mx-0"
        />

        <div class="input-grid mt-1" v-if="slotProps.item.service_type == 'cemetery'">
            <VueInput
              label="Cemetery Section"
              name="section"
              v-model="slotProps.item.cemetery_section"
            />
            <VueInput label="Cemetery Lot" name="lot" v-model="slotProps.item.cemetery_lot" />
            <VueInput label="Cemetery Space" name="space" v-model="slotProps.item.cemetery_space" />
            <VueCheckbox
              label="Is marker installed?"
              name="deceased"
              v-model="slotProps.item.cemetery_marker_installed"
            />
        </div>

        <FuneralBioDynamicSection
            name="Personnel"
            :property="`service_info.${slotProps.index}.service_personnel`"
            :default-obj="personnelDefaultObj"
            section="Personnel"
            v-slot="personnelSlotProps"
            class="m-0"
        >
            <div class="input-grid">
                <VueSelect
                  label="Person to Contact"
                  name="person_to_contact"
                  v-model="personnelSlotProps.item.service_person_type"
                  :options="servicePersonTypeOptions"
                />
                <VueInput
                  label="Name"
                  name="service_person_contact_name"
                  v-model="personnelSlotProps.item.service_person_contact_name"
                />
            </div>

            <VueTextarea
              label="Special Instructions"
              name="service_person_description"
              v-model="personnelSlotProps.item.service_person_description"
              class="mt-2 mb-0 mx-0"
            />
        </FuneralBioDynamicSection>
    </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentService',
  props: ['section'],
  data() {
    return {
      defaultObj: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        cemetery_section: '',
        cemetery_lot: '',
        cemetery_space: '',
        cemetery_marker_installed: '',
        special_instructions: '',
        service_personnel: [],
      },
      personnelDefaultObj: {
        service_person_type: '',
        service_person_contact_name: '',
        special_instructions: '',
      },
      serviceTypeOptions: [
        { name: 'Funeral/Celebration of Life Service', value: 'funeral_celebration_of_life_service' },
        { name: 'Visitation/Gathering', value: 'visitation_gathering' },
        { name: 'Vigil/Prayer/Fraternal Service', value: 'vigil_prayer_fraternal_service' },
        { name: 'Cemetery', value: 'cemetery' },
        { name: 'Military Rites', value: 'military_rites' },
        { name: 'Memorial Donations to?', value: 'memorial_donations_to' },
        { name: 'Other', value: 'other' },
      ],
      servicePersonTypeOptions: [
        { name: 'Clergy', value: 'clergy' },
        { name: 'Organist', value: 'organist' },
        { name: 'Special Music', value: 'special_music' },
        { name: 'Vocalist', value: 'vocalist' },
        { name: 'Bible Passage', value: 'bible_passage' },
        { name: 'Casket bearer', value: 'casket_bearer' },
        { name: 'Monument Dealer', value: 'monument_dealer' },
        { name: 'Participating Organization', value: 'participating_organization' },
        { name: 'Other', value: 'other' },
      ],
    };
  },
};
</script>
