<template>
  <div class="dashboard-calendar">
    <VueCal
      :events="allItems"
      events-on-month-view="short"
      :disable-views="['years', 'year']"
      :active-view="activeView"
      twelve-hour
      :time-from="5 * 60"
      :time-to="20 * 60"
      :on-event-click="onEventClick"
    >
      <template v-slot:event="{event}">
        <div
          class="wrapper"
          :title="`${event.title} - ${event.start.formatTime('h:mm{am}')} to ${event.end.formatTime('h:mm{am}')}`"
        >
          <div
            class="vuecal__event-title"
            v-html="event.title"
          />
          <small class="vuecal__event-time">
            <span v-if="event.allDay">All Day</span>
            <span v-else-if="!event.end">Starting at {{ event.start.formatTime("h:mm{am}") }}</span>
            <span v-else-if="!event.start">Ends at {{ event.end.formatTime("h:mm{am}") }}</span>
            <template v-else>
              <span>{{ event.start.formatTime("h:mm{am}") }}</span><br />
              <span>to {{ event.end.formatTime("h:mm{am}") }}</span>
            </template>
          </small>
        </div>
      </template>
    </VueCal>
    <VueModal
      :visible="showDialog"
      @on-close="showDialog = false"
    >
      <template #header>
        <div class="d-flex justify-content-between">
          <h3 class="text-primary">
            <FontAwesomeIcon
              class="mr-2"
              :icon="iconCalendar"
            />{{ selectedEvent.title }}
          </h3>
          <RouterLink
            v-if="selectedEvent.funeral_id && funerals[selectedEvent.funeral_id].role_name === 'organizer' && selectedEvent.is_user_created_event"
            :to="{ name: 'EventIndex', params: { id: selectedEvent.funeral_id, eventId: selectedEvent.id } }"
            class="btn btn-primary"
            title="Internal link to the edit event page in the same window"
          >Edit Event</RouterLink>
        </div>
      </template>

      <div class="statuses">
        <div class="status">
          <p class="key">For Funeral</p>
          <p class="value">{{ selectedEvent.funeral_name }}</p>
        </div>
        <div class="status">
          <p class="key">On</p>
          <p class="value">{{ formatDay(selectedEvent.start) }}</p>
        </div>
        <div class="status" v-if="selectedEvent.allDay">
          <p class="key">All Day</p>
        </div>
        <div class="status" v-else-if="!selectedEvent.end">
          <p class="key">Starts at</p>
          <p class="value">{{ formatTime(selectedEvent.start) }}</p>
        </div>
        <div class="status" v-else-if="!selectedEvent.start">
          <p class="key">Ends at</p>
          <p class="value">{{ formatTime(selectedEvent.end) }}</p>
        </div>
        <template v-else>
          <div class="status">
            <p class="key">From</p>
            <p class="value">{{ formatTime(selectedEvent.start) }}</p>
          </div>
          <div class="status">
            <p class="key">To</p>
            <p class="value">{{ formatTime(selectedEvent.end) }}</p>
          </div>
        </template>
      </div>

      <div
        class="description"
        v-if="selectedEvent.description"
      >
        <p class="m-0">{{ selectedEvent.description }}</p>
      </div>

      <div
        class="location-card"
        v-if="selectedEvent.location_name || selectedEvent.location_address"
      >
        <div class="location-header">
          <p class="location-name">
            <FontAwesomeIcon :icon="iconAt" />{{ selectedEvent.location_name || "" }}
          </p>
          <a
            title="External link in new tab opening directions to the given directions"
            target="_blank"
            class="btn btn-white directions-link"
            v-if="formatDirections(selectedEvent)"
            :href="formatDirections(selectedEvent)"
          >
            <FontAwesomeIcon :icon="iconMapMarkerAlt" />{{ addressCase(selectedEvent) === 1 ? 'Search for Location' : 'Get Directions'}}
          </a>
        </div>
        <p
          class="location-address"
          v-if="selectedEvent.location_address"
        >{{ selectedEvent.location_address.address1 }}<br />{{ selectedEvent.location_address.city }}, {{ selectedEvent.location_address.state }} {{ selectedEvent.location_address.zip }}</p>
      </div>
    </VueModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faAt,
  faMapMarkerAlt,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'PortalCalendar',
  props: {
    colors: Array,
    schedules: Object,
    activeView: { type: String, default: 'month' },
  },
  components: {
    VueCal,
    FontAwesomeIcon,
  },
  data() {
    return {
      selectedEvent: {},
      showDialog: false,
      unusedColors: this.colors || [],
      usedColors: [],
      iconAt: faAt,
      iconMapMarkerAlt: faMapMarkerAlt,
      iconCalendar: faCalendar,
    };
  },
  computed: {
    ...mapState([
      'funerals',
    ]),
    allItems() {
      return Object.entries(this.schedules)
        .filter(([k, f]) => !this.funerals[f.funeral_id].is_hidden)
        .map(([k, f]) => {
          let color = 'blue';
          if (!this.unusedColors.length && !this.usedColors.length) {
            color = 'blue';
          } else if (!this.unusedColors.length) {
            this.unusedColors = this.usedColors;
            this.usedColors = [];
          } else {
            color = this.getRandom(this.unusedColors);
            this.usedColors.push(color[0]);
          }
          return f.items.map((s) => ({ funeral_id: f.funeral_id, class: color, ...s }));
        })
        .flat(1);
    },
  },
  methods: {
    onEventClick(event, e) {
      this.selectedEvent = event;
      this.showDialog = true;
      e.stopPropagation();
    },
    getRandom(array) {
      return array.splice(
        Math.floor(Math.random() * this.schedules.length),
        1,
      );
    },
    formatDay(date) {
      return dayjs(date).format('dddd, MMMM D, YYYY');
    },
    formatTime(date) {
      return dayjs(date).format('h:mma');
    },
    addressCase(event) {
      return !!event.location_name + 2 * !!event.location_address;
    },
    filterAndJoin(array, join) {
      return array.filter((i) => i).join(join);
    },
    formatDirections(event) {
      const caseId = this.addressCase(event);
      const n = event.location_name;
      const a = event.location_address;

      switch (caseId) {
        case 0:
          return false;
        case 1:
          return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            n,
          )}`;
        default:
          const existingFields = this.filterAndJoin(
            [
              n,
              a.address1,
              a.address2,
              a.city,
              this.filterAndJoin([a.state, a.zip], ' '),
            ],
            ', ',
          );
          return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
            existingFields,
          )}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-calendar {
  &::v-deep {
    .vuecal__event {
      cursor: pointer;
      line-height: 1.1;
      font-size: 11px;
      font-weight: bold;
      text-align: left;

      &.blue {
        color: white;
        background-color: #0b2a6d;
      }
      &.teal {
        color: white;
        background-color: #007991;
      }
      &.moss {
        color: black;
        background-color: #bcd8c1;
      }
      &.yellow {
        color: black;
        background-color: #e9d985;
      }
      &.emerald {
        color: white;
        background-color: #439a86;
      }
      &.lavendar {
        color: black;
        background-color: #b5838d;
      }

      &-title {
        overflow-wrap: break-word;
        hyphens: auto;
      }

      > .wrapper {
        padding: 0.5em;
      }
    }

    .vuecal:not(.vuecal--month-view) .vuecal__event > .wrapper {
      height: 100%;
    }

    .vuecal__weekdays-headings {
      padding-right: 0;
    }

    .vuecal__title-bar {
      background-color: var(--primary);
      color: white;

      button {
        color: inherit;
      }
    }

    .vuecal__cell-content {
      height: auto;
    }

    .vuecal__cell--today,
    .vuecal__cell--current {
      background-color: hsla(0deg, 0%, 0%, 0.02);
    }

    .vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
      background-color: rgba(235, 255, 245, 0.4);
    }

    .vuecal__cell--selected:before {
      border-color: transparent;
      background-color: hsla(221deg, 82%, 24%, 0.05);
    }

    .vuecal--short-events .vuecal__event-time {
      padding: 0 3px;
      display: block;
    }

    .vm-body {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 1rem;
    }
  }

  .statuses {
    display: flex;
    margin: -0.25rem -0.5rem;
    flex-wrap: wrap;

    .status {
      display: flex;
      align-items: center;
      margin: 0.25rem;
      font-size: 12px;

      .key,
      .value {
        margin: 0;
      }

      .key {
        border-radius: 5px;
        background-color: hsl(0deg, 0%, 95%);
        padding: 2px 8px 0;
        display: flex;
        align-items: center;
        font-weight: bold;
      }

      .value {
        padding: 0 0.5rem;
      }
    }
  }

  .location-card {
    box-shadow: 0 3px 8px -3px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    overflow: hidden;

    .location-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0.5rem;
      background-color: var(--primary);

      .location-name {
        display: flex;
        font-size: large;
        margin: 0;
        align-items: center;
        gap: 0.5rem;
        color: white;
      }

      .directions-link {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .location-address {
      padding: 1rem;
      margin: 0;
    }
  }
}
</style>
