<template>
  <div class="app-main">
    <SitePortalHeader :company-name="companyName" />

    <div class="content-wrapper">
      <div class="content">
        <PortalSidebarNav />

        <div class="main-content-wrapper">
          <h3 class="main-content-header" v-if="funeral">{{ funeral.name }}</h3>
          <div
            class="main-content"
            :class="{'is-loading': isLoading, 'in-chat': inChat, 'on-dashboard': onDashboard}"
          >
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AppIndex',
  computed: {
    ...mapState([
      'isLoading',
      'companyName',
      'funerals',
      'userData',
    ]),
    inFuneral() {
      return this.$route.matched.some(({ name }) => name === 'FuneralIndex');
    },
    inChat() {
      return ['CommunicationsHub', 'FuneralCommunicationsHub'].includes(this.$route.name);
    },
    onDashboard() {
      return this.$route.name === 'DashboardIndex';
    },
    funeral() {
      return this.inFuneral ? this.funerals[this.$route.params.id] : false;
    },
  },
  methods: {
    ...mapActions([
      'fetchData',
    ]),
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../sass/_vue-import.scss";

.app-main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-wrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;

  .content {
    flex-grow: 1;
    width: 100%;
    display: flex;

    .main-content-wrapper {
      flex: 1 1 auto;
      overflow: auto;
      display: flex;
      flex-direction: column;
      min-width: min(calc(100% - 1rem), 300px);
    }

    .main-content-header {
      padding: 1rem 2rem;
      border-bottom: 1px solid hsl(0deg, 0%, 95%);
    }

    .main-content {
      flex-grow: 1;
      padding: 2rem;
      opacity: 1;
      @extend %minimal-scrollbar;
      transition: 50ms ease-out;
      display: flex;

        &.is-loading {
          padding-top: 2.25rem;
          padding-bottom: 1.75rem;
          opacity: 0;
        }

        &.in-chat,
        &.on-dashboard {
          overflow: hidden;
        }
      }
    }
  }
</style>
