<template>
	<div class="vp-tab-nav">
		<router-link
			:to="{ name: 'CartList' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the cart page"
			exact
		>Carts</router-link>
	</div>
</template>

<script>
export default {
	name: "CartSecondaryNav",
};
</script>
