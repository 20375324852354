<template>
    <div class="h-100 flex-grow-1">
        <RegisterForm :captcha="captcha"/>
    </div>
</template>

<script>
import RegisterForm from './components/RegisterForm.vue';

export default {
  props: ['captcha'],
  components: {
    RegisterForm,
  },
};
</script>
