<template>
	<VueModal
		:visible="isModalOpen"
		@on-close="closeFuneralUserAddModifyModal"
		id="funeral-add-modify-modal"
	>
		<template #header>
			<h4 class="modal-title">
				<span class="fas fa-user-circle mr-1 text-tertiary"></span>&nbsp;{{
                    getModalHeader
                }}
			</h4>
		</template>
		<!-- Form Include -->
        <div class="form-errors"></div>
		<AddModifyFuneralUserForm
			:class="{ 'd-none': isFormProcessing }"
			@closeModal="closeFuneralUserAddModifyModal"
		/>

		<VpanelLoader v-if="isFormProcessing" />

		<template #footer>
			<div class="d-flex justify-content-end">
				<button
					type="button"
					class="btn btn-outline-primary cancel-button border"
					@click.prevent="closeFuneralUserAddModifyModal"
				>
					Cancel
				</button>
				<button
					type="submit"
					class="btn btn-primary ml-1"
					:disabled="isFormProcessing"
					form="add-modify-funeral-user-form"
					@click.prevent="submitForm(funeralId)"
				>
					{{ isFormProcessing ? "Processing..." : "Apply" }}
				</button>
			</div>
		</template>
	</VueModal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
	name: "AddModifyFuneralUserModal",
	computed: {
		...mapState("user/addModifyUser", {
			isModalOpen: (state) => state.isModalOpen,
			activeRecord: (state) => state.activeRecord,
			isActionAdd: (state) => state.isActionAdd,
			isFormProcessing: (state) => state.isFormProcessing,
		}),
		getModalHeader() {
			let action = (this.isActionAdd) ? "Invite a New " : "Modify ";
			return action + "Funeral User";
		},
        funeralId(){

            return this.$route.params.id;
        }
	},
	methods: {
		...mapActions("user/addModifyUser", [
			"openModal",
			"closeModal",
			"resetFuneralUser",
			"submitForm",
			"setFormIsAdd",
		]),

		closeFuneralUserAddModifyModal() {
			this.setFormIsAdd(true);
			this.resetFuneralUser();
			this.closeModal();
		},
	},
};
</script>
