<template>
  <div class="d-flex flex-md-row flex-column flex-grow-1">
    <div class="flex-grow-1 d-flex align-items-center justify-content-center">
      <div class="login">
        <p class="title">Login</p>
        <form
          id="login-form"
          name="login-form"
          @submit.prevent="submitLogin"
          ref="formEl"
        >
          <span class="text-muted text-14 mb-1 d-block">All fields marked with * are required</span>
          <div
            v-if="errorMessage"
            class="alert alert-danger form-errors"
            v-html="errorMessage"
          ></div>
          <div
            v-if="successMessage"
            class="alert alert-success"
            v-html="successMessage"
          ></div>

          <input
            type="hidden"
            name="_token"
            :value="activeRecord._token"
          />

          <div
            class="wrap-gap mb-1"
            style="--gap:1rem;"
          >
            <VueInput
              class="flex-grow-1"
              type="email"
              name="Email"
              :float-label="false"
              required
              v-model="activeRecord.email"
            />

            <VuePassword
              class="password flex-grow-1"
              name="Password"
              :float-label="false"
              required
              v-model="activeRecord.password"
            />
          </div>

          <div class="wrap-gap align-items-center justify-content-between">
            <a
              class="forgotpw"
              @click="openForgotModal"
              style="flex-grow:9999"
              title="Internal link to the forgot password page"
            >Forgot Password</a>
            <button
              class="btn btn-primary"
              style="flex-grow:1"
            >Login</button>
          </div>
        </form>
      </div>
    </div>
    <div class="flex-grow-1 login-img">
      <img
        src="/images/custom/site/Collaboration-Image_square.jpg"
        alt="Decorative image of two people collaborating"
      />
      <div class="reg-text text-center" v-if="shouldRedirect">
        <p class="reg-title">Don't have an account?</p>
        <p class="sub-text">
            Click the button below and fill out our quick registration&nbsp;form.
        </p>
        <a
          href="/register"
          title="internal link in new tab to register page"
          class="btn btn-primary"
        >Register</a>
      </div>
    </div>
  </div>
</template>
<script>
import VueInput from '@v/Core/Global/Components/VueForms/VueInput.vue';
import VuePassword from '@v/Core/Global/Components/VueForms/VuePassword.vue';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'LoginForm',
  components: {
    VueInput,
    VuePassword,
  },
  computed: {
    ...mapState('login', {
      activeRecord: (state) => state.activeRecord,
      isFormProcessing: (state) => state.isFormProcessing,
      errorMessage: (state) => state.errorMessage,
      successMessage: (state) => state.successMessage,
      shouldRedirect: (state) => state.shouldRedirect,
    }),
  },
  methods: {
    ...mapActions('login', ['submitForm', 'closeModal', 'openModal']),
    ...mapMutations('login', ['SET']),
    openForgotModal() {
      location.href = '/forgot-password';
    },
    submitLogin() {
      const formId = '#login-form';
      const path = '/login';
      this.submitForm(formId, path);
    },
  },
  created() {
    const url = new URL(window.location.href);
    const path = url.pathname;
    if (path === '/login') {
      this.SET(['shouldRedirect', true]);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

.login {
  @include fluid-size(40px, 80px, padding);
  height: auto;

  .forgotpw {
    text-decoration: underline !important;
    font-size: 12px;
    cursor: pointer;
  }

  // This needs to be fixed in the base at some point but not now
  &::v-deep .password {
    position: relative;

    .password-preview {
      height: calc(1.5em + 0.75rem + 2px);
      bottom: 0;
      top: auto;
      transform: none;
      display: flex;
      align-items: center;
    }
  }

  select {
    padding: 3px;
  }

  form {
    margin: auto;
  }

  .title {
    margin: 0;
    color: var(--blue);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
  }
}

.login-img {
  position: relative;
  display: flex;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: hsla(0deg, 0%, 100%, 65%);
    z-index: -1;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .reg-text {
    @include fluid-size(40px, 80px, padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    width: 100%;

    .reg-title {
      margin: 0;
      color: var(--blue);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
    }

    .sub-text {
      margin: 0;
      font-weight: bold;
      font-size: 18px;
      padding: 5px;
    }
  }
}
</style>
