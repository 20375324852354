<template>
    <div class="list-content">
        <div class="list-item" v-for="id in locationIds" :key="id">
            <div class="location-image" v-if="locations[id].image_path">
                <img :src="locations[id].image_path" :alt="locations[id].image_alt">
            </div>
            <div class="location-overview">
                <div class="left">
                    <preferred-location-button :id="id" />
                    <p class="title">{{ locations[id].title }}</p>
                    <p class="m-0" v-if="locations[id].type">{{ locations[id].type }}</p>
                    <frontend-location-short-hours
                        v-for="(hours, index) in locations[id].hours"
                        :key="index + '_lv'"
                        :hours="hours"
                    />
                </div>

                <view-location-details-button :id="id" />
            </div>
            <div class="location-details">
                <frontend-location-address :location="locations[id]"/>
                <div class="contact">
                    <p class="heading">Contact Information</p>
                    <div class="group" >
                        <p><strong>Phone:</strong> <a :href="`tel:${locations[id].phone.replace(/\D/g,'')}`" >{{locations[id].phone}}</a></p>
                        <p v-if="locations[id].fax"><strong>Fax:</strong>{{locations[id].fax}}</p>
                        <p v-if="locations[id].toll_free"><strong>Toll Free:</strong>{{locations[id].toll_free}}</p>
                        <p v-if="locations[id].email"><strong>Email: </strong>{{ locations[id].email }}</p>
                    </div>
                </div>
                <div class="services" v-if="isServicesEnabled && services.length && locations[id].services.length">
                    <p class="heading">Services</p>
                    <frontend-location-services
                        :location="locations[id]"
                        :id="id"
                        :services="services"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: "FrontendLocationListView",
    props:[
        'services',
        'isServicesEnabled'
    ],
    computed: {
        ...mapState('location', [
            'locations'
        ]),
        ...mapGetters('location', [
            'locationIds'
        ])
    }
}
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../../sass/_vue-import.scss';

    // eventually move this to the base, maybe clean it up a bit
    @mixin border-widths($t, $r: null, $b: null, $l: null) {
        // conditionally handle shorthand
        @if not $r {$r:$t;$b:$t;$l:$t;}
        @else if not $b {$b:$t;$l:$r;}
        @else if not $l {$l:$r;}

        border-style: solid;
        border-color: var(--light);
        border-width: $t $r $b $l;
    }

    .list-content {
        padding: 0 .75rem;
        min-height: 400px;

        @include desktop { @include border-widths(0, 1px, 1px); }
        @include not-desktop { @include border-widths(0, 0, 1px); }

        .list-item {
            @include border-widths(0, 0, 1px);
            display: grid;
            width: 100%;
            font-size: 14px;
            padding: 1rem 0;

            @include desktop {
                grid-template:
                    "img overview" max-content
                    "img details" max-content
                    / min-content 1fr;

                .location-image { padding-right: .75rem; }
            }

            @include not-desktop {
                grid-template:
                    "img" max-content
                    "overview" max-content
                    "details" max-content
                    / 100%;
            }

            .location-image {
                grid-area: img;

                @include desktop {
                    padding-right: .75rem;

                    img {
                        width: clamp(287px,30.6vw,357px); // magic numbers here to align with the search bar
                    }
                }

                @include not-desktop {
                    padding-bottom: 1rem;

                    img {
                        width: 100%;
                        height: math.div(900, 16) * 1vw;
                        object-fit: cover;
                    }
                }
            }

            .location-overview {
                grid-area: overview;

                @include desktop {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                .btn { margin: 1rem 0; }
            }

            .location-details {
                grid-area: details;
                margin: 0 -.375rem;
                display: flex;
                flex-wrap: wrap;

                .address { flex: 1 0 50%; }
                .contact { flex: 1 0 clamp(175px, 25%, 25%); }
                .services { flex: 1 0 clamp(125px, 25%, 25%); }

                > div {
                    padding: 0 .375rem;
                }

                .heading {
                    margin-top: 1rem;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: .25rem;
                    border-bottom: 1px solid var(--light);

                    + .group {
                        padding: .5rem 0;

                        p { margin: 0; }

                        > *:first-child { margin-top: 0; }
                        > *:last-child { margin-bottom: 0; }
                    }
                }
            }

            .title {
                @include fluid-size(22px, 30px, font-size);
                font-weight: bold;
                line-height: 1.1;
                margin-bottom: .5rem;
            }

            .hour-overview {
                margin: 0;
            }
        }
    }
</style>