<template>
    <button type="button" class="btn btn-primary m-0" @click="newPost">
        New Obituary
    </button>
</template>
<script>
import { mapActions } from "vuex";

export default {
    name: "AddNewObituaryButton",
    methods: {
        newPost() {
            this.$router.push({ name: "ObituaryFormNew" });
        }
    }
};
</script>
