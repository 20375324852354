<template>
    <div class="details-content">
        <div class="location-overview">
            <div class="buttons">
                <preferred-location-button :id="id" />

                <button
                    class="go-back"
                    @click="setDetails(null)"
                >
                    <span class="fas fa-chevron-left"></span>Back to Locations
                </button>
            </div>
            <p class="title">{{ location.title }}</p>
            <p class="m-0" v-if="location.type">{{ location.type }}</p>
            <frontend-location-short-hours
                v-for="(hours, index) in location.hours"
                :key="index + '_dv'"
                :hours="hours"
            />
        </div>
        <div class="location-details" :class="{'no-image': !location.image_path, 'no-description': !location.description}">
            <div class="location-contact-info">
                <frontend-location-address :location="location"/>

                <div class="contact">
                    <p class="heading">Contact Information</p>
                    <div class="group">
                        <p><strong>Phone:</strong> <a :href="`tel:${location.phone.replace(/\D/g,'')}`">{{location.phone}}</a></p>
                        <p v-if="location.fax"><strong>Fax:</strong>{{location.fax}}</p>
                        <p v-if="location.toll_free"><strong>Toll Free:</strong>{{location.toll_free}}</p>
                        <p v-if="location.email"><strong>Email: </strong>{{ location.email }}</p>
                    </div>
                </div>

                <div class="hours">
                    <p class="heading">Hours</p>
                    <frontend-location-full-hours
                        v-for="(hours, index) in location.hours"
                        :key="index"
                        :hours="hours"
                    />
                </div>

                <div class="services" v-if="isServicesEnabled && Object.keys(services).length && location.services.length">
                    <p class="heading">Services</p>
                    <frontend-location-services
                        :location="location"
                        :id="id"
                        :services="services"
                    />
                </div>
            </div>
            <div class="location-image" v-if="location.image_path">
                <img :src="location.image_path" :alt="location.image_alt">
            </div>
            <div class="location-description" v-html="location.description"></div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: ["location", "id", "services"],
    computed: {
        ...mapState('location/settings', [
            'isServicesEnabled'
        ])
    },
    methods: {
        ...mapActions('location', [
            'setDetails'
        ])
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    // eventually move this to the base, maybe clean it up a bit
    @mixin border-widths($t, $r: null, $b: null, $l: null) {
        // conditionally handle shorthand
        @if not $r {$r:$t;$b:$t;$l:$t;}
        @else if not $b {$b:$t;$l:$r;}
        @else if not $l {$l:$r;}

        border-style: solid;
        border-color: var(--light);
        border-width: $t $r $b $l;
    }

    .go-back {
        border: 0;
        border-radius: 3px;
        background-color: var(--lighter);
        color: var(--dark);
        font-size: 14px;
        padding: .25rem .5rem;
        line-height: 1;
        margin-bottom: 1rem;

        > span {
            display: flex;
            align-items: center;
        }

        svg {
            margin-right: .5em;
            font-size: 12px;
        }
    }

    .details-content {
        font-size: 14px;
        padding: 1rem .75rem;

        @include desktop { @include border-widths(1px); }
        @include not-desktop { @include border-widths(1px, 0); }

        .location-overview {
            padding-bottom: .5rem;
            margin-bottom: 1rem;
            @include border-widths(0, 0, 1px);

            .buttons {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;

                @include mobile {
                    button {
                        flex-grow: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .title {
                @include fluid-size(22px, 30px, font-size);
                font-weight: bold;
                line-height: 1.1;
                margin-bottom: .5rem;
            }

            .hour-overview { margin: 0; }
        }

        .location-details {
            width: 100%;

            // image || description
            &:not(.no-image), &:not(.no-description) {
                display: grid;

                .location-contact-info { grid-area: a; }
                .location-image { grid-area: b; }
                .location-description { grid-area: c; }
            }

            // image && description
            &:not(.no-image.no-description) {
                @include desktop {
                    grid-template: "a b" max-content "a c" 1fr / minmax(300px, 1fr) 2fr;

                    .location-description { margin-left: .75rem; }
                }
                @include not-desktop {
                    grid-template: "b" max-content "c" max-content "a" max-content / 100%;

                    .location-description { margin-bottom: .75rem; }
                }
            }

            // !image && description
            &.no-image:not(.no-description) {
                grid-template: "c" max-content "a" max-content / 100%;

                .location-description { margin: 1rem 0 2rem; }
            }

            // image && !description
            &.no-description:not(.no-image) {
                .location-contact-info > div { flex: clamp(250px,50%,1000px); }
                @include desktop { grid-template: "a b" max-content / 1fr 1fr; }
                @include not-desktop { grid-template: "b" max-content "a" max-content / 100%;}
            }

            // !image || !description
            &.no-image, &.no-description {
                .location-contact-info {
                    display: flex;
                    margin: -.375rem;
                    flex-wrap: wrap;

                    > div {
                        flex: 1 0 clamp(250px,25%,1000px);
                        padding: .375rem;
                    }
                }
            }

            &.no-image .location-image { display: none; }
            &.no-description .location-description { display: none; }

            .location-contact-info {

                &::v-deep {
                    .heading { margin-top: 0; }
                }

                .heading {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: .25rem;
                    border-bottom: 1px solid var(--light);

                    + .group {
                        padding: .5rem 0 1.5rem;

                        p { margin: 0; }

                        > *:first-child { margin-top: 0; }
                        > *:last-child { margin-bottom: 0; }
                    }
                }

                .hours-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &.today { font-weight: bold; }
                }
            }

            .location-image {
                grid-area: b;
                margin-bottom: 1rem;

                @include desktop { margin-left: .75rem; }
                img { max-width: 100%; }
            }

            .location-description {
                grid-area: c;

                > *:first-child { margin-top: 0; }
                > *:last-child { margin-bottom: 0; }
            }
        }
    }
</style>