<template>
	<form id="add-modify-cart-form" class="cart-form needs-validation" name="add-modify-cart-form" novalidate @submit.prevent="submitForm">
		<div class="form-errors"></div>

		<input type="hidden" name="_token" :value="activeRecord._token" />
		<input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />

		<div class="d-flex flex-column justify-content-between align-items-stretch h-100">
			<div class="document-form-fields d-flex flex-column align-items-stretch">
				<h5>Basic Information</h5>

				<div class="d-flex justify-content-between align-items-center" >
					<table>
						<th>Name</th>
						<th>Price</th>
						<th>Adjust</th>
						<th>Qty</th>
						<th>Line Total</th>
						<tr
							v-for="(item, index) in activeRecord.cart_items"
							:key="index"
						>
							<td>
								<input
									type="text"
									class="form-control"
									disabled
									name="name"
									id="name"
									autocomplete="off"
									v-model="item.name"
								/>
							</td>
							<td>
								<input
									type="number"
									class="form-control"
									disabled
									name="name"
									id="product_price"
									autocomplete="off"
									v-model="item.product_price"
								/>
							</td>

							<td>
								<input
									type="number"
									class="form-control"
									name="product_price_adjustment"
									id="product_price_adjustment"
									title="Please enter Adjustment"
									autocomplete="off"
									data-invalid-feedback="Please enter product price adjustment"
									v-model="item.product_price_adjustment"
									@change="calculate(index)"
								/>

							</td>
							<td>
								<input
									type="number"
									class="form-control"
									required
									name="qty"
									id="qty"
									autocomplete="off"
									v-model="item.qty"
									@change="calculate(index)"
								/>
							</td>
							<td>
								<input
									type="number"
									class="form-control"
									required
									disabled
									name="line_total"
									id="line_total"
									autocomplete="off"
									v-model="item.line_total"
								/>
							</td>
						</tr>
						<tr>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td>
								<div class="d-flex justify-content-between align-items-center">Subtotal:</div>
							</td>
							<td>
								<div class="d-flex justify-content-between align-items-center"></div>
								<input
									required
									disabled
									type="number"
									id="subtotal"
									name="subtotal"
									autocomplete="off"
									class="form-control"
									v-model="activeRecord.subtotal"
								/>
							</td>
						</tr>
						<tr>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td>
								<div class="d-flex justify-content-between align-items-center">
									Tax Total:
								</div>
							</td>
							<td>
								<input
									disabled
									type="number"
									id="tax_total"
									name="tax_total"
									autocomplete="off"
									class="form-control"
									v-model="activeRecord.tax_total"
								/>
							</td>
						</tr>
						<tr>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td><div class="d-flex justify-content-between align-items-center"></div></td>
							<td>
								<div class="d-flex justify-content-between align-items-center">
									Total:
								</div>
							</td>
							<td>
								<input
									disabled
									id="total"
									name="total"
									type="number"
									class="form-control"
									v-model="activeRecord.total"
								/>
							</td>
						</tr>
					</table>
				</div>
				<br />
				<div class="form-group flex-grow-1">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<span class="required mr-3">Approval Status</span>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Please select a status for this cart."
						><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
					</div>
					<fieldset>
						<legend class="label sr-only">Status</legend>
						<div class="form-check form-check-inline form-check-radio">
							<label
								class="form-check-label"
								for="status-approved"
							>
								<input
									class="form-check-input"
									type="radio"
									title="status"
									required
									name="approval"
									id="status-approved"
									value="Approved"
									v-model="activeRecord.approval"
									data-invalid-feedback="Please select a status for this cart."
								/>
								<span class="form-check-sign"></span>
								Approved
							</label>
						</div>

						<div class="form-check form-check-inline form-check-radio">
							<label
								class="form-check-label"
								for="status-pending"
							>
								<input
									class="form-check-input"
									title="status"
									type="radio"
									name="approval"
									id="status-pending"
									value="Pending"
									v-model="activeRecord.approval"
									data-invalid-feedback="Please select a status for this cart."
								/>
								<span class="form-check-sign"></span>
								Pending
								<div class="invalid-feedback">
									Please select a status for this cart.
								</div>
							</label>
						</div>

						<div class="form-check form-check-inline form-check-radio">
							<label
								class="form-check-label"
								for="status-declined"
							>
								<input
									class="form-check-input"
									title="status"
									type="radio"
									name="approval"
									id="status-declined"
									value="Declined"
									v-model="activeRecord.status"
									data-invalid-feedback="Please select a status for this cart."
								/>
								<span class="form-check-sign"></span>
								Declined
								<div class="invalid-feedback">
									Please select a status for this cart.
								</div>
							</label>
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
	props:["visible"],
	data() {
		return {
			local_tax_total: '',
			state_tax_total: '',
		};
	},
	computed: {
		...mapState("cart/addModifyCart", {
			isModalOpen: (state) => state.isModalOpen,
			activeRecord: (state) => state.activeRecord,
			isActionAdd: (state) => state.formActionIsAdd,
			isFormProcessing: (state) => state.isFormProcessing,
		}),
	},
	watch: {
		visible: function (n,o) {
			this.timeToCalculate()
		}
	},
	mounted() {
		this.timeToCalculate();
	},
	methods: {
		timeToCalculate() {
			if (this.visible) {
				for (let i = 0; i < this.activeRecord.cart_items.length; i++) {
                    this.calculate(i);
                }
			}
		},
		calculate(index) {
			const price        = Number(this.activeRecord.cart_items[index].product_price);
			const adjust       = Number(this.activeRecord.cart_items[index].product_price_adjustment);
			let lineItemsTotal = 0;
			let qty            = Number(this.activeRecord.cart_items[index].qty);
			let lt             = (price + adjust) * qty;
			let LT             = lt.toFixed(2);
			let payload        = { value: LT, index: index, target: "line_total", variable: "cart_items" };

			this.SET_CART_ITEM_VALUE_IN_ACTIVE_RECORD(payload);

			let object = this.activeRecord.cart_items;

			for (const property in object) {
				let obj = object[property];
				for(const key in obj){
					if(key == "line_total"){
						lineItemsTotal += parseInt(obj[key]);
						let itemsTotal            = lineItemsTotal.toFixed(2);
						this.activeRecord.subtotal= itemsTotal;
					}
				}
            }

			let sTax             = Number(this.activeRecord.state_tax_percentage) * Number(this.activeRecord.subtotal);
			this.state_tax_total = sTax.toFixed(2);
			let lTax             = Number(this.activeRecord.local_tax_percentage) * Number(this.activeRecord.subtotal);
			this.local_tax_total = lTax.toFixed(2);
			let tTax             = (sTax+lTax);
			let taxTotal         = tTax.toFixed(2);
			let total            = Number(this.activeRecord.subtotal)+tTax;
			let finalTotal       = total.toFixed(2);
			let payload2         = { variable: "tax_total", value: taxTotal };
			let payload3         = { variable: "total", value: finalTotal };

			this.SET_VALUE_IN_ACTIVE_RECORD(payload2);
			this.SET_VALUE_IN_ACTIVE_RECORD(payload3);
		},
		...mapMutations("cart/addModifyCart", ["SET_CART_ITEM_VALUE_IN_ACTIVE_RECORD", "SET_VALUE_IN_ACTIVE_RECORD"]),
	},
};
</script>