<template>
	<button
		type="button"
		class="btn btn-primary m-0"
		@click.prevent="open()"
	>
		<slot></slot>
	</button>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
	props: ["id"],
	name: "AddUserToFuneralButton",
	methods: {
		...mapActions("user/addModifyUser", [
			"openModal",
			"resetFuneralUser",
			"setFormIsAdd",
		]),
		...mapMutations("user/addModifyUser", ["SET_VAR"]),
		...mapActions("user/list", ["selectUser"]),
		open() {
			if (this.id) {
				this.selectUser(this.id);
				this.setFormIsAdd(false);
			} else {
				this.setFormIsAdd(true);
				this.resetFuneralUser;
				let id = this.$route.params.id;
				let payload = { variable: "funeral_id", value: id };
				this.SET_VAR(payload);
			}
			this.openModal();
		},
	},
};
</script>
