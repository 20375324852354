<template >
	<div class="form-view">
		<add-modify-event-modal
			:location="location"
			:schedule_id="scheduleId"
		/>
		<!-- Header Area -->
		<div>
			<h5 class="modal-title"><span class="fas fa-edit mr-1 text-tertiary"></span>{{ getHeader }}</h5>
			<div
				class="vp-status-bar-list pb-2"
				v-if="!isActionAdd"
			>
				<p>Created Date: <span>{{ formatDate(activeRecord.created_at) }}</span></p>
				<p>Last Modified: <span>{{ formatDate(activeRecord.updated_at) }}</span></p>
				<p v-if="activeRecord.is_active">Status: <span>{{ activeRecord.is_active ? 'Active' : 'Inactive' }}</span></p>
				<p v-if="activeRecord.published_at">Published On: <span>{{ activeRecord.published_at }}</span></p>
				<p v-else>Status: <span>Not Published</span></p>
			</div>
		</div>

		<!-- Body Area -->
		<form
			id="add-modify-obituary-form"
			class="obituart-form needs-validation"
			name="add-modify-obituary-form"
			novalidate
			@submit.prevent="saveSubmitForm()"
		>
			<div class="form-errors"></div>
			<input
				type="hidden"
				name="_token"
				:value="activeRecord._token"
			/>
			<input
				type="hidden"
				name="id"
				value="activeRecord.id"
				v-if="!isActionAdd"
			/>

			<div class="row">
				<!-- Left Side -->
				<div class="col-md-8">
					<h5>Basic Information</h5>

					<!-- Name -->
					<div class="form-group has-label">
						<div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
							<label for="title">First Name <span class="required">*</span></label>
						</div>
						<input
							type="text"
							class="form-control"
							name="firstname"
							id="firstname"
							v-model="activeRecord.funeral_bio.first_name"
							title="Enter a first name"
							required
						/>
						<p class="invalid-feedback">Please enter a first name.</p>
					</div>
					<div class="form-group has-label">
						<div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
							<label for="title">Middle Name </label>
						</div>
						<input
							type="text"
							class="form-control"
							name="name"
							id="name"
							v-model="activeRecord.funeral_bio.middle_name"
							title="Enter a Middle Title"
						/>
						<p class="invalid-feedback">Please enter a middle name.</p>
					</div>
					<div class="form-group has-label">
						<div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
							<label for="title">Last Name <span class="required">*</span></label>
						</div>
						<input
							type="text"
							class="form-control"
							name="firstname"
							id="firstname"
							v-model="activeRecord.funeral_bio.last_name"
							title="Enter a last name"
							required
						/>
						<p class="invalid-feedback">Please enter a last Name.</p>
					</div>
					<div class="form-check has-label">
						<div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
							<!-- <p for="title">Last Name <span class="required">*</span></p> -->
						</div>

					</div>
					<!-- branches may need in the future

                    <div class="form-group flex-column">
                        <select
                            id="branch"
                            name="branch"
                            v-model="activeRecord.branch"
                            class="form-control"
                            title="Please select branch."
                        >
                            <option
                                v-for="(branch, index) in branches"
                                :key="index"
                                :value="branch.uuid"
                            >{{ branch.name }}</option>
                        </select>
                        <label for="branch">Branch <span class="required">*</span></label>
                        <p class="invalid-feedback">Please select a branch.</p>
                    </div>-->
                    <!-- Content -->

                    <div>
                    </div>
                        <h5>Content</h5>
                           <div class="row flex-wrap m-2" >
                            <div class="col-md-6">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="obit_is_syncing"
                                        id="obit_is_syncing"
                                        v-model="activeRecord.obit_is_syncing"
                                        :checked-value="true"
                                    />
                                    <label
                                        class="form-check-label m2"
                                        for="obit_is_syncing"
                                    > Content Is Syncing?</label>
                            </div>
                        <textarea
                            name="content"
                            id="content"
                            v-model="activeRecord.content"
                            title="Enter Content"
                        ></textarea>
                    </div>
                    <br/>
                  <!-- live_stream_link -->
                    <div class="form-group has-label">
                            <label for="title">Live Stream Link </span></label>
                        <input
                            type="text"
                            class="form-control"
                            name="live_stream_link"
                            id="live_stream_link"
                            v-model="activeRecord.live_stream_link"
                            title="Enter a live stream link"

                        />
                        <p class="invalid-feedback">Please enter a live stream link.</p>
                    </div>

                <!-- tribute_link -->
                    <div class="form-group has-label">
                            <label for="title">Tribute Link </span></label>

                        <input
                            type="text"
                            class="form-control"
                            name="tribute_link"
                            id="tribute_link"
                            v-model="activeRecord.tribute_link"
                            title="Enter a tribute  link"

                        />
                        <p class="invalid-feedback">Please enter a tribute stream link.</p>
                    </div>
                    <!-- todo make dynamic -->
                     <label for="title">Send Condolences To:</span></label>
                    <div class="form-group has-label" v-for="(email,index) in activeRecord.send_to"
                    :key="index">
                      <div @click="remove(activeRecord.send_to[index].email,index)">
                         <span class="fa fa-trash m-1"  style="font-size:20px;color:red;"
                         ></span>
                       </div>
                        <input
                            type="email"
                            required
                            class="form-control"
                            name="send_to"
                            id="send_to"
                            v-model="activeRecord.send_to[index].email"
                            title="Please enter email addresses sparated by a semicolon."

                        />
                        <p class="invalid-feedback">Please enter email addresses</p>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="ml-2 cursor-pointer"
                        @click="addField"
                    >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        fill="green"
                        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    />
                    </svg>
                </div><!--col 8 -->
                 <!-- Right Side -->
                <div class="col-md-4">

                   <h5>Obituary Information</h5>
                    <div class="publish-info px-2 py-1 border rounded mb-2">
                        <div class="mx-n2 mt-n1 mb-1 py-1 px-2 border-bottom">
                            <div class="d-flex justify-content-between">
                                <p class="m-0">Date of Birth: {{ activeRecord.funeral_bio.birth_date}}</p>
                            </div>
                              <div class="row flex-wrap" >
                                    <div class="col-md-6">
                                        <div class="form-group mb-0 mt-1">
                                            <input
                                                type="date"
                                                id="date_of_birth"
                                                name="date_of_birth"
                                                class="form-control"
                                                v-model="activeRecord.funeral_bio.birth_date"
                                                value="2018-07-22"
                                                required
                                            />
                                        </div>
                                </div>
                              </div>
                        </div>
                        <div class="mx-n2 mt-n1 mb-1 py-1 px-2 border-bottom">
                            <div class="d-flex justify-content-between">
                                <p class="m-0">Date of Passing: {{ activeRecord.date_of_death}}</p>
                            </div>
                             <div class="row flex-wrap" >
                                <div class="col-md-6">
                                    <div class="form-group mb-0 mt-1">
                                        <input
                                            type="date"
                                            id="date_of_death"
                                            name="date_of_death"
                                            class="form-control"
                                            v-model="activeRecord.date_of_death"
                                            value="2018-07-22"
                                        />
                                    </div>
                              </div>
                            </div>
                        </div>

                      <div class="form-group flex-column">
                        <select
                            id="gender"
                            name="gender"
                            v-model="activeRecord.funeral_bio.gender"
                            class="form-control"
                            title="Please select gender."
                            required
                        >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                        <label for="gender">Gender<span class="required">*</span></label>
                        <p class="invalid-feedback">Please check post gender.</p>
                    </div>
                      <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                name="condolences_are_private"
                                id="condolences_are_private"
                                v-model="activeRecord.condolences_are_private"
                                :checked-value=1

                            />
                            <label
                                class="form-check-label"
                                for="condolences_are_private"
                            >Make Condolences Private?<span class="required">*</span></label>
                        </div>
                         <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                name="is_active"
                                id="is_active"
                                v-model="activeRecord.is_active"
                                :checked-value=1
                                @change="updateEventSync()"

                            />
                            <label
                                class="form-check-label"
                                for="is_active"
                            >Make Obituary Public?<span class="required">*</span></label>
                        </div>
                        <div class="mx-n2 mt-n1 mb-1 py-1 px-2 border-bottom">
                            <div class="d-flex justify-content-between">
                                <p class="m-0">Publish On: {{ activeRecord.published_at}}</p>
                            </div>
                              <div class="row flex-wrap" >
                                    <div class="col-md-6">
                                        <div class="form-group mb-0 mt-1">
                                            <input
                                                type="date"
                                                id="date_of_birth"
                                                name="date_of_birth"
                                                class="form-control"
                                                v-model="activeRecord.published_at"
                                                value="2018-07-22"

                                            />
                                        </div>
                                </div>
                              </div>
                        </div>

                    </div>
                    <!--services -->
                    <h5>Services Information</h5>
                    <div class="publish-info px-2 py-1 border rounded mb-2" v-if="activeRecord.schedules">
                        <div  v-for="(schedule,index) in activeRecord.schedules" :key="index">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">{{ schedule.name}}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted">{{ schedule.location_name}}</h6>
                                    <p class="card-text m-0"><strong>Date: </strong> {{ schedule.date}}</p>
                                    <p class="card-text m-0"> <strong>From: </strong>{{ schedule.start_time }}<strong> To: </strong>{{ schedule.end_time }}</p>
                                    <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                name="`is_private${schedule.id}`"
                                                id="`is_private${schedule.id}`"
                                                v-model="activeRecord.schedules[index].is_private"
                                                :checked-value="1"
                                                 v-bind:true-value="1"
                                                 v-bind:false-value="0"

                                            />
                                            <label
                                                class="form-check-label"
                                                for="`is_private${schedule.id}`"
                                            >Make Event Private?</label>

                                    </div>
                                   <div><button  type="button"  @click="modifyEvents(schedule)">edit</button>
                                   </div>
                                </div>
                            </div>
                        </div>
                         <AddNewEventButton :location="location" :schedule_id="scheduleId" class="mb-2 align-self-end" />
                          <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="is_syncing"
                                  id="is_syncing"
                                  v-model="activeRecord.schedule_is_syncing"
                                  :checked-value="1"
                              />
                              <label
                                  class="form-check-label"
                                  for="is_syncing"
                              > Is Syncing?</label>
                          </div>
                    </div>

                     <!-- Featured Image -->
                    <div class="form-group has-label flex-column">
                        <img
                            v-if="activeRecord.profile_image_path"
                            class="mb-2"
                            id="featuredImage"
                            :src="activeRecord.profile_image_path"
                            :alt="activeRecord.profile_image_path_alt_text"
                        />

                        <input
                            class="form-control"
                            type="text"
                            id="profile_image_path_alt_text"
                            name="profile_image_path"
                            v-model="activeRecord.profile_image_path_alt_text"
                            title="Please enter featured image alt text"
                        />

                        <label for="featuredImage">Profile Image Alt Text</label>

                        <div class="mb-2 d-flex justify-content-end">
                            <button
                                @click.prevent="openFileManager('feat')"
                                class="btn btn-tertiary"
                                id="featuredImageButton"
                                data-input="image_path"
                                title="Opens your file manager where you can choose or upload a new file from your file library"
                            >Set Profile Image</button>
                        </div>

                        <p class="invalid-feedback">Open the file manager modal where you can choose to upload a new file or choose from your file library.</p>
                    </div>
                     <!-- cover Image -->
                    <div class="form-group has-label flex-column">
                        <img
                            v-if="activeRecord.cover_image_path"
                            class="mb-2"
                            id="thumbImage"
                            :src="activeRecord.cover_image_path"
                            :alt="activeRecord.cover_image_alt_text"
                        />

                        <input
                            class="form-control"
                            type="text"
                            id="thumbImage"
                            name="cover_image_alt_text"
                            v-model="activeRecord.cover_image_alt_text"
                            title="Please enter cover image alt text"
                            value="activeRecord.name"

                        />

                        <label for="featuredImage">cover Image</label>
                        <div class="mb-2 d-flex justify-content-end">
                            <button
                                @click.prevent="openFileManager('thumb')"
                                class="btn btn-tertiary"
                                id="thumbImageButton"
                                data-input="thumb_image_path"
                                title="Opens your file manager where you can choose or upload a new file from your file library"
                            >Set Cover Image</button>
                        </div>
                    </div>

                </div><!-- col 4 -->
             	<div class="d-flex justify-content-end">
			    	<button type="submit" class="btn btn-secondary m-1">Save</button>


			    </div>
            </div>
         </form>
    </div>

</template>

<script>
import { mapState, mapActions, mapMutations} from "vuex";
import { openFileManager } from "../../../../../../../../resources/js/core/vpanel/vpanel-functions";
import dayjs from "dayjs";

export default {
  name: "AddModifyObituaryForm",

  mounted() {
    if (this.activeRecord.isActionAdd) {
      this.resetObituary;
    }
  },
  data() {
    return {
      selected: [],
      toggleDatetime: false,
      date: "",
      time: "",
      emails: [{ send_to: "" }],
      didUpdate: this.didUpdateSched,
      eventIndex: "",
      location:"obit"
    };
  },
  watch: {
    activeRecord: {
      handler: function (val, oldVal) {
        this.setDidSaveDraft(false);
      },
      deep: true,
    },
    date(n, o) {
      this.setDateTime(n, this.time);
    },
    time(n, o) {
      this.setDateTime(this.date, n);
    },
    didUpdateSched(n,o){
        if (this.didUpdateSched == true) {
        let id = this.activeRecord.funeral_id;
        this.updateSched(id);
        this.setDidUpdateSched(false);
      } else {
        return false;
      }
    }
  },
  computed: {
    ...mapState("obituary/addModifyObituary", {
      activeRecord: (state) => state.activeRecord,
      isActionAdd: (state) => state.formActionIsAdd,
      isFormProcessing: (state) => state.isFormProcessing,
      didSaveDraft: (state) => state.didSaveDraft,
    }),
    ...mapState("obituary/list", {
      branches: (state) => state.branches,
    }),
    ...mapState("schedule/addModifySchedule", {
      didUpdateSched: (state) => state.didUpdateSched,
      eventActiveRecord: (state) => state.activeRecord,
    }),
    getHeader() {
      let action = this.isActionAdd ? "Add a New " : "Modify ";
      return action + "Obituary";
    },
    scheduleId(){
         let sched= this.activeRecord.schedules[0]?.schedule_id;
         return sched;
    },
  },
  methods: {
    ...mapActions("obituary/addModifyObituary", [
      "submitForm",
      "openPreview",
      "setDidSaveDraft",
      "resetObituary",
      "fetchPassareRecord",
      "updateSched",
      "updateSub"
    ]),
    ...mapActions("schedule/list", [
      "openModModal",
      "fetchSchedule",
      "setDidUpdateSched",
    ]),
    ...mapActions("schedule/addModifySchedule", ["setDidUpdateSched"]),

    ...mapMutations("schedule/addModifySchedule", ["SET_ACTIVE_RECORD"]),
    ...mapActions("schedule/addModifySchedule", ["setFormIsAdd", "openModal"]),
        modifyEvents(row) {
            this.SET_ACTIVE_RECORD(row);
            this.setFormIsAdd(false);
            this.openModal(true);
        },

    formatDate(date) {
      return dayjs(date).format("MMMM D, YYYY");
    },
    openFileManager(image) {
      if (image == "thumb") {
        openFileManager(event.target, this.setThumbImage);
      } else {
        openFileManager(event.target, this.setImage);
      }
    },
    setImage(path) {
      this.activeRecord.profile_image_path = path[0].url;
    },
    setThumbImage(path) {
      this.activeRecord.cover_image_path = path[0].url;
    },
    saveSubmitForm() {
      this.setContent();
      this.submitForm();
    },
    setContent() {
      this.activeRecord.content = CKEDITOR.instances.content.getData();
    },
    setDateTime(d, t) {
      this.activeRecord.scheduled_at = dayjs(`${d} ${t}`).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    addField() {
      this.activeRecord.send_to.push({ email: "" });
    },
    remove(email,index) {
      let payload={'email': email,'funeral_id':this.activeRecord.funeral_id,'index':index}
      this.updateSub(payload);
    },
     removeField() {
      this.activeRecord.send_to.push({ email: "" });
    },
    openEventModModal(id) {
      let obitId = this.activeRecord.id;
      this.openModModal(id, obitId);
    },
    updateObitActiveRecord() {
      let record = this.eventActiveRecord;
      this.updateSched(record);
    },
    updateEventSync(){

      if (this.activeRecord.is_active==true){

          this.activeRecord.schedule_is_syncing=false;
      }
    },
    setContentArea() {
      let contentTextarea = document.querySelector("#content");

      CKEDITOR.replace(contentTextarea, {
        isReadOnly: "false",
        extraPlugins:
          "dialogui,dialog,notification,clipboard,textmatch,fakeobjects",
        removePlugins: "maximize",
        height: 600,
        toolbar: [
          { items: ["Source", "Undo", "Redo", "-"] },
          { items: ["Styles", "FontSize", "TextColor", "-"] },
          { items: ["Bold", "Italic", "Strike", "RemoveFormat", "-"] },
          { items: ["NumberedList", "BulletedList", "-"] },
          {
            items: [
              "Indent",
              "Outdent",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
              "-",
            ],
          },
          { items: ["Blockquote", "-"] },
          { items: ["Iframe", "-", "Maximize", "-", "Scayt"] },
        ],
      });
      CKEDITOR.instances.content.setData(this.activeRecord.content);
    },

  },
  mounted() {
    this.setContentArea();
    this.updateEventSync();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../../sass/_vue-import.scss";

.form-view {
	@extend %minimal-scrollbar;
	overflow: auto;
	height: 100%;
	padding: 1rem;
}

ul.no-bullets {
	list-style-type: none; /* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}

.publish-info input[type="checkbox"] {
	opacity: 1;
	visibility: visible;
	position: static;
}

select[multiple] {
	padding: 0;

	option {
		padding: 0.25rem 1rem;
	}
}
</style>