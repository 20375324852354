<template>
  <div class="flex-grow-1">
    <div
      v-if="errorMessage"
      class="alert alert-danger form-errors alert-dismissible"
      v-html="errorMessage"
    ></div>

    <div
      v-if="successMessage"
      class="alert alert-success alert-dismissible"
      v-html="successMessage"
    ></div>

    <div v-if="role=='organizer'">
      <AddUserToFuneralButton>Invite User</AddUserToFuneralButton>
      <AddModifyFuneralUserModal />
      <SiteUsersInFuneral :role="role" />
      <SiteRecycleUserModal />
    </div>
    <div v-else>You need to be a funeral organizer to manager users.</div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SiteRecycleUserModal from '../components/modals/SiteRecycleUserModal.vue';

export default {
  components: { SiteRecycleUserModal },
  name: 'UsersIndex',

  computed: {
    funeralId() {
      return this.$route.params.id;
    },
    ...mapState({
      user: (state) => state.userData,
      funerals: (state) => state.funerals,
    }),
    ...mapState('user/list', {
      tableData: (state) => state.tableData,
    }),
    ...mapState('user/addModifyUser', {
      successMessage: (state) => state.successMessage,
      errorMessage: (state) => state.errorMessage,
    }),
    ...mapState([
      'isLoading',
    ]),
    role() {
      return this.funerals ? this.funerals[this.$route.params.id].role_name : false;
    },
  },
  methods: {
    ...mapActions('user/list', ['fetchFuneralUsers']),
  },
  mounted() {
    this.fetchFuneralUsers(this.funeralId);
  },
};
</script>
