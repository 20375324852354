<template>
	<vue-modal :visible="isModalOpen" @on-close="closeUserAddModifyModal" id="user-add-modify-modal">
		<template #header>
			<h5 class="modal-title">
				<span class="fas fa-event mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}
			</h5>
		</template>

		<div class="page-settings-details pb-3 pt-0" v-if="!isActionAdd">
			<p class="psd-published">User Name: <span>{{ activeRecord.name }}</span></p>
			<p class="psd-published">Date Added: <span>{{ activeRecord.created_at }}</span></p>
			<p class="psd-modified">Last Modified: <span>{{ activeRecord.updated_at }}</span></p>
			<p class="psd-status" v-if="activeRecord.is_active">Status: <span>Active</span></p>
			<p class="psd-status" v-else>Status: <span>Inactive</span></p>
		</div>

		<!-- Form Include -->
		<add-modify-portal-form
			@submitForm="submitForm"
			:is-action-add="isActionAdd"
			@closeModal="closeUserAddModifyModal"
			:class="{ 'd-none': isFormProcessing }"
		/>

		<vpanel-loader v-if="isFormProcessing" />

		<template #footer>
			<div class="d-flex justify-content-end">
				<button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeUserAddModifyModal">Cancel</button>
				<button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="add-modify-event-form" @click.prevent="submitForm">
					{{ isFormProcessing ? "Processing..." : "Apply" }}
				</button>
			</div>
		</template>
	</vue-modal>
</template>
<script>
import { mapState, mapActions,mapMutations } from "vuex";
export default {
	name: "AddModifyPortalModal",
	computed: {
		...mapState("portal/addModifyPortal", {
			isModalOpen: (state) => state.isModalOpen,
			activeRecord: (state) => state.activeRecord,
			isActionAdd: (state) => state.formActionIsAdd,
			isFormProcessing: (state) => state.isFormProcessing,
		}),
		getModalHeader() {
			let action = this.isActionAdd ? "Add a New " : "Modify ";
			return action + "User";
		},
	},
	methods: {
		...mapActions("portal/addModifyPortal", [
			"openModal",
			"closeModal",
			"submitForm",
		]),
        ...mapMutations("portal/addModifyPortal", ["SET_ACTIVE_RECORD"]),
		...mapActions("portal", {
			fetchFunerals: "list/fetchFunerals",
		}),
		closeUserAddModifyModal() {
            this.SET_ACTIVE_RECORD('');
			this.closeModal();
		},
	},
};
</script>