export default {
    data() {
        return {
            response: '',
            message: '',
            isProcessing: false,
            validated: false,
            errorMessage: '',
            isValid: null,
            errors: [],
        }
    },
    methods: {
        validateForm() {
            this.isValid = this.$refs.form.checkValidity();
            this.validated = true;
        },
        resetAllFormProcessingValues() {
            this.isValid = null;
            this.isProcessing = false;
        },
        clearFormValidation() {
            this.validated = false;
            this.errorMessage = '';
            this.errors = [];
        },
        defaultThen(res) {
            if (res.status == 201 || res.status == 200) {
                this.message = res.data.message;
                this.response = 'Success';
            }
            this.resetForm();
            this.clearFormValidation();
            this.resetAllFormProcessingValues();
        },
        defaultCatch(err) {
            console.error(err);

            if (err.request) {
                this.response = 'Error';
                this.message = "We're sorry an error has occured. Please Try again later.";
            }

            if (err?.response?.data) {
                let vm = this;
                this.errorMessage = err.response.data.message;

                if (Object.keys(err.response.data?.[0]).length) {
                    Object.entries(err.response.data[0]).forEach(([key,value]) => {
                        vm.errors.push({
                            name: key,
                            value: value
                        })
                    })
                }

            }
        },
        defaultFinally() {
            this.isProcessing = false;
            this.successModalVisible = true;
        },
        submitForm({actionUrl, thenCb, catchCb, finallyCb}) {
            //check if the form is valid
            this.validateForm();

            if (this.isValid) {
                //set the form processing variable
                this.isProcessing = true;
                let formData = new FormData(this.$refs.form);
                axios
                    .post(actionUrl, formData)
                    .then(thenCb || this.defaultThen)
                    .catch(catchCb || this.defaultCatch)
                    .finally(finallyCb || this.defaultFinally);
            }
        },
        resetForm() {
            // Pull from initially set form object to reset it
            Object.assign(this.$data.form, this.$options.data.call(this).form);
        }
    }
}