<template>
    <button
        type="button"
        class="btn btn-primary m-0"
        @click.prevent="open()"
    >
        Add Event
    </button>
</template>
<script>
import { mapActions } from "vuex";

export default {
    props:["location","schedule_id"],
    name: "AddNewEventButton",
    methods:{
          ...mapActions("schedule/addModifySchedule", [
            "openModal",
            "resetEvent",
            "setSched"
        ]),
          ...mapActions("obituary/addModifyObituary", [
           "resetObituary",
        ]),

        open(){

            if (this.locationIsIndex){

                this.resetObituary(true);
                this.resetEvent(true);
                this.openModal();
            }else{
                this.setSched(this.schedule_id);
                this.openModal();
            }
        }
    },
      computed:{

            locationIsIndex(){
                let loc=false;
                (this.location == "schedule_index") ? loc= true : loc=false
                return loc;
            }
        }

};
</script>
