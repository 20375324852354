<template>
  <FuneralBioDynamicSection
      name="Church"
      property="church_info"
      :default-obj="defaultObj"
      :section="section"
      sub-title="List Church memberships and involvement."
      v-slot="slotProps"
  >
    <VueTextarea
      class="m-0"
      name="Church Description"
      v-model="slotProps.item.church_description"
    />
  </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentChurches',
  props: ['section'],
  data() {
    return {
      defaultObj: {
        church_description: '',
      },
    };
  },
};
</script>
