<template>
  <FuneralBioDynamicSection
    name="Veteran Service"
    property="veteran_info"
    :default-obj="defaultObj"
    :section="section"
    v-slot="slotProps"
  >
    <div class="input-grid">
      <VueSelect
        label="Branch"
        name="branch"
        v-model="slotProps.item.branch"
        :options="branchOptions"
      />
      <VueDate
        label="Date Enlisted"
        name="date_enlisted"
        v-model="slotProps.item.enlist_date"
      />
      <VueInput
        label="Enlisted Location"
        name="enlisted_location"
        v-model="slotProps.item.enlist_loc"
      />
      <VueDate
        label="Discharge Date"
        name="discharge_date"
        v-model="slotProps.item.discharge_date"
      />
      <VueInput
        label="Discharge Location"
        name="discharge_location"
        v-model="slotProps.item.discharge_location"
      />
      <VueInput
        label="Where is a copy of your discharge"
        name="where_is_a_copy_of_your_discharge"
        v-model="slotProps.item.discharge_copy_location"
      />
      <VueInput
        label="Service Rank"
        name="service_rank"
        v-model="slotProps.item.rank"
      />
      <VueInput
        label="Service Number"
        name="service_number"
        v-model="slotProps.item.service_number"
      />
      <VueCheckbox
        label="Are you Receiving VA Benefits?"
        name="are_you_receiving_va_benefits"
        v-model="slotProps.item.has_va_benifits"
      />
      <VueInput
        label="VA Claim Number"
        name="va_claim_number"
        v-model="slotProps.item.va_claim_number"
      />
    </div>

    <VueTextarea
      label="Organization Served With"
      name="organization_served_with"
      v-model="slotProps.item.organizations_served_with"
      class="mt-2 mx-0 mb-0"
    />
    <VueTextarea
      label="Notes"
      name="notes"
      v-model="slotProps.item.notes"
      class="mt-2 mx-0 mb-0"
    />
  </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentVeteranService',
  props: ['section'],
  data() {
    return {
      defaultObj: {
        branch: '',
        enlist_date: '',
        enlist_loc: '',
        discharge_date: '',
        discharge_location: '',
        discharge_copy_location: '',
        rank: '',
        service_number: '',
        has_va_benifits: '',
        va_claim_number: '',
        organizations_served_with: '',
        notes: '',
      },
      branchOptions: [
        { name: 'Army', value: 'army' },
        { name: 'Navy', value: 'navy' },
        { name: 'Marine Corps', value: 'marine_corps' },
        { name: 'Air Force', value: 'air_force' },
        { name: 'Coast Guard', value: 'coast_guard' },
        { name: 'National Guard', value: 'national_guard' },
      ],
    };
  },
};
</script>
