<template>
  <div class="content container my-5">
    <h2 class="top-title">Planning Assistance with Locke</h2>
    <div class="text-center justify-content-center mx-auto w-100 w-md-75">
      <h4 class="text-primary">There are many options to consider when planning a funeral or celebration of life. You will have options which relate to the final disposition of the body, the help or services we provide to you, items you may purchase, and other arrangements we might help with like newspaper obituaries. Your decisions will be based on information we come up with together to meet your family's unique needs. Our staff has the knowledge and experience to help you create a plan and carry it out.</h4>

      <h4 class="text-primary">In the process that follows you will be shown Service Options, Purchase Options, and Other Arrangement Options. Detailed information and complite pricing information can be found on our General Price List and Merchandise Price Lists avaiable below. You may also start your planning and view pricing as you work your way through the planning steps.</h4>

      <a
        class="btn btn-primary m-2"
        href="/storage/shares/GPL.pdf"
        target="_blank"
        title="Opens General Price List PDF in new tab"
      >General Price List</a>

      <a
        class="btn btn-secondary m-2"
        href="/storage/shares/MPL.pdf"
        target="_blank"
        title="Opens Merchandise Price List PDF in new tab"
      >Merchandise Price List</a>
    </div>

    <h3 class="text-center text-primary title-option my-4"><span>To Begin Your Planning Process<br>When May You Need Services</span></h3>

    <div class="planning-cards">
      <PlanningCards
        cardTitle="Now"
        cardImage="/images/custom/site/cremation-center/Now.jpeg"
        :product="optionNow"
        cardStep=0
        cardId="now"
        class="h-auto"
      >
        <p class="text-center">A loved one has passed and we have an immediate&nbsp;need</p>
        <p class="text-center"><strong>For immediate assistance, please call <a href="tel:3192336138" title="Opens our number in your default calling application">(319)&nbsp;233&#8209;6138</a></strong></p>
      </PlanningCards>

      <PlanningCards
        cardTitle="Soon"
        cardImage="/images/custom/site/cremation-center/Soon.jpeg"
        :product="optionSoon"
        cardStep=0
        cardId="soon"
        class="h-auto"
      >
        <p class="text-center">We will need services soon</p>
      </PlanningCards>

      <PlanningCards
        cardTitle="Planning Ahead"
        cardImage="/images/custom/site/cremation-center/Planning.jpeg"
        :product="optionPlaning"
        cardId="planning-ahead"
        cardStep=0
        class="h-auto"
      >
        <p class="text-center">We want to have a plan before there is a need</p>
      </PlanningCards>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      optionNow: { need: 'now' },
      optionSoon: { need: 'soon' },
      optionPlaning: { need: 'planning-ahead' },
    };
  },
};
</script>
