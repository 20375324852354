<template>
    <vue-modal :visible="isModalOpen" @on-close="closeCartAddModifyModal" id="cart-add-modify-modal">
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-data mr-1 text-tertiary"></span
                >&nbsp;{{ getModalHeader }}
            </h5>
        </template>

        <div class="page-settings-details pb-3 pt-0" v-if="!isActionAdd">
            <p class="psd-published">
                Date Added: <span>{{ activeRecord.created_at }}</span>
            </p>
            <p class="psd-modified">
                Last Modified: <span>{{ activeRecord.updated_at }}</span>
            </p>
            <p class="psd-modified">
                Decedent: <span>{{ activeRecord.decedent }}</span>
            </p>
            <p class="psd-modified">
                Cart User: <span>{{ activeRecord.user }}</span>
            </p>
            <p class="psd-modified">
                Approval Status: <span>{{ activeRecord.approval }}</span>
            </p>
        </div>

        <!-- Form Include -->
        <add-modify-cart-form
            :visible="isModalOpen"
            @submitForm="submitForm"
            :is-action-add="isActionAdd"
            @closeModal="closeCartAddModifyModal"
            :class="{ 'd-none': isFormProcessing }"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeCartAddModifyModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="add-modify-cart-form" @click.prevent="submitForm">
                    {{ isFormProcessing ? "Processing..." : "Apply" }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "AddModifyCartModal",
    computed: {
        ...mapState("cart/addModifyCart", {
            isModalOpen: state => state.isModalOpen,
            activeRecord: state => state.activeRecord,
            isActionAdd: state => state.formActionIsAdd,
            isFormProcessing: state => state.isFormProcessing
        }),
        getModalHeader() {
            let action = this.isActionAdd ? "Add a New " : "Modify ";

            return action + "Cart";
        }
    },
    methods: {
        ...mapActions("cart/addModifyCart", [
            "submitForm",
            "closeModal",
            "resetCart"
        ]),
        closeCartAddModifyModal() {
            this.closeModal();
            this.resetCart();
        }
    }
};
</script>
