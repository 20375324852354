<template>
  <div class="cremation-main">
    <div class="content container my-5">
      <h2 class="top-title">Funeral Planning Assistance with Locke</h2>
      <h4 class="text-center text-primary">There are many options available for those interested in planning services. <br />Choose from the options below to continue your planning.</h4>
      <h3 class="text-center text-primary title-option my-4"><span>What Type Of Service Are You Looking For?</span></h3>

      <div class="planning-cards">
        <PlanningCards
          cardTitle="Burial"
          cardImage="/images/custom/site/cremation-center/burial.jpeg"
          :product="burial"
          cardStep=1
          class="h-auto text-center"
          cardId="burial"
        >
          <p class="text-center">
            Our staff will help you through every step of choosing the options that are right for you, hepling with the details that arise, and answering questions and providing information.
          </p>
        </PlanningCards>
        <PlanningCards
          cardTitle="Cremation"
          cardImage="/images/custom/site/cremation-center/cremation.jpeg"
          :product="cremation"
          cardStep=1
          class="h-auto text-center"
          cardId="cremation"
        >
          <p class="text-center">
            With Locke Funeral Services' Cremation Center, and our on site crematory, details related to any cremation are handled in house by our staff. You choose how much of our help you need.
          </p>
        </PlanningCards>
        <PlanningCards
          cardTitle="Other Options"
          cardImage="/images/custom/site/cremation-center/other-services.jpeg"
          :product="other"
          cardStep=1
          class="h-auto text-center"
          cardId="other"
        >
          <p class="text-center">
            We offer our help for other services including where the death or services occur away from Waterloo, the burial is immediate, or anatomical donation of the body.
          </p>
        </PlanningCards>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['LockeAssistance'],
  data() {
    return {
      burial: { serviceType: 'burial' },
      cremation: { serviceType: 'cremation' },
      other: { serviceType: 'other' },
    };
  },
};
</script>
