<template>
    <vue-modal :visible="isModalOpen" @on-close="closeRecycleModal" id="portal-recycle-modal">
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-trash mr-1 text-tertiary"></span>Confirm
                User Delete
            </h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">
                Are you sure you want to delete this User?
            </p>
            <p>This action is permanent.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeRecycleModal">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click="recycleUser" :disabled="isModalProcessing">{{ isModalProcessing ? "Processing..." : "Delete user" }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "RecyclePortalModal",

    computed: {
        ...mapState("portal", {
            activeRecord: state => state.addModifyPortal.activeRecord,
            isModalOpen: state => state.recyclePortalModal.isModalOpen,
            isModalProcessing: state => state.recyclePortalModal.isModalProcessing
        })
    },
    methods: {
        ...mapActions("portal", {
            resetFuneral: "addModify/resetFuneral",
            openModal: "recyclePortalModal/openModal",
            closeModal: "recyclePortalModal/closeModal",
            recycleRecord: "recyclePortalModal/recycleRecord"
        }),
        closeRecycleModal() {
            this.resetFuneral();
            this.closeModal();
        },
        recycleUser() {
            this.recycleRecord({
                ajaxRoute:
                    "/vpanel/modules/portal/destroy/" +
                    this.activeRecord.id,
                dispatchAction: "portal/list/removeRecordFromDataTable"
            });
            this.closeRecycleModal();
        }
    }
};
</script>
