<template>
    <div class="glide">
        <div class="glide__track" data-glide-el="track">
            <div class="glide__slides">
                <div
                    class="glide__slide"
                    v-for="(slide, index) in slides"
                    :key="slide.mm_uuid"
                >
                    <div class="slide-wrapper">
                        <div class="slide-bg-image" :style="{backgroundImage: `url('${slides[index][bgImageSize]}')`}"></div>
                        <div class="dynamic-container">
                            <div class="slide-content">
                                <p class="h1 slide-title">{{ slide.title }}</p>
                                <p class="slide-tagline">{{ slide.tagline }}</p>
                                <vue-link
                                    link-class="btn btn-yellow"
                                    :link-path="slide.button_url"
                                    :link-text="slide.button_text"
                                    :link-target="slide.button_target"
                                    v-if="slide.button_url && slide.button_text"
                                    :data-nodisclaimer="!slide.button_disclaimer"
                                ></vue-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="slides.length > 1">
            <div class="glide__arrows" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                <button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
            </div>
            <div class="glide__bullets" data-glide-el="controls[nav]">
                <button
                    v-for="n in slides.length"
                    :key="'bullet' + (n - 1)"
                    class="glide__bullet"
                    :data-glide-dir="`=${n - 1}`"
                ></button>
            </div>
        </template>
    </div>
</template>

<script>
import { mixin } from '@v/Modules/MarketingMessage/Core/Site/marketingMessageMixin';

export default {
    name: "MarketingMessageTemplateLocke",
    mixins: [mixin],
    data() {
        return {
            options: {
                type: 'carousel',
                gap: 0,
                animationDuration: 200,
                swipeThreshold: false,
                dragThreshold: false
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import "../../../sass/_vue-import.scss";
@import "../../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";

//slider custom theming
.glide {
    opacity: 0;
    transition: 200ms ease-out;

    &.glide--carousel {
        opacity: 1;
    }

    .glide__slides {
        align-items: stretch;

        .glide__slide {
            height: auto;
        }
    }
}

.slide-wrapper {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;

    .slide-bg-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0,0.2); // Not compliant, but it's what they had before
        }
    }

    .dynamic-container {
        @include fluid-size(48px, 96px, --spacing);
        $padding: 3rem;
        max-width: calc(1110px + #{$padding * 2});
        margin-left: auto;
        margin-right: auto;
        margin-top: calc(var(--hd-height) + var(--spacing));
        margin-bottom: calc(80px + var(--spacing));
        padding-left: $padding;
        padding-right: $padding;
        width: 100%;
    }

    .slide-content {
        position: relative;
        max-width: 60ch;
        margin: 0 auto;
        text-align: center;

        p {
            color: white;
            font-family: 'Roboto Condensed';
            text-shadow: 0 2px 4px rgba(0,0,0,.5);
        }

        .slide-title {
            @include fluid-size(24px, 40px, font-size);
        }

        .slide-tagline {
            @include fluid-size(16px, 20px, font-size);
            margin: .75em 0 1em;
        }
    }
}

.glide__arrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    bottom: 0;
    left: 0;
    pointer-events: none;

    .glide__arrow {
        --arrow-arm-length: 20px;
        --arrow-chonk: 5px;
        position: relative;
        background-color: transparent;
        width: 3rem;
        height: 100%;
        text-indent: -9999px;
        outline: 0;
        border: 0 solid white;
        border-radius: 2px;
        opacity: .8;
        pointer-events: all;
        transition: 100ms ease-out;

        &:hover,
        &:focus {
            border-width: 2px;
            opacity: 1;
        }

        &:before, &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            height: var(--arrow-chonk);
            width: var(--arrow-arm-length);
            transform-origin: calc(100% - (var(--arrow-chonk) / 2)) 50%;
            border-radius: 55rem;
            background-color: white;
            transform: translate(-50%, -50%);
        }

        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after { transform: translate(-50%, -50%) rotate(-45deg); }
        &--left { &:before, &:after { transform-origin: calc(var(--arrow-chonk) / 2) 50%; } }
    }
}

.glide__bullets {
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    padding: 12px 8px;

    .glide__bullet {
        border: 0;
        outline: 0;
        background-color: var(--yellow);
        border-width: 1px;
        border-style: solid;
        border-color: white;
        height: 14px;
        width: 14px;
        margin: 0 4px;
        border-radius: 50rem;
        padding: 0;
        opacity: .5;
        transition: opacity 200ms ease-out;

        &--active, &:hover {
            opacity: 1;
        }
    }
}
</style>