<template>
    <form
        id="add-modify-condolence-form"
        class="condolence-form needs-validation"
        name="add-modify-condolence-form"
        novalidate
        @submit.prevent="submitForm"
    >
        <div class="form-errors"></div>
        <input type="hidden" name="_token" :value="activeRecord._token" />
        <input
            type="hidden"
            name="id"
            :value="activeRecord.id"
            v-if="!isActionAdd"
        />
        <div
            class="d-flex flex-column justify-content-between align-items-stretch h-100"
        >
            <div
                class="document-form-fields d-flex flex-column align-items-stretch"
            >
                <h5>Basic Information</h5>
                  <div class="form-group has-label">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="full_name">{{activeRecord.decedent}}</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Decedent"
                        ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <p></p>
                </div>
                    <div class="form-group has-label">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <label class="required" for="full_name">Name</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Name"
                                ><span
                                    class="fas fa-question-circle text-info cursor-pointer"
                                ></span
                            ></a>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            required
                            name="name"
                            id="name"
                            title="Please enter name"
                            autocomplete="off"
                            data-invalid-feedback="Please enter a condolence name."
                            v-model="activeRecord.name"
                        />
                        <div class="invalid-feedback">
                            Please enter a name.
                        </div>
                    </div>
                    <div class="form-group has-label">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <label class="required" for="full_name">Relationship</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Relationship"
                                ><span
                                    class="fas fa-question-circle text-info cursor-pointer"
                                ></span
                            ></a>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            required
                            name="relationship"
                            id="relationship"
                            title="Please enter relationship"
                            autocomplete="off"
                            data-invalid-feedback="Please enter a condolence relationship."
                            v-model="activeRecord.relationship"
                        />
                        <div class="invalid-feedback">
                            Please enter a relationship.
                        </div>
                    </div>

                    <div class="form-group has-label">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <label class="required" for="user_email"
                                >Email</label
                            >
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="The email of the user"
                                ><span
                                    class="fas fa-question-circle text-info cursor-pointer"
                                ></span
                            ></a>
                        </div>
                        <input
                            type="email"
                            required
                            class="form-control"
                            name="email"
                            id="email"
                            title="Please enter email"
                            autocomplete="off"
                            v-model="activeRecord.email"
                            data-invalid-feedback="Please enter email."
                        />
                        <div class="invalid-feedback">
                            Please enter email.
                        </div>
                    </div>

                    <div class="form-group has-label">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <label class="required" for="condolence"
                                >Condolence</label
                            >
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Condolence"
                                ><span
                                    class="fas fa-question-circle text-info cursor-pointer"
                                ></span
                            ></a>
                        </div>
                        <textarea
                            required
                            class="form-control"
                            name="condolence"
                            id="condolence"
                            title="Please enter condolence"
                            autocomplete="off"
                            v-model="activeRecord.content"
                            data-invalid-feedback="Add a condolence of the "
                        />
                        <div class="invalid-feedback">
                            Add a Condolence.
                        </div>
                    </div>

                    <div class="form-group flex-grow-1">
                        <div
                            class="radio-legend d-flex justify-content-between align-items-center"
                        >
                            <span class="required mr-3">Status</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Please select a status for this condolence."
                                ><span
                                    class="fas fa-question-circle text-info cursor-pointer"
                                ></span
                            ></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Status</legend>
                            <div
                                class="form-check form-check-inline form-check-radio"
                            >
                                <label
                                    class="form-check-label"
                                    for="status-approved"
                                >
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        title="status"
                                        required
                                        name="status"
                                        id="status-approved"
                                        value="Approved"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please select a status for this condolence."
                                    />
                                    <span class="form-check-sign"></span>
                                    Approved
                                </label>
                            </div>

                            <div
                                class="form-check form-check-inline form-check-radio"
                            >
                                <label
                                    class="form-check-label"
                                    for="status-pending"
                                >
                                    <input
                                        class="form-check-input"
                                        title="status"
                                        type="radio"
                                        name="status"
                                        id="status-pending"
                                        value="Pending"
                                        v-model="activeRecord.status"
                                        data-invalid-feedback="Please select a status for this condolence."
                                    />
                                    <span class="form-check-sign"></span>
                                    Pending
                                    <div class="invalid-feedback">
                                        Please select a status for this
                                        condolence.
                                    </div>
                                </label>
                            </div>

                            <div
                                class="form-check form-check-inline form-check-radio"
                            >
                                <label
                                    class="form-check-label"
                                    for="status-declined"
                                >
                                    <input
                                        class="form-check-input"
                                        title="status"
                                        type="radio"
                                        name="status"
                                        id="status-declined"
                                        value="Declined"
                                        v-model="activeRecord.is_private"
                                        data-invalid-feedback="Please select a status for this condolence."
                                    />
                                    <span class="form-check-sign"></span>
                                    Declined
                                    <div class="invalid-feedback">
                                        Please select a status for this
                                        condolence.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                     <div class="form-group flex-grow-1">
                        <div
                            class="radio-legend d-flex justify-content-between align-items-center"
                        >
                            <span class="required mr-3">Visibility</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Please select a status for this condolence."
                                ><span
                                    class="fas fa-question-circle text-info cursor-pointer"
                                ></span
                            ></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Visibility</legend>
                            <div
                                class="form-check form-check-inline form-check-radio"
                            >
                                <label
                                    class="form-check-label"
                                    for="visibility-public"
                                >
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        title="is_private"
                                        required
                                        name="is_private"
                                        id="visibility-public"
                                        value="0"
                                        v-model="activeRecord.is_private"
                                        data-invalid-feedback="Please select visibility for this condolence."
                                    />
                                    <span class="form-check-sign"></span>
                                    Public
                                </label>
                            </div>

                            <div
                                class="form-check form-check-inline form-check-radio"
                            >
                                <label
                                    class="form-check-label"
                                    for="visibility-private"
                                >
                                    <input
                                        class="form-check-input"
                                        title="is_private"
                                        type="radio"
                                        name="is_private"
                                        id="visibility-private"
                                        value="1"
                                        v-model="activeRecord.is_private"
                                        data-invalid-feedback="Please select a visibility status for this condolence."
                                    />
                                    <span class="form-check-sign"></span>
                                    Private
                                    <div class="invalid-feedback">
                                        Please select visibility for this condolence.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "AddModifyCondolenceForm",
    props: ["isActionAdd", "activeRecord"],

    computed: {
        getHeader() {
            let action = this.isActionAdd ? "Add a New " : "Modify ";
            return action + "Obituary";
        },
        ...mapState("condolence/list", {
            // moduleConfig: state => state.moduleConfig,
            // cspNonce: state => state.cspNonce
        }),
        ...mapState("condolence/addModifyCondolence", {
           // activeRecord: state => state.activeRecord,
            isFormProcessing: state => state.isFormProcessing,
            didSaveDraft: state => state.didSaveDraft
        }),

    },
    methods: {}
};
</script>
