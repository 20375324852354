<template>
  <div class="dynamic-sections">
    <div class="mx-0 mb-0">
      <h4 class="mb-1">{{ section }}</h4>
      <p class="mb-4" v-if="subTitle"><small>{{ subTitle }}</small></p>
    </div>

    <div
      class="dynamic-section"
      v-for="(item, index) in parseNestedProperty"
      :key="index"
    >
      <slot
        :item="item"
        :index="index"
      ></slot>

      <div class="icon-bar m-0">
        <button
          class="icon-delete"
          @click.prevent="deleteRow(index)"
          title="Delete entry"
        >
          <FontAwesomeIcon
            fixed-width
            :icon="icons.trash"
          />
          <span class="icon-text">Delete</span>
        </button>
      </div>
    </div>
    <button
      class="btn btn-primary align-self-start mx-0 mb-0"
      @click.prevent="addRow()"
    >Add {{ name }}</button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';

export default {
  props: ['name', 'section', 'subTitle', 'property', 'defaultObj'],
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      icons: {
        trash: faTrashAlt,
      },
    };
  },
  computed: {
    ...mapState('siteFuneral/addModify', {
      activeRecord: (state) => state.activeRecord,
    }),
    parseNestedProperty() {
      // Returns the path to point at in the activeRecord
      let ar = this.activeRecord.funeral_bio;
      const parts = this.property.split('.');
      if (!ar) return undefined;
      if (parts.length <= 1) return ar[this.property];
      parts.forEach((part) => {
        ar = ar[part];
        if (!ar) return undefined;
      });
      return ar;
    },
  },
  methods: {
    addRow() {
      this.parseNestedProperty.push(cloneDeep(this.defaultObj));
    },
    deleteRow(index) {
      this.parseNestedProperty.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-sections {
  display: flex;
  flex-direction: column;
  margin: -.75rem;

  > * {
    margin: .75rem;
  }

  .dynamic-section {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1rem 1rem;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: .75rem .25rem;

    > * {
      margin: .5rem;
    }

    .icon-bar {
      display: flex;
      gap: 0.5rem;
      position: absolute;
      top: 0;
      right: 0.5rem;
      transform: translateY(-50%);
      z-index: 1;

      button {
        font-size: 12px;
        display: flex;
        align-items: center;
        min-height: 24px;
        min-width: 24px;
        padding: 0;
        justify-content: center;
        border: 0;
        border-radius: 50rem;
        outline: 0;
        background-color: white;
        color: var(--primary);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        transition: 200ms ease-out;

        &:hover,
        &:focus {
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
          padding: 0 0.5rem;

          .icon-text {
            max-width: 100px;
            margin-left: 0.25rem;
          }
        }

        .icon-text {
          max-width: 0;
          margin-left: 0;
          overflow: hidden;
          transition: 200ms ease-out;
        }

        &.icon-delete {
          background-color: var(--danger);
          color: white;
        }
      }
    }
  }
}
</style>
