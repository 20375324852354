<template>
    <div class="datatable-section p-2">
        <!--todo add <vue-loader v-if="isLoading" /> -->
        <AddNewEventButton  class="mb-2 align-self-end" :location="location" />
        <vpanel-schedule-list />
        <add-modify-event-modal :location="location" />
        <recycle-event-modal />
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "ScheduleIndex",

    data(){
        return {
            location:"schedule_index"
        }
    },
    computed: {
        ...mapState("schedule/list", {
            isLoading: (state) => state.isLoading,
        }),
    },

};
</script>
