<template>
    <vue-modal
        :visible="isModalOpen"
        @on-close="closeSyncModal"
        id="passare-sync-modal"
    >
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-trash mr-1 text-tertiary"></span>Confirm
                Passare Sync
            </h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-warning fa-7x"></span>
            <p class="h4 font-weight-bold">
                Sync Passare Data?
            </p>
            <p>
                This action may take a few minutes!
            </p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button
                    type="button"
                    class="btn btn-outline-primary cancel-btn"
                    @click="closeSyncModal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    class="btn btn-primary confirm-btn ml-2"
                    @click="syncAll"
                    :disabled="isModalProcessing"
                >
                    {{ isModalProcessing ? "Processing..." : "Sync" }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "SyncPassareModal",
    computed: {
        ...mapState("obituary", {
            //   activeRecord: state => state.addModifyObituary.activeRecord,
            isModalOpen: state => state.syncPassareModal.isModalOpen,
            isModalProcessing: state => state.syncPassareModal.isModalProcessing
        })
    },
    methods: {
        ...mapActions("obituary", {
            closeModal: "syncPassareModal/closeModal",
            syncAll: "list/syncAllPassare"
            //  recycleRecord: "syncPassareModal/recycleRecord"
        }),
        closeSyncModal() {
            this.closeModal();
        }
        // recycleObituary() {
        //     this.recycleRecord({
        //         ajaxRoute:
        //             "/vpanel/modules/obituary/destroy/" + this.activeRecord.id,
        //         recordId: this.activeRecord.id,
        //         dispatchAction: "obituary/list/removeRecordFromDataTable"
        //     });
        // }
    }
};
</script>
