<template>
  <div class="flex-grow-1 position-relative">
    <PortalCalendar :schedules="currentSchedule" />

    <div class="icon-bar" v-if="isCurrentFuneralOrganizer && hasEvent">
      <RouterLink
        class="icon-delete"
        :to="{ name: 'EventIndex', params: { id: this.$route.params.id, eventId: 'add' } }"
        title="Create new event for this funeral"
      >
        <FontAwesomeIcon fixed-width :icon="icons.calendarPlus" />
        <span class="icon-text">Add&nbsp;Event</span>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'SiteScheduleIndex',
  data() {
    return {
      icons: {
        calendarPlus: faCalendarPlus,
      },
    };
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    ...mapState(['schedules', 'funerals']),
    isCurrentFuneralOrganizer() {
      return this.funerals?.[this.$route.params.id]?.role_name === 'organizer' || false;
    },
    currentSchedule() {
      const vm = this;

      return Object.entries(this.schedules).reduce((a, [k, v]) => {
        // k is the funeral_id
        if (k === vm.$route.params.id) a[k] = v;
        return a;
      }, {});
    },
    hasEvent() {
      return (Object.keys(this.currentSchedule) > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
  .schedule-view {
    position: relative;

    .icon-bar {
      opacity: 1;
      pointer-events: all;
    }
  }

  .add-new-event {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-1rem, -50%);
  }

  .icon-bar {
    transition: 200ms ease-out;
    display: flex;
    gap: .5rem;
    position: absolute;
    top: 0;
    right: .5rem;
    transform: translateY(-50%);
    z-index: 1;

    a {
      font-size: 14px;
      display: flex;
      align-items: center;
      min-height: 24px;
      min-width: 24px;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 50rem;
      outline: 0;
      color: white;
      background-color: var(--primary);
      box-shadow: 0 0 5px rgba(0,0,0,0.2);
      text-decoration: none;
      padding: 2px .5rem;
      transition: 200ms ease-out;

      &:hover, &:focus {
        box-shadow: 0 0 0 2px var(--primary);
      }
    }

    .icon-text {
      padding-top: 1px;
      margin-left: .25rem;
    }
  }
</style>
