<template>
    <div id="cc-module" class="flex-grow-1 d-flex" style="min-height: 100vh;">
        <router-view />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    computed: {
        ...mapState('cart/addModifyCart', {
            activeRecord: (state) => state.activeRecord,
        }),
    },
    methods: {
        ...mapActions('cart/addModifyCart', [
            'openModal',
            'closeModal',
            'setFormIsAdd',
        ]),
        ...mapActions('cart/addModifyCart', [
            'fetchProducts',
        ]),
    },
    created() {
        this.fetchProducts();
    },
}
</script>

<style lang="scss">
    #cc-module {
        .service-footer {
            position: static;
        }
    }
</style>
