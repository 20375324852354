<template>
  <div class="bio-nav">
    <button
      v-for="section in sections"
      :key="section"
      @click.prevent="setActiveView(section)"
      class="btn lh-xs"
      :class="[activeView === section ? 'btn-primary' : 'btn-light']"
      :title="`Shows the ${section} section`"
    >{{ section }}</button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      sections: [
        'Biographical Info',
        'Family Info',
        'Education Info',
        'Occupational Info',
        'Organizational Involvement',
        'Hobbies and Interests',
        'Church Involvement',
        'Service Info',
        'Financial Info',
        'Veteran Info',
      ],
    };
  },
  computed: {
    ...mapState('siteFuneral/addModify', [
      'activeView',
    ]),
  },
  methods: {
    ...mapActions('siteFuneral/addModify', [
      'setActiveView',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  .bio-nav {
    display: flex;
    flex-wrap: wrap;
    margin: -.125rem;

    button {
      margin: .125rem;
      padding: .25rem .5rem;
      line-height: 1;
      font-size: 14px;

      &.btn-light {
        border: 1px solid hsl(210deg, 17%, 80%);
      }
    }
  }
</style>
