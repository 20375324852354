<template>
    <div class="subscription-section">
        <p class="title">Subscribe</p>
        <p>Subscribe to receive our most recent {{ viewData.header }}</p>
        <div class="alert alert-danger form-errors" v-if="errorMessage">
            <p>{{ errorMessage }}</p>
            <ul>
                <li v-for="e in errors" :key="e.name">{{e.name}}: {{e.value}}</li>
            </ul>
        </div>
        <form
            @submit.prevent="submitSubscribeForm"
            ref="form"
            name="subscribe-form"
        >
            <vue-input
                class="mt-2"
                name="email"
                label="Email"
                type="email"
                v-model="form.email"
            ></vue-input>

            <div v-if="form.email">
                <input
                    type="checkbox"
                    :value="viewData.inputValue"
                    name="subscriptions[]"
                    checked
                    hidden
                    v-model="form.subscriptions"
                />
                <label>
                    <input
                        type="checkbox"
                        :value="viewData.altInputValue"
                        name="subscriptions[]"
                        v-model="form.subscriptions"
                    />
                    <span class="text-14">I would also like to subscribe to {{ viewData.altInputLabel }}</span>
                </label>
            </div>
            <button class="btn btn-primary mt-2 mb-0">
                Subscribe Today
            </button>
        </form>

        <vue-modal :visible="successModalVisible" @on-close="successModalVisible = false">
            <template #header>
                <p
                    class="h2 m-0"
                    v-show="response"
                    :class="[response == 'Success' ? 'text-success' : 'text-danger']"
                >
                    <span v-if="response == 'Success'" class="fas fa-check mr-1"></span>{{ response }}
                </p>
            </template>
            <p class="m-0">{{ message }}</p>
        </vue-modal>
    </div>
</template>

<script>
import mixin from './formProcessingMixin';
export default {
    props: ["view"],
    mixins: [mixin],
    data() {
        return {
            form: {
                email: '',
                subscriptions: [this.view],
            },
            successModalVisible: false,
            showSubscription: false,
        };
    },
    computed: {
        viewData() {
            if (this.view == 'Obituary') {
                return {
                    header: 'obituaries',
                    inputValue: 'Obituary',
                    altInputLabel: 'blogs',
                    altInputValue: 'Blog'
                }
            }

            return {
                header: 'blogs',
                inputValue: 'Blog',
                altInputLabel: 'obituaries',
                altInputValue: 'Obituary'
            }
        }
    },
    methods: {
        submitSubscribeForm() {
            this.submitForm({
                actionUrl: '/subscriber/store'
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.subscription-section {
    padding: 4rem 0 4rem 2rem;
    position: relative;
    margin-bottom: 2rem;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100vw;
        border-radius: 25px;
        background-color: var(--lighter-blue);
        z-index: -1;
    }

    &:before {
        width: 35vw;
        border-radius: 25px;
    }

    .title {
        padding-top: 0.5rem;
        font-size: 24px;
        color: var(--blue);
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 0.5rem;

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 1px;
            width: 100vw;
            background-color: var(--light-blue);
        }
    }
}
</style>