<template>
    <div class="condolence-form" :class="{ 'mt-6': !condolencesArePrivate }">
        <p class="title">Express your condolences...</p>
        <form
            id="add-condolence-form"
            name="add-condolence-form"
            @submit.prevent="onSubmit"
            ref="formEl"
        >
            <span class="text-muted text-14 mb-1 d-block"
                >All fields marked with * are required</span
            >

            <vue-textarea name="content" v-model="comment" required maxlength="5000" />
            <p class="small">{{comment.length}} /5000 </p>

            <div class="row">
                <div :class="[condolencesArePrivate ? 'col-md-6' : 'col-md-4']">
                    <vue-input name="name" v-model="name" required />
                </div>

                <div v-show="!condolencesArePrivate" class="col-md-4">
                    <vue-checkbox
                        name="is_private"
                        label="Make condolence private?"
                        v-model="privateCondolence"
                        :checked-value="1"
                    />
                </div>

                <div :class="[condolencesArePrivate ? 'col-md-6' : 'col-md-4']">
                    <vue-input
                        name="email"
                        type="email"
                        v-model="email"
                        required
                    />
                </div>
            </div>

            <input
                name="obituary_id"
                :value="activeObituary"
                type="hidden"
                required
            />

            <input
                name="hcaptchaToken"
                v-model="token"
                type="hidden"
                required
            />

            <VueHcaptcha
                v-if="hcaptchaSiteKey"
                :sitekey="hcaptchaSiteKey"
                @verify="onVerify"
                @expired="onExpire"
                @error="onError"
            />

            <button
                class="btn btn-primary mt-1"
                :class="{ disabled: !verified }"
                :disabled="!verified || maxCommentCountIsOverLimit"
            >
                Submit Condolence
            </button>
        </form>

        <vue-modal :visible="successModalVisible" @on-close="closeModal">
            <template #header>
                <p class="h2 m-0 text-success" v-if="response == 'Success'">
                    <span class="fas fa-check mr-1"></span>{{ response }}
                </p>
                <p class="h2 m-0" v-else>{{ response }}</p>
            </template>
            <p class="m-0">{{ message }}</p>
            <div id="form-errors" class="form-errors mb-0"></div>
        </vue-modal>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
    components: { VueHcaptcha },
    props: ["obituary"],
    data() {
        return {
            comment: "",
            name: "",
            email: "",
            privateCondolence: false,
            verified: false,
            expired: false,
            error: null,
            token: null,
            eKey: null
        };
    },
    mounted() {
        if (this.condolencesArePrivate) this.privateCondolence = true;
    },
    computed: {
        ...mapState("obituary", ["activeObituary", "hcaptchaSiteKey"]),
        ...mapState("condolences", [
            "successModalVisible",
            "message",
            "response"
        ]),
        condolencesArePrivate() {
            return parseInt(this.obituary.condolences_are_private);
        },
        maxCommentCountIsOverLimit(){

          return  (this.comment.length <= 5000) ? false : true;
        }
    },
    methods: {
        ...mapMutations("condolences", ["SET_SUCCESS_MODAL"]),
        ...mapActions("condolences", ["submitForm"]),
        onVerify(token, ekey) {
            this.verified = true;
            this.token = token;
            this.eKey = ekey;
        },
        onExpire() {
            this.verified = false;
            this.token = null;
            this.eKey = null;
            this.expired = true;
        },
        onError(err) {
            this.token = null;
            this.eKey = null;
            this.error = err;
        },
        onSubmit(submitEvent) {
            this.submitForm(submitEvent);
        },
        closeModal() {
            this.SET_SUCCESS_MODAL(false);
            this.comment = "";
            this.name = "";
            this.email = "";
            this.verified = false;
            this.expired = false;
            this.error = null;
            this.$refs.formEl.classList.remove("was-validated");
        }
    }
};
</script>

<style lang="scss" scoped>
.title {
    margin: 0;
    color: var(--dark-tan);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}
</style>
