<template>
    <button
        type="button"
        class="btn btn-primary m-0"
        @click.prevent="openModal"
    >
        Add Condolence
    </button>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "AddNewCondolenceButton",
    methods: {
        ...mapActions("condolence/addModifyCondolence", [
            "openModal",
            "closeModal"
        ])
    }
};
</script>
