<template>
    <div class="h-100">
        <ResetPasswordForm />
    </div>
</template>

<script>
    import ResetPasswordForm from './components/ResetPasswordForm.vue'

    export default {
        name: "ResetIndex",
        components: {
            ResetPasswordForm
        },
    }
</script>
