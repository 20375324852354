<template>
  <LoginForm />
</template>

<script>
import LoginForm from './components/LoginForm.vue';

export default {
  props: ['status'],
  components: {
    LoginForm,
  },
};
</script>