<template>
    <div class="obituary-list-item">
        <div class="obituary-wrapper">
            <div class="obituary-image"> <!-- eventually v-if this but I need it for testing -->
                <img :src="profileImg" :alt="obituary.profile_image_path_alt_text" />
            </div>
            <div class="obituary-details">
                <p class="name">{{ obituary.name }}</p>
                <p class="lifetime" v-if="lifetime">{{ lifetime }}</p>
                <div class="bio" v-html="obituary.content"></div>
                <a :href="getFormattedHref" class="read-more" :title="`Internal link to read ${obituary.name}'s full obituary`">Read full obituary <span class="fas fa-chevron-right"></span></a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
    name: "SiteObituaryListItem",
    props: [
        "id",
        "obituary"
    ],
    computed: {
        ...mapGetters('obituary', [
            'getFormattedDate'
        ]),
        getPrimaryService() {
            let primaryService = { location: 'TBD', date: 'N/A' }
            if (this.obituary.schedule_items.length) {
                let service = this.obituary.schedule_items.filter(item => item.name == 'Memorial Service')[0];

                if (service) {
                    primaryService['location'] = service.location_name;
                    primaryService['date'] = this.getFormattedDate(service.date);
                }
            }
            return primaryService;
        },
        getFormattedHref() {
            let formattedName = this.obituary.name;
            formattedName = formattedName.replace(/[^A-Za-z0-9\s\.\:\_\-]/g, '');
            formattedName = formattedName.replace(/[\.\:\_\-]/g, '');
            formattedName = formattedName.replace(/ {1,}/g, '-')
            return `/obituaries/${this.id}/${formattedName.toLowerCase()}`;
        },
        profileImg() {
            return this.obituary.profile_image_path || '/images/custom/site/pt-bg-default.jpg';
        },
        lifetime() {
            let validBirth = dayjs(this.obituary.date_of_birth).isValid(),
                validDeath = dayjs(this.obituary.date_of_death).isValid();

            if (!validBirth && !validDeath) return false;
            if (!validBirth) return `Died ${this.getFormattedDate(this.obituary.date_of_death)}`;
            if (!validDeath) return `Born ${this.getFormattedDate(this.obituary.date_of_birth)}`;
            return `${this.getFormattedDate(this.obituary.date_of_birth)} - ${this.getFormattedDate(this.obituary.date_of_death)}`
        }
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    @mixin fha($parent-width, $min, $max: 100%) {
        min-width: $min;
        max-width: $max;
        flex-basis: calc(#{$parent-width} * 999 - 100% * 999);
    }

    .obituary-list-item {
        font-size: 14px;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid hsl(0deg,0%,95%);
        margin-bottom: 1.5rem;

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
            margin-bottom: 0;
        }

        .obituary-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin: -.5rem;
        }

        .obituary-image {
            @include fha(500px, clamp(150px, 20%, 300px));
            margin: .5rem;
            z-index: 0;

            img {
                object-fit: cover;
                height: clamp(100px, 100vw, 100%) !important;
                width: 100%;
            }
        }

        .obituary-details {
            @include fha(500px, 50%);
            flex-grow: 1;
            margin: .5rem;

            .name {
                @include fluid-size(22px, 42px, font-size);
                font-family: 'Libre Caslon Display', serif;
                line-height: 1;
                color: var(--blue);
                border-bottom: 1px solid var(--yellow);
                margin: 0;
            }

            .lifetime-services {
                font-family: 'Roboto', sans-serif;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0;
            }

            .bio {
                max-height: 100px;
                position: relative;
                overflow: hidden;
                margin: 1rem 0;

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 50%;
                    width: 100%;
                    background-image: linear-gradient(to top, white, transparent);
                }
            }

            .read-more {
                font-weight: bold;
                font-size: 16px;
                text-decoration: none;
                margin: 0;
                display: flex;
                align-items: center;
                color: var(--blue);

                svg {
                    font-size: 14px;
                    margin-left: .5rem;
                }

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
</style>