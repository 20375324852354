<template>
    <div class="d-flex" style="min-height: 100%">
        <form
            id="forgot-form"
            class="m-0 d-flex flex-column justify-content-center"
            style="flex: 1 0 min(100%, 300px)"
            name="forgot-form"
            @submit.prevent="submitForgot"
            ref="formEl"
        >
            <div
                v-if="errorMessage"
                class="alert alert-danger form-errors"
                v-html="errorMessage"
            ></div>
            <div v-if="successMessage" class="alert alert-success">
                {{ successMessage }}
                <a
                    href="/login"
                    title="Internal link to return to the login page"
                    >Click here to return to the login page</a
                >
            </div>

            <p style="max-width: 60ch; font-size: 14px; font-weight: bold">
                Forgot your password? No problem. Just let us know your email
                address and we'll email you a password reset link that will
                allow you to choose a new one.
            </p>

            <input type="hidden" name="_token" :value="activeRecord._token" />

            <div class="form-group">
                <div>
                    <label class="form-label" for="forgot_password_email"
                        >Email<span class="required">*</span></label
                    >
                    <input
                        id="forgot_password_email"
                        class="form-control"
                        type="email"
                        name="email"
                        v-model="activeRecord.email"
                        required
                    />
                    <p class="invalid-feedback">
                        Please enter a valid email address.
                    </p>
                </div>
            </div>

            <div
                class="wrap-gap justify-content-between align-items-center"
                style="--gap: 1rem"
            >
                <a
                    class="secondary-action"
                    style="flex-grow: 9999"
                    title="Returns to the login page"
                    >Go Back</a
                >
                <button
                    class="btn btn-primary flex-grow-1"
                    :disabled="isFormProcessing"
                >
                    {{ isFormProcessing ? "Processing..." : "Apply" }}
                </button>
            </div>
        </form>

        <div
            class="flex-grow-1 d-md-block d-none register-img"
            style="flex: 1 0 min(100%, 300px)"
        >
            <img
                src="/images/custom/site/login-reg-flower.jpg"
                alt="Decorative image of flowers"
            />
        </div>
    </div>
</template>

<script>
import VueInput from "@v/Core/Global/Components/VueForms/VueInput.vue";
import { mapState, mapActions } from "vuex";

export default {
    name: "ForgotForm",
    components: {
        VueInput,
    },
    computed: {
        ...mapState("forgot", [
            "activeRecord",
            "isFormProcessing",
            "errorMessage",
            "successMessage",
        ]),
    },
    methods: {
        ...mapActions("forgot", [
            "submitForm",
            "closeModal",
            "openModal",
            "forgot",
        ]),
        submitForgot() {
            var formId = "#forgot-form";
            var path = "/forgot-password";
            this.forgot(formId, path);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

form {
    @include fluid-size(40px, 80px, padding);
}

.title {
    margin: 0;
    color: var(--dark-tan);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}

.secondary-action {
    text-decoration: underline !important;
    font-size: 12px;
    cursor: pointer;
}

.register-img {
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
</style>
