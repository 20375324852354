<template>
  <!-- TODO: This needs to be hooked up -->
  <div class="progress-section container">
    <h1 class="fr-title">Locke Funeral Services Planning</h1>
    <div class="fr-progress" data-progress="1" style="--stop-count: 9" :data-next-enabled="nextEnabled">
      <div class="fr-bar"></div>

      <div
        v-for="(stop, index) in stops"
        :key="`stop_${index}`"
        :class="['fr-stop', `fr-${wrapperClass(index)}`]"
      >
        <router-link
          v-bind="stopBinder(stop, index)"
        >{{ stop.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FormSteps',
  data() {
    return {
      stops: [
        { path: 'AccountVerification', name: 'Account Verification' },
        { path: 'LockeAssistance', name: 'Locke Assistance' },
        { path: 'ServiceType', name: 'Service Options' },
        { path: 'PackageOptions', name: 'Package Options' },
        { path: 'PurchaseOptions', name: 'Purchase Options' },
        { path: 'OtherOptions', name: 'Additonal Purchase Options' },
        { path: 'ReviewCart', name: 'Review Cart' },
        { path: 'Completion', name: 'Completion' },
      ],
    };
  },
  computed: {
    ...mapState('cart/addModifyCart', [
      'activeRecord',
      'user',
    ]),
    routeName() {
      return this.$route.name;
    },
    hasUser() {
      return this.user ? !!Object.keys(this.user) : false;
    },
    nextEnabled() {
      switch (this.routeName) {
        case 'LockeAssistance': return !!Object.keys(this.activeRecord.timeOfNeed).length;
        case 'ServiceType': return !!Object.keys(this.activeRecord.serviceType).length;
        case 'PackageOptions': return !!Object.keys(this.activeRecord.packageOption).length;
        case 'AccountVerification':
        case 'ReviewCart':
          return this.hasUser;
        default: return true;
      }
    },
    currentIndex() {
      return this.stops.findIndex((stop) => stop.path === this.routeName);
    },
  },
  methods: {
    wrapperClass(index) {
      if (this.currentIndex === index) return 'active';
      if (this.currentIndex > index) return 'before';
      if (this.currentIndex < index) return 'after';
    },
    nextOrNotFirst(index) {
      return this.nextEnabled || !index;
    },
    stopBinder(stop, index) {
      return {
        class: ['step-link', index % 2 === 0 ? 'even' : 'odd'],
        to: { name: stop.path },
        'active-class': 'current',
        exact: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../sass/vue-import";

.progress-section {
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-link {
  color: var(--tertiary);
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  text-transform: uppercase;
  font-weight: 500;
  @include fluid-size(10px, 14px, font-size);
  position: absolute;
  transform: translate(-25%, -10%);

  @include medium {
    position: relative;
    transform: translate(0);
  }

  &.odd {
    top: -4rem;

    @include medium {
      top: 0;
    }
  }

  &.even {
    top: 0;
  }
}

.fr-title {
  @include fluid-size(45px, 60px, font-size);
  color: var(--blue);
  text-align: center;
  margin: 3rem 0;
  font-size: 60px;
}

// Progress Bar Styles
.fr-progress {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
  height: 0px;
  margin-bottom: 0rem;
  opacity: 0;
  transition: 200ms ease-out;

  &:not(.fr-hidden) {
    opacity: 1;
    height: 10px;
    margin-bottom: 1rem;
  }

  &[data-next-enabled] .fr-stop.fr-active + .fr-stop {
    background-color: white;
    pointer-events: all;
    cursor: pointer;
  }

  .fr-stop {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: white;
    border: solid 1px var(--dark-tan);
    border-radius: 50rem;
    transition: 200ms ease-out;
    pointer-events: none;

    &.fr-after {
      background-color: var(--light-gray);
    }

    &.fr-active {
      background-color: var(--dark-tan);

      >.step-link {
        color: var(--primary);
      }
    }

    &.fr-before {
      cursor: pointer;
      background-color: var(--tan);
      pointer-events: all;
    }
  }

  // This may need to be raised if they ever go above 20 steps
  @for $i from 1 through 20 {
    &[data-progress="#{$i}"] {
      .fr-bar:after {
        width: calc(((#{$i} - 1) / (var(--stop-count) - 1)) * 100%);
      }
    }
  }

  .fr-bar {
    position: absolute;
    background-color: var(--tan);
    height: 4px;
    width: calc(100% - 4px);
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);

    &:after {
      position: absolute;
      content: "";
      height: 100%;
      left: 0;
      top: 0;
      background-color: var(--blue-500);
      transition: 200ms ease-out;
    }
  }
}
</style>
