<template>
    <div class="cart">
        <h2 class="text-center text-white bg-primary p-4">Review Cart</h2>
        <table class="table-responsive p-2 p-md-4">
            <thead>
                <tr>
                <th scope="col" class="cart-header h4">Preview</th>
                <th scope="col" class="cart-header h4">Category</th>
                <th scope="col" class="cart-header h4">Item</th>
                <th scope="col" class="cart-header h4">Description</th>
                <th scope="col" class="cart-header h4">Has Tax</th>
                <th scope="col" class="cart-header h4">Tax</th>
                <th scope="col" class="cart-header h4">Price</th>
                </tr>
            </thead>

            <tr v-if="Object.keys(activeRecord.timeOfNeed).length">
                <td data-label="Preview">
                <img
                    class="preview"
                    src="/images/custom/site/Placeholder-Thumbnail.png"
                    alt="Placeholder image frank update with dynamic images"
                />
                </td>
                <td data-label="Category">Need</td>
                <td data-label="Item">{{ activeRecord.timeOfNeed.need }}</td>
                <!-- TODO: add dynamic text -->
                <td data-label="Description">--</td>
                <td data-label="Has Tax">--</td>
                <td data-label="Tax">--</td>
                <td data-label="Price">--</td>
            </tr>

            <tr v-if="Object.keys(activeRecord.serviceType).length">
                <td data-label="Preview">
                    <img
                        class="preview"
                        src="/images/custom/site/Placeholder-Thumbnail.png"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Service Type</td>
                <td data-label="Item">{{ activeRecord.serviceType.serviceType }}</td>
                <td data-label="Description">--</td>
                <td data-label="Has Tax">--</td>
                <td data-label="Tax">--</td>
                <td data-label="Price">--</td>
            </tr>

            <tr v-if="Object.keys(activeRecord.packageOption).length">
                <td data-label="Preview">
                    <img
                        class="preview"
                        :src="activeRecord.packageOption.image || '/images/custom/site/Placeholder-Thumbnail.png'"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Package Option</td>
                <td data-label="Item">{{ activeRecord.packageOption.ep_name }}</td>
                <td data-label="Description" class="flex-column text-left align-items-start">
                <span v-html="activeRecord.packageOption.ep_description"></span>
                </td>
                <td data-label="Has Tax">
                    {{(activeRecord.packageOption.ep_taxable  === 1) ? '.07': '--'}}
                </td>
                <td data-label="Tax" >
                    {{ activeRecord.packageOption.ep_taxable  === 1 ? (activeRecord.packageOption.ep_store_price * .07).toFixed(2) : '--' }}
                </td>
                <td data-label="Price">
                    {{ activeRecord.packageOption.ep_store_price }}
                </td>
            </tr>

            <tr v-if="Object.keys(activeRecord.casketOption).length">
                <td data-label="Preview">
                    <img
                        class="preview"
                        :src="activeRecord.casketOption.image || '/images/custom/site/Placeholder-Thumbnail.png'"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Casket Option</td>
                <td data-label="Item">{{ activeRecord.casketOption.ep_name }}</td>
                <td data-label="Description" class="flex-column text-left align-items-start">
                <span v-html="activeRecord.casketOption.ep_description"></span>
                </td>
                <td data-label="Has Tax">
                    {{ (activeRecord.casketOption.ep_taxable  === 1) ? '.07' : '--' }}
                </td>
                <td data-label="Tax">
                    {{ activeRecord.casketOption.ep_taxable  === 1 ? (activeRecord.casketOption.ep_store_price * .07).toFixed(2) : '--' }}
                </td>
                <td data-label="Price">
                    {{ activeRecord.casketOption.ep_store_price }}
                </td>
            </tr>

            <tr v-if="Object.keys(activeRecord.urnOption).length">
                <td data-label="Preview">
                    <img
                        class="preview"
                        :src="activeRecord.urnOption.image || '/images/custom/site/Placeholder-Thumbnail.png'"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Urn Option</td>
                <td data-label="Item">{{ activeRecord.urnOption.ep_name }}</td>
                <td data-label="Description" class="flex-column text-left align-items-start">
                <span v-html="activeRecord.urnOption.ep_description"></span>
                </td>
                <td data-label="Has Tax">
                    {{ (activeRecord.urnOption.ep_taxable  === 1) ? '.07': '--' }}
                </td>
                <td data-label="Tax">
                    {{ activeRecord.urnOption.ep_taxable  === 1 ? (activeRecord.urnOption.ep_store_price * .07).toFixed(2) : '--' }}
                </td>
                <td data-label="Price">
                    {{ activeRecord.urnOption.ep_store_price }}
                </td>
            </tr>

            <tr v-if="Object.keys(activeRecord.vault).length">
                <td data-label="Preview">
                    <img
                        class="preview"
                        :src="activeRecord.vault.image || '/images/custom/site/Placeholder-Thumbnail.png'"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Vault</td>
                <td data-label="Item">{{ activeRecord.vault.ep_name }}</td>
                <td data-label="Description" class="flex-column text-left align-items-start">
                    <span v-html="activeRecord.vault.ep_description"></span>
                </td>
                <td data-label="Has Tax">
                    {{ (activeRecord.vault.ep_taxable === 1) ? '.07': '--' }}
                </td>
                <td data-label="Tax">
                    {{ activeRecord.vault.ep_taxable  === 1 ? (activeRecord.vault.ep_store_price * .07).toFixed(2) : '--' }}
                </td>
                <td data-label="Price">
                    {{ activeRecord.vault.ep_store_price }}
                </td>
            </tr>

            <tr v-if="Object.keys(activeRecord.restingPlace).length">
                <td data-label="Preview">
                    <img
                        class="preview"
                        :src="activeRecord.restingPlace.image || '/images/custom/site/Placeholder-Thumbnail.png'"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Final Resting Option</td>
                <td data-label="Item">{{ activeRecord.restingPlace.ep_name }}</td>
                <td data-label="Description" class="flex-column text-left align-items-start">
                    <span v-html="activeRecord.restingPlace.ep_description"></span>
                </td>
                <td data-label="Has Tax">
                    {{ (activeRecord.restingPlace.ep_taxable  === 1) ? '.07': '--' }}
                </td>
                <td data-label="Tax">
                    {{ activeRecord.vault.restingPlace ? (activeRecord.restingPlace.ep_store_price * .07).toFixed(2) : '--' }}
                </td>
                <td data-label="Price">
                    {{ activeRecord.restingPlace.ep_store_price }}
                </td>
            </tr>

            <tr v-if="Object.keys(activeRecord.remainsContainer).length">
                <td data-label="Preview">
                    <img
                        class="preview"
                        :src="activeRecord.restingPlace.image || '/images/custom/site/Placeholder-Thumbnail.png'"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Remains</td>
                <td data-label="Item">{{ activeRecord.remainsContainer.ep_name }}</td>
                <td data-label="Description" class="flex-column text-left align-items-start">
                    <span v-html="activeRecord.remainsContainer.ep_description"></span>
                </td>
                <td data-label="Has Tax">
                    {{ (activeRecord.remainsContainer.ep_taxable  === 1) ? '.07': '--' }}
                </td>
                <td data-label="Tax">
                    {{ activeRecord.remainsContainer.ep_taxable  === 1 ? (activeRecord.remainsContainer.ep_store_price * .07).toFixed(2) : '--' }}
                </td>
                <td data-label="Price">
                    {{ activeRecord.remainsContainer.ep_store_price }}
                </td>
            </tr>

            <tr v-if="Object.keys(activeRecord.deathCert).length">
                <td data-label="Preview">
                    <img
                        class="preview"
                        :src="activeRecord.restingPlace.image || '/images/custom/site/Placeholder-Thumbnail.png'"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Death Certificate</td>
                <td data-label="Item">{{ activeRecord.deathCert.ep_name }}</td>
                <td data-label="Description" class="flex-column text-left align-items-start">
                <span v-html="activeRecord.deathCert.ep_description"></span>
                </td>
                <td data-label="Has Tax">
                    {{ (activeRecord.deathCert.ep_taxable  === 1) ? '.07': '--' }}
                </td>
                <td data-label="Tax">
                    {{ activeRecord.deathCert.ep_taxable  === 1 ? (activeRecord.deathCert.ep_store_price * .07).toFixed(2) : '--' }}
                </td>
                <td data-label="Price">
                    {{ activeRecord.deathCert.ep_store_price }}
                </td>
            </tr>

            <tr
                v-for="(item,index) in activeRecord.other"
                :key="index"
            >
                <td data-label="Preview">
                    <img
                        class="preview"
                        :src="item.image || '/images/custom/site/Placeholder-Thumbnail.png'"
                        alt="Placeholder image frank update with dynamic images"
                    />
                </td>
                <td data-label="Category">Other Purchase Options</td>
                <td data-label="Item">{{ item.ep_name }}</td>
                <td data-label="Description" class="flex-column text-left align-items-start">
                    <span v-html="item.ep_description"></span>
                </td>
                <td data-label="Has Tax">
                    {{ (item.ep_taxable  === 1 && item.ep_store_price > 0.01) ? '.07': '--' }}
                </td>
                <td data-label="Tax">
                    {{ item.ep_taxable  === 1 && item.ep_store_price > 0.01 ? (item.ep_store_price * .07).toFixed(2) : '--' }}
                </td>
                <td data-label="Price">{{ (item.ep_store_price > 0.01) ? item.ep_store_price : 'Further Discussion Necessary' }}</td>
            </tr>

            <tr>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals" colspan="4">
                    <p>
                        <span class="total-titles">Subtotal:</span> {{ subTotal }}
                    </p>
                </td>
            </tr>

            <tr>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals" colspan="4">
                    <p>
                        <span class="total-titles">Tax:</span> {{ tax }}
                    </p>
                </td>
            </tr>

            <tr>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals d-none d-md-flex"></td>
                <td class="totals" colspan="4">
                    <p>
                        <span class="total-titles">Total:</span> {{ total }}
                    </p>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('cart/addModifyCart', [
            'activeRecord',
            'subTotal',
            'tax',
            'total',
        ]),
    },
};
</script>

<style lang="scss">
    @media print {
        .nav,
        .progress-section,
        .cremation-main .content + div,
        footer {
            display: none !important;
        }
    }
</style>

<style lang="scss" scoped>
.cart {
    width: 100%;
    box-shadow: 0 1px 10px 3px #c4d3e0;

    .cart-header {
        margin: 2rem 1rem;
    }

    .preview {
        width: 100px;
        height: 100px;
        margin: 1rem 0;
        text-align: center;

        @media print {
            height: 48px;
            width: 48px;
            margin: .25rem;
        }
    }

    .totals {
        font-size: 16px;
        border-bottom: none;

        p {
            display: flex;
            justify-content: space-between;
        }

        &[colspan] {
            display: block;
            width: 30%;
        }

        .total-titles {
            font-size: 20px;
            font-weight: 600;
            padding: 0 2rem;
        }
    }
}
</style>
