<template>
  <FuneralBioDynamicSection
    name="Family / Friends"
    property="family_info"
    :default-obj="defaultObj"
    :section="section"
    sub-title="List immediate family and relatives. Also, other relatives to be mentioned."
    v-slot="slotProps"
  >
    <div class="input-grid">
      <VueInput name="Name" v-model="slotProps.item.name" />
      <VueSelect
        name="Relationship"
        v-model="slotProps.item.relationship"
        :options="relationshipOptions"
      />
      <VueInput name="Address" v-model="slotProps.item.address" />
      <VueInput type="tel" name="Phone" v-model="slotProps.item.phone" />
      <VueInput type="email" name="Email" v-model="slotProps.item.email" />
      <VueCheckbox name="Deceased?" v-model="slotProps.item.deceased" />
    </div>
  </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentFamily',
  props: ['section'],
  data() {
    return {
      defaultObj: {
        name: '',
        relationship: '',
        address: '',
        phone: '',
        email: '',
        deceased: '',
      },
      relationshipOptions: [
        { name: 'Spouse', value: 'spouse' },
        { name: 'Child', value: 'child' },
        { name: 'Grandchild', value: 'grandchild' },
        { name: 'Father', value: 'father' },
        { name: 'Mother', value: 'mother' },
        { name: 'Grandmother', value: 'grandmother' },
        { name: 'Grandfather', value: 'grandfather' },
        { name: 'Other', value: 'other' },
      ],
    };
  },
};
</script>
