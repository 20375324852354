<template>
	<form
		id="add-modify-event-form"
		class="event-form needs-validation"
		name="add-modify-event-form"
		novalidate
		@submit.prevent="submitForm"
	>
		<div class="form-errors"></div>
		<input
			type="hidden"
			name="_token"
			:value="activeRecord._token"
		/>
		<input
			type="hidden"
			name="id"
			value="activeRecord.id"
			v-if="!isActionAdd"
		/>
		<div class="d-flex flex-column justify-content-between align-items-stretch h-100">
			<div class="document-form-fields d-flex flex-column align-items-stretch">
				<h5>{{activeRecord.decedent}}</h5>
				<div class="form-group has-label"  v-if="!activeRecord.schedule_id">
					<div class="d-flex justify-content-between align-items-center">
						<label
							class="required"
							for="full_name"
						>Decedent</label>
					</div>
					<select
						class="form-select-input"
						v-model="activeRecord.schedule_id"
						required
						name="schedule_id"
					>
						<option
							v-for="(schedule, index) in schedules"
							:key="index"
							:id="`schedule${schedule.name}`"
							:value="schedule.id"
						>{{schedule.name}}</option>
					</select>
					<div class="invalid-feedback">
						Please select a Name.
					</div>
				</div>
				<div class="form-group has-label">
					<div class="d-flex justify-content-between align-items-center">
						<label
							class="required"
							for="full_name"
						>Name</label>
					</div>
					<input
						type="text"
						class="form-control"
						required
						name="name"
						id="name"
						title="Please enter name"
						autocomplete="off"
						data-invalid-feedback="Please enter a event name."
						v-model="activeRecord.name"
					/>
					<div class="invalid-feedback">
						Please enter a name.
					</div>
				</div>
				<div class="form-group has-label">
					<div class="d-flex justify-content-between align-items-center">
						<label
							class="required"
							for="full_name"
						>Location Name</label>
					</div>
					<input
						type="text"
						class="form-control"
						required
						name="location_name"
						id="location_name"
						title="Please enter location_name"
						autocomplete="off"
						data-invalid-feedback="Please enter a event location_name."
						v-model="activeRecord.location_name"
					/>
					<div class="invalid-feedback">
						Please enter a location name.
					</div>
				</div>
				<div class="form-group position-relative has-label m-2">
					<div class="d-flex justify-content-between align-items-center">
						<label for="show-end-time"> Show Day Of Week?</label>
						<input
							class="form-check-input"
							type="checkbox"
							name="show-day"
							id="show-day"
							v-model="activeRecord.show_day_of_week"
                            v-bind:true-value="1"
                            v-bind:false-value="0"
							:checked-value="true"
						/>
					</div>
				</div>
				<div class="form-group has-label">
					<div class="d-flex justify-content-between align-items-center">
						<label for="date">Date</label>
					</div>
					<input
						type="date"
						class="form-control"
						name="date"
						id="date"
						title="Please enter date"
						autocomplete="off"
						v-model="activeRecord.date"
						data-invalid-feedback="Please enter date."
					/>
					<div class="invalid-feedback">
						Please enter date.
					</div>
				</div>

			</div>
		</div>
		<div class="form-group position-relative has-label m-2">
			<div class="d-flex justify-content-between align-items-center">
				<label for="show-start-time"> Show Start Time?</label>
				<input
					class="form-check-input"
					type="checkbox"
					name="show-start-time"
					id="show-start-time"
					v-model="showStartTime"
					:checked-value="true"
					@change="updateStartTime()"
				/>

			</div>
		</div>
		<div
			class="form-group has-label"
			v-if="showStartTime"
		>
			<div class="d-flex justify-content-between align-items-center">
				<label for="time">Start Time </label>
			</div>
			<input
				type="time"
				class="form-control"
				name="start_time"
				id="start_time"
				title="Please enter start time"
				autocomplete="off"
				v-model="activeRecord.start_time"
				data-invalid-feedback="Please enter start time."
			/>
			<div class="invalid-feedback">
				Please enter time.
			</div>
		</div>
		<div class="form-group position-relative has-label m-2">
			<div class="d-flex justify-content-between align-items-center">
				<label for="show-end-time"> Show End Time?</label>
				<input
					class="form-check-input"
					type="checkbox"
					name="show-end-time"
					id="show-emd-time"
					v-model="showEndTime"
					:checked-value="true"
					@change="updateEndTime()"
				/>

			</div>
		</div>
		<div
			class="form-group has-label"
			v-if="showEndTime"
		>
			<div class="d-flex justify-content-between align-items-center">
				<label for="time">End Time</label>
			</div>
			<input
				type="time"
				class="form-control"
				name="end_time"
				id="end_time"
				title="Please enter end_time"
				autocomplete="off"
				v-model="activeRecord.end_time"
				data-invalid-feedback="Please enter start time."
			/>
			<div class="invalid-feedback">
				Please enter time.
			</div>
		</div>
		<div class="row">
			<div class="col form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="address1">Address</label>
				<input
					type="text"
					id="address1"
					name="address1"
					class="form-control"
					title="Please enter the address"
					v-model="activeRecord.location_address.address1"
					data-invalid-feedback="Please provide an address"
				/>
				<div class="invalid-feedback">
					Please provide an address
				</div>
			</div>
		</div>
		</div>
		<div class="row">
			<div class="col form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="city">City</label>
				</div>
				<input
					id="city"
					name="city"
					type="text"
					class="form-control"
					title="Please enter the city"
					v-model="activeRecord.location_address.city"
					data-invalid-feedback="Please provide a city"
				/>
				<div class="invalid-feedback">
					Please provide a city
				</div>
			</div>
			<div class="col form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="state">State</label>
				</div>
				<select
					id="state"
					name="state"
					type="text"
					class="form-control"
					title="Please enter the state"
					v-model="activeRecord.location_address.state"
					data-invalid-feedback="Please provide a state"
				>
					<option
						:key="index"
						:value="option.value"
						v-for="(option, index) in options"
					>
						{{ option.name }}
					</option>
				</select>
				<div class="invalid-feedback">
					Please provide a state
				</div>
			</div>
			<div class="col form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="postal_code">Zip</label>
				</div>
				<input
					type="text"
					id="postal_code"
					name="postal_code"
					class="form-control"
					title="Please enter the postal code"
					v-model="activeRecord.location_address.zip"
					data-invalid-feedback="Please provide a postal code"
				/>
				<div class="invalid-feedback">
					Please provide a postal code
				</div>
			</div>
		</div>
	</form>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
	name: "AddModifyEventForm",
	props: ["isActionAdd","location","schedule_id"],
	data() {
		return {
			showStartTime: true,
			showEndTime: true,
			decedent:'',
		};
	},
	methods: {
		updateStartTime() {
			if (this.showStartTime == false)
				this.activeRecord.start_time = '';
		},
		updateEndTime() {
			if (this.showEndTime == false) this.activeRecord.end_time = '';
		},
		...mapActions("schedule/addModifySchedule", ["setSched"]),
	},
	computed: {
			getHeader() {
			let action = this.isActionAdd ? "Add a New " : "Modify ";
			return action + "Event";
		},
		setDecedent(){
			if(this.obitActiveRecord && this.isActionAdd){
			    this.setSched(this.schedule_id);
			}
		},
		...mapState("schedule/list", {
			schedules: (state) => state.sched,
			cspNonce: (state) => state.cspNonce,
		}),
		...mapState("schedule/addModifySchedule", {
			activeRecord: (state) => state.activeRecord,
			isFormProcessing: (state) => state.isFormProcessing,
			options: (state) => state.states,
		}),
		...mapState("obituary/addModifyObituary", {
     		 obitActiveRecord: (state) => state.activeRecord
   		 }),

	},
};
</script>
