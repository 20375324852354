<template>
    <div id="obituary-module">
        <obituary-secondary-nav />
        <router-view />
    </div>
</template>

<script>

    export default {
        name: "ModuleIndex",
    };
</script>

<style lang="scss" scoped>

    @import '../../../../../../sass/_vue-import.scss';
    #obituary-module {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        &::v-deep {
            .datatable-section {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow: auto;

                input[type="search"] { margin: 0 !important; }
            }
        }
    }
</style>