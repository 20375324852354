<template>
    <div class="obituary-layout">
        <div class="obituary-left">
            <div class="obituary-filters">
                <vue-search name="Search for a loved one" v-model="searchModel" />
                <p class="mt-1 mb-0 font-weight-bold">Search date range</p>
                <div class="bottom">
                    <div class="date-range">
                        <vue-date name="From" v-model="dateFrom" />
                        <vue-date name="To" v-model="dateTo" />
                    </div>
                    <div class="range-options">
                        <vue-radios
                            name="Date range options"
                            label=""
                            v-model="rangeOption"
                            :options="[{name:'Search birth date',value:'birth'},{name:'Search death date',value:'death'}]"
                        />
                    </div>
                </div>
            </div>

            <template v-if="!isLoading">
                <site-obituary-list-item
                    v-for="obituary in paginatedList"
                    :key="'post_' + obituary.id"
                    :id="obituary.id"
                    :obituary="obituary"
                />

                <pagination
                    :active-page="activePage"
                    :item-count="totalItems"
                    :items-per-page="itemsPerPage"
                    @on-update="setPage($event)"
                />
            </template>
            <VueLoader v-else />
        </div>
        <SiteObituarySidebar />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    name:'SiteObituaryList',
    props: ['obituaries'],
    data() {
        return {
            searchModel: '',
            dateFrom: '',
            dateTo: '',
            rangeOption: 'birth',
        }
    },
    watch: {
        searchModel(n,o) {
            this.SET(['searchModel', n]);
        },
        dateFrom(n,o) {
            this.SET(['dateFrom', n]);
        },
        dateTo(n,o) {
            this.SET(['dateTo', n]);
        },
        rangeOption(n,o) {
            this.SET(['activeFilterType', n]);
        },
        totalItems(n) {
            let maxPage = Math.ceil(n/this.itemsPerPage)
            // if above maxPage range (on page 10 but only 6 pages) move to new maxPage
            if ((maxPage < this.activePage)) this.SET(['activePage', maxPage]);
            // if coming out of search / filter with no results and under page range (on page 0 while maxPage is 1) move to page 1
            else if ((maxPage && !this.activePage)) this.SET(['activePage', 1]);
        }
    },
    computed: {
        ...mapState('obituary', [
            'activePage',
            'itemsPerPage',
            'isLoading',
        ]),
        ...mapGetters('obituary', [
            'totalItems',
            'paginatedList',
        ])
    },
    methods: {
        ...mapActions('obituary', [
            'setPage',
            'setObituaries',
        ]),
        ...mapMutations('obituary', [
            'SET'
        ])
    },
    mounted() {
        this.setObituaries(this.obituaries);
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    .obituary-filters {
        padding: 1rem;
        background-color: var(--light);
        margin-bottom: 2rem;
        border-radius: 25px;

        .bottom {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -.5rem -.5rem;

            .date-range {
                display: flex;
                flex-wrap: wrap;

                &::v-deep .form-group {
                    margin: .5rem;
                    flex: 1 0 calc(250px - 1rem);
                    max-width: calc(100% - 1rem);
                }
            }

            .date-range,
            .range-options {
                @include desktop { flex: 1 0 250px; }
                @include not-desktop { flex: 1 0 100%; }
            }

            .range-options {
                &::v-deep > div {
                    margin: .5rem;

                    .form-group {
                        margin: 0;
                    }
                }
            }
        }
    }
</style>