<template>
    <vue-modal
        :visible="isModalOpen"
        @on-close="closeFuneralAddModifyModal"
        id="funeral-add-modify-modal"
    >
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-event mr-1 text-tertiary"></span>&nbsp;{{
                    getModalHeader
                }}
            </h5>
        </template>

        <div class="page-settings-details pb-3 pt-0" v-if="!isActionAdd">
            <p class="psd-published">
                Funeral Name: <span>{{ activeRecord.name }}</span>
            </p>
            <p class="psd-published">
                Date Added: <span>{{ activeRecord.created_at }}</span>
            </p>
            <p class="psd-modified">
                Last Modified: <span>{{ activeRecord.updated_at }}</span>
            </p>
            <p class="psd-status" v-if="activeRecord.is_active">
                Status: <span>Active</span>
            </p>
            <p class="psd-status" v-else>
                Status: <span>Inactive</span>
            </p>
        </div>

        <!-- Form Include -->
        <add-modify-funeral-form
            :class="{ 'd-none': isFormProcessing }"
            :is-action-add="isActionAdd"
            :active-record="activeRecord"
            @submitForm="submitForm"
            @closeModal="closeFuneralAddModifyModal"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-outline-primary cancel-button"
                    @click.prevent="closeFuneralAddModifyModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-primary ml-1"
                    :disabled="isFormProcessing"
                    form="add-modify-event-form"
                    @click.prevent="submitForm"
                >
                    {{ isFormProcessing ? "Processing..." : "Apply" }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "AddModifyEventModal",
    computed: {
        ...mapState("funeral/addModify", {
            isModalOpen: (state) => state.isModalOpen,
            activeRecord: (state) => state.activeRecord,
            isActionAdd: (state) => state.formActionIsAdd,
            isFormProcessing: (state) => state.isFormProcessing,
        }),
        getModalHeader() {
            let action = this.isActionAdd ? "Add a New " : "Modify ";
            return action + "Funeral";
        },
    },
    methods: {
        ...mapActions("funeral/addModify", [
            "openModal",
            "closeModal",
            "resetFuneral",
            "submitForm",
        ]),
        closeFuneralAddModifyModal() {
            this.closeModal();
            this.resetFuneral();
        },
    },

};
</script>
