<template>
  <div class="biography-form-sections">
    <DecedentBiographical
      section="Biographical Info"
      v-show="activeView === 'Biographical Info'"
    />
    <DecedentFamily
      section="Family Info"
      v-show="activeView === 'Family Info'"
    />
    <DecedentEducation
      section="Education Info"
      v-show="activeView === 'Education Info'"
    />
    <DecedentOccupations
      section="Occupational Info"
      v-show="activeView === 'Occupational Info'"
    />
    <DecedentOrganizations
      section="Organizational Involvement"
      v-show="activeView === 'Organizational Involvement'"
    />
    <DecedentHobbies
      section="Hobbies and Interests"
      v-show="activeView === 'Hobbies and Interests'"
    />
    <DecedentChurches
      section="Church Involvement"
      v-show="activeView === 'Church Involvement'"
    />
    <DecedentService
      section="Service Info"
      v-show="activeView === 'Service Info'"
    />
    <DecedentFinancial
      section="Financial Info"
      v-show="activeView === 'Financial Info'"
    />
    <DecedentVeteranService
      section="Veteran Info"
      v-show="activeView === 'Veteran Info'"
    />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

export default {
  data() {
    return {
      view: '',
    };
  },
  computed: {
    ...mapGetters('siteFuneral/addModify', ['getEducation']),
    ...mapState('siteFuneral/addModify', [
      'activeRecord',
      'activeView',
    ]),
  },
  mounted() {
    if (this.vp) {
      this.$nextTick(function() {
        this.activeRecord.funeral_bio.biography_info_fe = {}
        this.populateFields()
      }) 
    }
  },
  methods: {
    ...mapActions('siteFuneral/addModify', ['addObj']),
    ...mapMutations('siteFuneral/addModify', ['ADD_OBJ_TO_ACTIVE_RECORD', 'DELETE_OBJ_FROM_ACTIVE_RECORD']),
    populateFields() {
      let feStructure = {
        title: '',
        gender: '',
        suffix: '',
        known: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        birth_date: '',
        address: '',
        city: '',
        state: 'AL',
        zip: '',
        phone: '',
        inside_city_limits: '',
        formerly_of: '',
        birth_city: '',
        birth_state: '',
        birth_country: '',
        citizen_of: '',
        sex: '',
        race: '',
        father_name: '',
        mother_name: '',
        education_level: '',
        occupation: '',
        us_armed_forces: '',
        dd_214: '',
        nok: '',
        nok_address: '',
        nok_city: '',
        nok_state: '',
        nok_zip: '',
        nok_phone: '',
        nok_email: '',
        estate_executor: '',
        executor_address: '',
        executor_city: '',
        executor_state: '',
        executor_zip: '',
        executor_phone: '',
        executor_email: '',
        cemetery: '',
        church_affiliation: '',
        hispanic_origin: '',
        hispanic_origin_specify: '',
      }

      let feHasBioObject = this.activeRecord?.funeral_bio?.biography_info_fe;
      let feBioObjectHasKeys = feHasBioObject && Object.keys(feHasBioObject).length
      let hasBioObject = this.activeRecord?.funeral_bio?.biography_info;
      let bioObjectHasKeys = hasBioObject && Object.keys(hasBioObject).length

      if (!feBioObjectHasKeys) {
        if (bioObjectHasKeys) {
          this.activeRecord.funeral_bio = Object.assign(this.activeRecord.funeral_bio, {biography_info_fe: Object.assign({...feStructure}, {...this.activeRecord.funeral_bio.biography_info})});
        } else {
          this.activeRecord.funeral_bio = Object.assign(this.activeRecord.funeral_bio, {biography_info_fe: {...feStructure}});
        }
      }
    }
  },
};
</script>

<style lang="scss">
  @media print {
    body {
      grid-template-columns: 0px 100%;
    }

    .sidebar-nav,
    #vpanel-vue-header,
    .vp-tab-nav,
    .bio-form .top-bar,
    #add-modify-funeral-form button {
      display: none !important;
    }

    .form-control {
      border-width: 1px;
      border-color: #ced4da;
    }
  }

  .vp-tab-nav + .bio-form {
    padding: 1rem;

    h4 {
      margin-top: 0;
    }

    .dynamic-sections {
      margin: 0;

      > *:nth-child(1) {
        margin: 0;
      }
    }
  }
</style>
