<template>
	<form
		id="add-modify-funeral-form"
		class="funeral-form needs-validation"
		name="add-modify-funeral-form"
		novalidate
		@submit.prevent="submitForm"
	>
       <div class="form-errors" v-if="errorMessage" v-html="errorMessage"></div>

        <template v-if="activeRecord">
            <input type="hidden" name="_token" :value="activeRecord._token" />
            <input type="hidden" name="user_id" :value="userData.id" />
            <input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />

            <template v-if="activeRecord.funeral_bio">
                <div class="form-group">
                    <label class="m-0" for="firstname">First Name <span class="required">*</span></label>
                    <input
                        type="text"
                        class="form-control"
                        name="firstname"
                        id="firstname"
                        v-model="activeRecord.funeral_bio.first_name"
                        title="Enter a first name"
                        required
                    />
                    <p class="invalid-feedback">Please enter a first name.</p>
                </div>

                <div class="form-group">
                    <label class="m-0" for="lastname">Last Name <span class="required">*</span></label>
                    <input
                        type="text"
                        class="form-control"
                        name="lastname"
                        id="lastname"
                        v-model="activeRecord.funeral_bio.last_name"
                        title="Enter a last name"
                        required
                    />
                    <p class="invalid-feedback">Please enter a last Name.</p>
                </div>
            </template>

            <div class="form-group flex-column">
                <label class="m-0" for="branch">Branch <span class="required">*</span></label>
                <select
                    id="branch"
                    name="branch"
                    v-model="activeRecord.branch"
                    class="form-control"
                    title="Please select branch."
                    required
                >
                    <option value="" selected hidden>Select a branch...</option>
                    <option
                        v-for="(branch, index) in branches"
                        :key="index"
                        :value="branch.uuid"
                    >{{ branch.name }}</option>
                </select>
                <p class="invalid-feedback">Please select a branch.</p>
            </div>

            <div class="form-group flex-grow-1">
                <p class="m-0">Is funeral active? <span class="required">*</span></p>
                <fieldset>
                    <legend class="label sr-only">Active?</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label">
                            <input
                                class="form-check-input"
                                type="radio"
                                title="is_active"
                                required
                                name="is_active"
                                value="0"
                                v-model="activeRecord.is_active"
                                data-invalid-feedback="Please select visibility for this funeral."
                            />
                            Inactive
                        </label>
                    </div>

                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label">
                            <input
                                class="form-check-input"
                                title="is_active"
                                type="radio"
                                name="is_active"
                                value="1"
                                v-model="activeRecord.is_active"
                                data-invalid-feedback="Please select a visibility status for this funeral."
                            />
                            Active
                        </label>
                    </div>
                </fieldset>
                <div class="invalid-feedback">
                    Please select visibility for this funeral.
                </div>
            </div>

            <div class="form-group flex-grow-1">
                <p class="m-0">Show Funeral? <span class="required">*</span></p>
                <fieldset>
                    <legend class="label sr-only">hide?</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label">
                            <input
                                class="form-check-input"
                                type="radio"
                                title="is_hidden"
                                required
                                name="is_hidden"
                                value="0"
                                v-model="activeRecord.is_hidden"
                                data-invalid-feedback="Please select visibility for this funeral."
                            />
                            Show
                        </label>
                    </div>

                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label">
                            <input
                                class="form-check-input"
                                title="is_hidden"
                                type="radio"
                                name="is_hidden"
                                value="1"
                                v-model="activeRecord.is_hidden"
                                data-invalid-feedback="Please select a visibility status for this funeral."
                            />
                            Hide
                        </label>
                    </div>
                </fieldset>
                <div class="invalid-feedback">
                    Please select visibility for this funeral.
                </div>
            </div>
        </template>
	</form>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	name: "SiteAddModifyFuneralForm",
	props: ["isActionAdd"],

	computed: {
		getHeader() {
			let action = this.isActionAdd ? "Add a New " : "Modify ";
			return action + "Funeral";
		},
		...mapState(["userData"]),
		...mapState("siteFuneral/addModify", [
			"activeRecord",
			"isFormProcessing",
			"didSaveDraft",
            "errorMessage"
		]),
		...mapState("siteFuneral/list", {
			branches: (state) => state.branches,
			funerals: (state) => state.tableData,
		}),
	},
	methods: {
		...mapActions("siteFuneral", {
			fetchFuneral: "list/fetchFuneral",
			setVar: "addModify/setVar",
            fetchBranches: "list/fetchBranches",
		}),

	},
	mounted() {
		this.setVar({
			variable: "user_id",
			value: this.userData.id,
		});
        this.fetchBranches();
	},
};
</script>
