<template>
    <vue-modal :visible="isModalOpen" @on-close="closeRecycleModal" id="cart-recycle-modal">
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-trash mr-1 text-tertiary"></span>Confirm
                Cart Delete
            </h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">
                Are you sure you want to delete this cart?
            </p>
            <p>This action is permanent. Consider marking the post as inactive.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeRecycleModal">Cancel</button>
                <button
                    type="button"
                    class="btn btn-primary confirm-btn ml-2"
                    @click="recycleObituary"
                    :disabled="isModalProcessing"
                >
                    {{ isModalProcessing ? "Processing..." : "Delete" }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "RecycleCartModal",
    computed: {
        ...mapState("cart", {
            activeRecord: state => state.addModifyCart.activeRecord,
            isModalOpen: state => state.recycleCartModal.isModalOpen,
            isModalProcessing: state =>
                state.recycleCartModal.isModalProcessing
        })
    },
    methods: {
        ...mapActions("cart", {
            closeModal: "recycleCartModal/closeModal",
            recycleRecord: "recycleCartModal/recycleRecord",
            resetCart: "addModifyCart/resetCart"
        }),
        closeRecycleModal() {
            this.closeModal();
        },
        recycleObituary() {
            axios.post("/vpanel/modules/cart/destroy/" + this.activeRecord.id)
                .then(response => {
                showNotification(response.data.message, response.data.result);
                    this.resetCart();
                    this.closeModal();
                    this.$emit('refresh');
            });
        }
    }
};
</script>
