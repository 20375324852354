<template>
    <div class="datatable-section p-2">
        <add-modify-portal-modal />
        <add-new-portal-user-button  class="align-self-end"/>
        <VpanelPortalList />
        <recycle-portal-modal />
    </div>
</template>

<script>
import { mapState } from "vuex";
import AddModifyPortalModal from '../components/modals/addModifyPortalModal.vue';
import VpanelPortalList from "../components/VpanelPortalList.vue";

export default {
    name: "PortalIndex",
    components:{ VpanelPortalList, AddModifyPortalModal         },
    computed: {
        ...mapState("portal/list", {
            isLoading: (state) => state.isLoading
        })
    },

};
</script>
