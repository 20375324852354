<template>
  <div class="row justify-content-center my-4" style="gap:1rem;">
    <RouterLink
      v-if="previousEnabled"
      :to="{ name: nav.previous }"
      class="btn btn-outline-primary border border-primary bottom-btns"
      active-class="current"
      title="Internal link in same tab to NAME PULL IN DYNAMICALLY page"
      exact
    >
      <FontAwesomeIcon
        :icon="icons.leftArrow"
        class="mr-1"
      />
      Previous Step
    </RouterLink>
    <RouterLink
      v-if="nextEnabled"
      :to="{ name: nav.next }"
      class="btn btn-primary bottom-btns"
      active-class="current"
      title="Internal link in same tab to NAME PULL IN DYNAMICALLY page"
      exact
    >
      Continue To Next Step
      <FontAwesomeIcon
        :icon="icons.rightArrow"
        class="ml-1"
      />
    </RouterLink>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRightLong, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'PreviousNextBtns',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      icons: {
        rightArrow: faArrowRightLong,
        leftArrow: faArrowLeftLong,
      },
    };
  },
  computed: {
    ...mapState('cart/addModifyCart', [
      'activeRecord',
      'user',
    ]),
    nav() {
      switch (this.routeName) {
        case 'AccountVerification': return { previous: null, next: 'LockeAssistance' };
        case 'LockeAssistance': return { previous: 'AccountVerification', next: 'ServiceType' };
        case 'ServiceType': return { previous: 'LockeAssistance', next: 'PackageOptions' };
        case 'PackageOptions': return { previous: 'ServiceType', next: 'PurchaseOptions' };
        case 'PurchaseOptions': return { previous: 'PackageOptions', next: 'OtherOptions' };
        case 'OtherOptions': return { previous: 'PurchaseOptions', next: 'ReviewCart' };
        case 'ReviewCart': return { previous: 'OtherOptions', next: this.hasUser ? 'Completion' : 'AccountVerification' };
        case 'Completion': return { previous: this.hasUser ? 'ReviewCart' : 'AccountVerification', next: null };
      }
    },
    nextEnabled() {
      switch (this.routeName) {
        case 'LockeAssistance': return Object.keys(this.activeRecord.timeOfNeed).length > 0;
        case 'ServiceType': return Object.keys(this.activeRecord.serviceType).length > 0;
        case 'PackageOptions': return Object.keys(this.activeRecord.packageOption).length > 0;
        case 'Completion': return false;
        default: return true;
      }
    },
    previousEnabled() {
      return this.routeName !== 'AccountVerification';
    },
    routeName() {
      return this.$route.name;
    },
    hasUser() {
      return this.user !== null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../sass/vue-import";

.bottom-btns {
  display: flex;
  justify-content: center;
  width: min(400px, (100% - 4rem));
  text-align: center;

  @include large {
    margin-top: 5rem auto 0 auto;
  }
}
</style>
