<template>
  <div class="cremation-main">
    <div class="content container my-5">
        <h2 class="top-title">Other Purchase Options</h2>
      <h4 class="text-center text-primary">
        There are other options for keepsakes, additional items, and other arrangement costs you may wish to consider.
      </h4>
        <h3 class="text-center text-primary title-option my-4">
        <span>Cash Advance</span>
      </h3>
      <h4 class="text-center text-primary">
        There are other arrangements outside of the funeral home which we can help you with. If you wish, we can also pay for those expenses as a cash advances for you. An example might be flowers, honorariums, or obituary expenses.
      </h4>
      <div class="planning-cards">
        <PlanningCards
          v-for="product in productsInCategory('Cash Advance')"
          :key="product.id"
          :cardTitle="product.ep_name"
          :cardImage="product.image"
          :cardId="parseInt(product.ep_id)"
          :cardPrice="product.ep_store_price"
          cardStep="7"
          :product="product"
        ><span v-html="product.ep_description"></span></PlanningCards>
      </div>

      <h3 class="text-center text-primary title-option my-4">
        <span>Additional Service Option</span>
      </h3>
      <h4 class="text-center text-primary">
        Depending on each individual circumstance, there may be other items of service we offer but do not include in the package of services option you selected. We will help you understand the options so you make the best decision for yourself. Some examples are listed here.
      </h4>
      <div class="planning-cards">
        <PlanningCards
          v-for="product in productsInCategory('Additional Services')"
          :key="product.id"
          :cardTitle="product.ep_name"
          :cardImage="product.image"
          :cardId="parseInt(product.ep_id)"
          :cardPrice="product.ep_store_price"
          cardStep="6"
          :product="product"
        ><span v-html="product.ep_description"></span></PlanningCards>
      </div>
      <h3 class="text-center text-primary title-option my-4">
        <span>Additional Memorialization Options</span>
      </h3>
      <h4 class="text-center text-primary">
        There are a variety of items designed to be a memorial for you of your loved one. A sample of those items are listed below. We can help you understand your options and design the item you desire.
      </h4>
      <div class="planning-cards">
        <PlanningCards
          v-for="product in productsInCategory('Additional Memorialization')"
          :key="product.id"
          :cardTitle="product.ep_name"
          :cardImage="product.image"
          :cardId="parseInt(product.ep_id)"
          :cardPrice="product.ep_store_price"
          cardStep="6"
          :product="product"
        ><span v-html="product.ep_description"></span></PlanningCards>
      </div>


      <!-- Hiding from planning center per LOCKE-867 2022-11-21 -->
      <!-- <h3 class="text-center text-primary title-option my-4">
        <span>Death Certificate</span>
      </h3>
      <div class="planning-cards">
        <PlanningCards
          v-for="product in productsInCategory('Death Certificate')"
          :key="product.id"
          :cardTitle="product.ep_name"
          :cardImage="product.image"
          :cardId="parseInt(product.ep_id)"
          :cardPrice="product.ep_store_price"
          cardStep="12"
          :product="product"
        ><span v-html="product.ep_description"></span></PlanningCards>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['OtherOptions'],
  computed: {
    ...mapState('cart/addModifyCart', [
      'activeRecord',
      'products',
    ]),
  },
  methods: {
    productsInCategory(category) {
      return this.products.filter((product) => product.cat === category);
    },
  },
};
</script>
