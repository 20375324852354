<template>
  <div class="content container my-5">
    <div v-if="serviceType == 'cremation'">
      <h2 class="top-title">
        Cremation Services We Offer
      </h2>
      <h4 class="text-center text-primary">
        There are many options available for those interested in cremation
        services. <br />Choose from the options below to continue your
        cremation planning.
      </h4>
      <h3 class="text-center text-primary title-option my-4">
        <span>Cremation Service Packages</span>
      </h3>
      <div class="planning-cards">
        <PlanningCards
          v-for="product in productsInCategory('Service Option Cremation')"
          :key="product.id"
          :cardTitle="product.ep_name"
          :cardImage="product.image"
          :cardId="parseInt(product.ep_id)"
          :cardPrice="product.ep_store_price"
          :product="product"
          cardStep="2"
        ><span v-html="product.ep_description"></span></PlanningCards>
      </div>
    </div>
    <div v-if="serviceType == 'burial'">
      <h3 class="text-center text-primary title-option my-4">
        <span>Burial Service Packages</span>
      </h3>
      <div class="planning-cards">
        <PlanningCards
          v-for="product in productsInCategory('Service Option Burial')"
          :key="product.id"
          :cardTitle="product.ep_name"
          :cardImage="product.image"
          :cardId="parseInt(product.ep_id)"
          :cardPrice="product.ep_store_price"
          cardStep="2"
          :product="product"
        />
      </div>
    </div>
    <div v-if="serviceType == 'other'">
      <h2 class="text-center text-primary mb-4">Other Services We Offer</h2>
      <h3 class="text-center text-primary">
        There are many options available for those interested in our services.
        <br />Choose from the options below to continue your planning.
      </h3>
      <!-- TODO: Set up a way to save selection -->
      <div>
        <h3 class="text-center text-primary title-option my-4">
          <span>Select an Option</span>
        </h3>
        <div class="planning-cards">
          <PlanningCards
            v-for="product in productsInCategory('Service Option Other')"
            :key="product.id"
            :cardTitle="product.ep_name"
            :cardImage="product.image"
            :cardId="product.ep_id"
            :cardPrice="product.ep_store_price"
            :product="product"
            cardStep=2
          ><span v-html="product.ep_description"></span></PlanningCards>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('cart/addModifyCart', [
      'activeRecord',
      'products',
      'cartCount',
      'subTotal',
      'tax',
      'total',
      'containerSuppliedByCustomer',
    ]),
    serviceType() {
      return this.activeRecord?.serviceType?.serviceType;
    },
  },
  methods: {
    productsInCategory(category) {
      return this.products.filter((product) => product.cat === category);
    },
  },
};
</script>
