<template>
    <vue-modal
        :visible="isModalOpen"
        @on-close="closeRecycleModal"
        id="condolence-recycle-modal"
    >
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-trash mr-1 text-tertiary"></span>Confirm
                Condolence Delete
            </h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">
                Are you sure you want to delete this condolence?
            </p>
            <p>
                This action is permanent. Consider marking the condolence as
                declined.
            </p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button
                    type="button"
                    class="btn btn-outline-primary cancel-btn"
                    @click="closeRecycleModal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    class="btn btn-primary confirm-btn ml-2"
                    @click="recycleCondolence"
                    :disabled="isModalProcessing"
                >
                    {{
                        isModalProcessing
                            ? "Processing..."
                            : "Delete condolence"
                    }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "RecycleCondolenceModal",

    computed: {
        ...mapState("condolence", {
            activeRecord: state => state.addModifyCondolence.activeRecord,
            isModalOpen: state => state.recycleCondolenceModal.isModalOpen,
            isModalProcessing: state =>
                state.recycleCondolenceModal.isModalProcessing
        })
    },
    methods: {
        ...mapActions("condolence", {
            closeModal: "recycleCondolenceModal/closeModal",
            resetCondolenceModal: "addModifyCondolence/resetCondolenceModal",
            recycleRecord: "recycleCondolenceModal/recycleRecord"
        }),
        closeRecycleModal() {
            this.resetCondolenceModal();
            this.closeModal();
        },
        recycleCondolence() {
            this.recycleRecord({
                ajaxRoute:
                    "/vpanel/modules/obituary/condolence/destroy/" +
                    this.activeRecord.id,
                recordId: this.activeRecord.id,
                dispatchAction: "condolence/list/removeRecordFromDataTable"
            });

        }
    }
};
</script>
