<template>
  <div class="funeral-index-wrapper" v-if="funeral">
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FuneralIndex',
  computed: {
    ...mapState(['funerals']),
    funeral() {
      return this.funerals[this.$route.params.id];
    },
  },
};
</script>

<style lang="scss" scoped>
  .funeral-index-wrapper {
    display: flex;
    flex-grow: 1;
  }
</style>