<template>
	<div>
		<h3 class="m-5">{{ activeRecord.decedent }}'s Funeral Documents</h3>
        <h4 class="m-5" v-if="!files.length">...Nothing uploaded yet.</h4>
		<div class="file-card m-5" v-for="file in files" :key="file.id">
			<div class="file-top-bar">
				<p class="file-title"><strong>File Name:</strong> {{file.attributes.file_name }} </p>
				<p> <strong>Type: </strong> {{file.attributes.for}}<strong> | Uploaded By: </strong> {{file.attributes.uploaded_by_name}}</p>
				<img :src="file.value" :alt="file.key" />
			</div>

			<div class="file-actions mt-1">
				<button @click="download(file.value)">Download</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState("cart/addModifyCart", {
			activeRecord: (state) => state.activeRecord,
		}),
		...mapState(["files", "userData"]),
		files() {
			return this.activeRecord.submissions.filter(
				(submission) => submission.key === "file"
			);
		},
	},
	methods: {
		download(file) {
			let formData = { url: file };
			axios
				.post("/vpanel/modules/cart/documents/download", formData, {
					responseType: "blob",
				})
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data])
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", formData.url);
					document.body.appendChild(link);
					link.click();
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/vue-import";

.file-cards {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.file-card {
		padding: 0.5rem 1rem;
		bfile: 1px solid var(--light-gray);
		bfile-radius: 5px;
	}

	.file-top-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;
	}

	.file-title {
		margin: 0;
	}

	.file-actions {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 0.25rem;

		button {
			display: block;
			flex-grow: 1;
			bfile-radius: 1rem;
		}
	}

	.file-dropdown {
		height: 0;
		overflow: hidden;
		padding-top: 0;
		margin-top: 0;
		bfile-top: 0 solid transparent;
		transition: 200ms ease-out;

		&.active {
			height: auto;
			overflow: hidden;
			padding-top: 0.5rem;
			margin-top: 0.5rem;
			bfile-top: 1px solid var(--light-gray);
		}
	}

	.file-statuses {
		display: flex;
		gap: 0.5rem;
		flex-wrap: wrap;
	}

	.file-status {
		font-size: 0.8em;
		bfile: 1px solid var(--light-gray);
		bfile-radius: 1em;
		padding: 0.125rem 0.75rem;
		font-weight: bold;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin: 0;
		gap: 0 0.5rem;

		span {
			font-weight: normal;
		}
	}

	.file-products-grid {
		margin-top: 1rem;
		display: grid;
		grid-auto-rows: max-content;
		gap: 0.5rem;

		@include not-desktop {
			grid-template-columns: 100%;
		}

		@include desktop {
			grid-template-columns: repeat(2, auto);
		}

		.opg-heading {
			font-weight: bold;

			@include not-desktop {
				display: none;
			}
		}

		.opg-hidden-label {
			font-weight: bold;

			@include desktop {
				display: none;
			}
		}

		p {
			margin: 0;

			@include desktop {
				&:not(:nth-child(2n + 1)) {
					text-align: right;
				}
			}

			@include not-desktop {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: 0 0.5rem;

				&:nth-child(2n):not(:last-child) {
					margin-bottom: 0.25rem;
					padding-bottom: 0.25rem;
					bfile-bottom: 1px solid var(--light-gray);
				}
			}
		}
	}

	.opg-total {
		grid-column: 1 / -1;
		bfile-top: 1px solid var(--light-gray);
		text-align: right;
		padding-top: 0.5rem;
		font-weight: bold;
	}
}
</style>