<template>
<div class="simple-table-wrapper simple-table-wrapper-lg" v-if="!events.isLoading">
    <form class="search-form mb-5" @submit.prevent="events.searchModel = searchModel">
        <div class="search-fields d-flex flex-wrap align-items-center">
            <input class="st-search w-100 w-md-auto" type="search" v-model="searchModel" placeholder="Search..." />
            <button type="submit" class="btn btn-primary ml-2"><span class="fas fa-search"></span> APPLY FILTERS</button>
        </div>
    </form>

    <div class="st-hidden-sorts">
        <p @click="events.sortBy('decedent')" :class="events.sortClass('decedent')">Decedent</p>

        <p @click="events.sortBy('updated_at', null, 'date')" :class="events.sortClass('updated_at')">Last Modified</p>
    </div>

    <div class="simple-table" style="--cols:4">
        <p class="first-col st-header" @click="events.sortBy('decedent')" :class="events.sortClass('decedent')">Decedent</p>
        <p class="st-header">Name</p>
        <p class="st-header" @click="events.sortBy('updated_at', null, 'date')" :class="events.sortClass('updated_at')">Last Modified</p>
        <p class="last-col st-header">Options</p>
        <template v-for="row in events.displayedList">

            <div class="first-col" :key="row.id+'decedent'">
                <span class="st-hidden">Decedent</span>
                {{ row.decedent }}
            </div>
            <p :key="row.id+'name'">
                <span class="st-hidden"></span>
                {{ row.name }}
            </p>
            <div :key="row.id+'updated_at'">
                <span class="st-hidden">Last Modified</span>
                {{ row.updated_at }}
            </div>

            <div :key="row.id+'options'">
                <span class="st-hidden">Options</span>
                <div class="st-buttons">
                    <button type="button" class="btn btn-secondary" @click.prevent="modifyEvents(row)">Modify</button>
                    <button type="button" class="btn btn-outline-primary" @click.prevent="recycleEvents(row)">Delete</button>
                </div>
            </div>
        </template>
    </div>

    <p>{{ events.rangeText }}</p>
    <Pagination :active-page="events.activePage" :item-count="events.itemCount" :items-per-page="events.itemsPerPage" @on-update="events.updateActivePage($event)" />
</div>
<VueLoader v-else />
</template>

<script>
import {
    mapState,
    mapActions,
    mapMutations
} from "vuex";
import EloquentSSFP from '@j/core/global/EloquentSSFP';
export default {
    name: "VpanelScheduleList",

    data() {
        return {
            events: new EloquentSSFP("/vpanel/modules/obituary/schedule/schedule-list", {
                activeSorts: ["date","decedent"],
                sortDirs: ["desc"],
                sortTypes: ["date","string"],
                searchableData: ["decedent"],
                itemsPerPage: 10
            }),
            searchModel: ""
        }
    },

    computed: {
        ...mapState("schedule/list", {
            cspNonce: (state) => state.cspNonce,
            isUpdatedIncrement: (state) => state.isUpdatedIncrement,
        }),
    },
    methods: {

        ...mapMutations("schedule/addModifySchedule", ["SET_ACTIVE_RECORD"]),
        ...mapActions("schedule/addModifySchedule", ["setFormIsAdd", "openModal"]),
        ...mapActions("schedule", {
            openRecycleModal: "recycleEventModal/openModal"
        }),

        modifyEvents(row) {
            this.SET_ACTIVE_RECORD(row);
            this.setFormIsAdd(false);
            this.openModal(true);
        },
        recycleEvents(row) {
            this.SET_ACTIVE_RECORD(row);
            this.openRecycleModal(null);

        },

    },
    watch: {
        isUpdatedIncrement(n, o) {
            this.events.refreshData(this.events.options);
        },
        searchModel(n, o) {
            if (!n && o) {
                this.events.updateSearch(n);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../../../Core/Global/Components/VueTable/scss/_table-styles.scss';

.simple-table {
    // Custom col structure to make the last col only be as wide as it needs to be
    --custom-col-structure: repeat(3, auto) max-content;
}
</style>
