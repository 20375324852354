<template>
    <div
        class="planning-card"
        @click="addToCart(product, cardStep);clicked = !clicked"
        :class="{ active: selected }"
    >
        <img
            class="planning-card__image"
            :src="cardImage || '/images/custom/site/Placeholder.png'"
            :alt="cardTitle + ' Header Image'"
        />
        <div class="planning-card__content">
            <div class="content-top">
                <p class="card-title">{{ cardTitle }}</p>
                <div class="card-desc">
                    <p v-if="cardDescription" v-html="cardDescription"></p>
                    <slot else>
                        <span v-html="product.ep_description"></span>
                    </slot>
                </div>
            </div>
            <div class="content-bottom">
                <h4 class="card-price" v-if="cardPrice > .01">${{ cardPrice }}</h4>
                <button
                    class="btn btn-primary d-flex text-center justify-content-center"
                    :title="`Select ${cardTitle} Option`"
                >Choose This Option</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    name: 'PlanningCards',
    props: {
        cardTitle: {
            type: String,
            required: true,
        },
        cardDescription: {
            type: String,
            required: false,
        },
        cardPrice: {
            type: String,
            required: false,
        },
        cardImage: {
            type: String,
            required: false,
        },
            cardId: {
            required: false,
        },
        cardStep: {
            type: String,
            required: false,
        },
        product: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            isActive: false,
            loginModalVisible: false,
            clicked: false,
        };
    },
    computed: {
        ...mapState('cart/addModifyCart', [
            'user',
            'cart',
            'products',
            'cartCount',
            'activeRecord',
            'customerIsProvidingContainer',
        ]),
        itemInCart() {
            const items = this.activeRecord.other;
            const id = this.product.ep_id;
            return items.find((o) => o.ep_id === id);
        },
        hasUser() {
            if (!this.user) return false;
            return !!Object.keys(this.user);
        },
        selected() {
            let isSelected = false;
            const step = this.cardStep;
            const ar = this.activeRecord;

            switch (step) {
                case '0': isSelected = this.checkIsSelected(ar.timeOfNeed, 'need'); break;
                case '1': isSelected = this.checkIsSelected(ar.serviceType, 'serviceType'); break;
                case '2': isSelected = this.checkIsSelected(ar.packageOption, 'ep_id'); break;
                case '3': isSelected = this.checkIsSelected(ar.casketOption, 'ep_id'); break;
                case '4': isSelected = this.checkIsSelected(ar.remainsContainer, 'ep_id'); break;
                case '5': isSelected = this.checkIsSelected(ar.vault, 'ep_id'); break;
                case '12': isSelected = this.checkIsSelected(ar.deathCert, 'ep_id'); break;
                case '6':
                case '7':
                case '8': isSelected = !!this.itemInCart; break;
            }

            return isSelected;
        },
    },
    methods: {
        ...mapMutations('cart/addModifyCart', ['SET_VALUE_IN_ACTIVE_RECORD']),
        ...mapActions('cart/addModifyCart', [
            'openModal',
            'calCartCount',
            'addItemToCart',
            'removeItemFromCart',
            'addOtherItemsToCart',
            'removeOtherItemFromCart',
        ]),
        checkIsSelected(obj, propertyOnObj) {
            const hasKeys = !!Object.keys(obj).length; // Object has keys
            const cardIdEqual = this.cardId === obj[propertyOnObj]; // Property on object === cardId
            return hasKeys && cardIdEqual;
        },
        addToCart(product, step) {
            const payload = { value: product };
            switch (step) {
                case '0': payload.variable = 'timeOfNeed'; break;
                case '1': payload.variable = 'serviceType'; break;
                case '2': payload.variable = 'packageOption'; break;
                case '3': payload.variable = 'casketOption'; break;
                case '4': payload.variable = 'remainsContainer'; break;
                case '5': payload.variable = 'vault'; break;
                case '6': payload.variable = 'momentos'; break;
                case '7': payload.variable = 'cashAdvance'; break;
                case '8': payload.variable = 'deathCertificate'; break;
                case '9': {
                    const a = document.createElement('a');
                    a.href = '/login?lpc';
                    a.click();
                    break;
                }
                case '10': {
                    const a = document.createElement('a');
                    a.href = '/register?lpc';
                    a.click();
                    break;
                }
                case '11': this.$router.push({ name: 'LockeAssistance' }); break;
                case '12': payload.variable = 'deathCert'; break;
            }
            switch (step) {
                case '6':
                case '7':
                case '8': !this.selected ? this.addOtherItemsToCart(payload) : this.removeOtherItemFromCart(payload.value.ep_id); break;
                case '12': !this.selected ? this.addItemToCart(payload) : this.removeItemFromCart('deathCert'); break;
                case '9':
                case '10':
                case '11': break; // Do Nothing
                default: this.addItemToCart(payload); break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../sass/_vue-import.scss";

.planning-card {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: min(250px, 100%);
    max-width: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 1px calc(var(--gridGap) / 2) 3px #c4d3e0;
    text-decoration: none;
    cursor: pointer;
    transition: 250ms ease-in-out;

    // Below card to prevent CSS Jitter
    &:after {
        content: "";
        position: absolute;
        inset: 100% 0 0;
        height: 0;
        transition: 250ms ease-in-out;
    }

    &:hover, &.active {
        position: relative;
        transform: translateY(calc(var(--gridGap) * -1));
        box-shadow: 0 1px calc(var(--gridGap) / 2) 3px #7d9eba;

        &:after { height: var(--gridGap); }
    }

    &.active:before {
        content: url("/images/custom/site/green-check.png");
        position: absolute;
        top: -10px;
        right: -10px;
    }

    &.disable {
        opacity: 0.5;
    }

    .card-price {
        text-align: center;
        color: var(--blue);
        font-size: 24px;
        font-weight: 700;
    }

    .planning-card__image {
        height: 225px;
        max-width: 100%;
        object-fit: cover;
    }

    .planning-card__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--gridGap);
        padding: calc(var(--gridGap) * 1.5) var(--gridGap);

        .content-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: calc(var(--gridGap) / 4);

            button {
                pointer-events: none;
            }
        }
    }

    .card-title {
        @include fluid-size(18px, 24px, font-size);
        color: var(--dark-tan);
        font-weight: 700;
        padding: 0;
        margin: 0 0 calc(var(--gridGap) / 4);
        text-align: center;
        line-height: 1.1;
    }

    .card-desc {
        @include fluid-size(14px, 16px, font-size);
        text-align: left;
        margin: 0;
    }

    .card-price {
        @include fluid-size(18px, 24px, font-size);
        text-align: center;
        color: var(--blue);
        font-weight: 700;
    }
}
</style>