<template>
  <div class="cremation-main flex-grow-1 d-flex flex-column">
    <PlanningNav />
    <FormSteps />
    <div class="content container flex-grow-1">
      <component :is="routeName" />
    </div>

    <PreviousNextBtns />
    <footer>
      <PortalFooter />
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isEditing: true,
    };
  },
  computed: {
    ...mapState('cart/addModifyCart', [
      'activeRecord',
    ]),
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions('cart/addModifyCart', [
      'setFormIsAdd',
      'openModal',
      'closeModal',
    ]),
    ...mapMutations('cart/addModifyCart', [
      'SET_ACTIVE_RECORD',
    ]),
    modifyCart(cart) {
      this.SET_ACTIVE_RECORD(cart);
      this.setFormIsAdd(false);
      this.openModal();
    },
    ...mapActions('cart/addModifyCart', [
      'fetchProducts',
      'fetchBranches',
    ]),
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    recycleCart(cart) {
      this.SET_ACTIVE_RECORD(cart);
      this.openRecycleModal();
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  created() {
    this.fetchProducts();
    this.fetchBranches();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../sass/_vue-import.scss";

.cremation-main {
  &::v-deep {
    .planning-cards {
      @include fluid-size(10px, 20px, --gridGap);
      margin: 2rem 0;
      // The better grid version that works perfect but no centering trailing items
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
      // grid-template-rows: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: calc(var(--gridGap) * 2);
    }

    .check {
      list-style: none;
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-left: 0;

      li {
        background: url("/images/custom/site/check.png") left 7px no-repeat;
        padding: 5px 0 5px 25px;
      }
    }
  }
}
</style>
