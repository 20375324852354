<template>
  <button
    type="button"
    class="btn btn-primary m-0 btn-lg"
    @click.prevent="planningCenter()"
  >
    <FontAwesomeIcon :icon="icons.plus" class="mr-1" />
    Add Funeral
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'AddNewFuneralButtonPortal',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      icons: {
        plus: faPlus,
      },
    };
  },
  methods: {
    planningCenter() {
      location.replace('/locke-planning-center');
    },
  },
};
</script>
