<template>
	<form
		id="add-modify-funeral-user-form"
		class="user-form needs-validation m-0"
		name="add-modify-funeral-user-form"
		novalidate
	>
		<div class="form-errors" v-if="formErrors" v-html="formErrors"></div>
		<input
			type="hidden"
			name="_token"
			:value="activeRecord._token"
		/>
        <div v-if="!this.activeRecord.isVpanel">
            <input
			type="hidden"
			name="user_id"
			v-model="activeRecord.user_id"
		/>
		<input
			type="hidden"
			name="id"
			v-model="activeRecord.funeral_id"
			v-if="!isActionAdd"
		/>
        </div>
		<div class="d-flex flex-column justify-content-between align-items-stretch h-100">
			<div class="document-form-fields d-flex flex-column align-items-stretch">
				<h5 class="mb-2">Basic Information</h5>

                <div class="row">
                    <div class="col-md">
                        <VueInput
                            required
                            :float-label="false"
                            :disabled="isDisabled"
                            label="Name"
                            name="name"
                            v-model="activeRecord.name"
                        />
                    </div>
                    <div class="col-md">
                        <VueInput
                            required
                            :float-label="false"
                            :disabled="isDisabled"
                            label="Email"
                            name="email"
                            v-model="activeRecord.email"
                            type="email"
                        />
                    </div>
                </div>

				<p v-if="!activeRecord.isActionAdd">{{ activeRecord.name }}</p>

                <VueRadios
                    required
                    :float-label="false"
                    :label="`${!activeRecord.isActionAdd && activeRecord.name ? activeRecord.name + '\'s ' : ''}Role`"
                    name="role"
                    :options="[{name:'Organizer',value:'2'},{name:'User',value:'3'}]"
                    v-model="activeRecord.role_id"
                />

			</div>
		</div>
	</form>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {

	name: "AddModifyFuneralUserForm",

	methods: {
		...mapActions("user/addModifyUser", {
			setVar: "setVar",
		}),
	},

	computed: {
		getHeader() {
			let action = this.isActionAdd ? "Add a New " : "Modify ";
			return action + "Funeral";
		},
		...mapState({
			user: (state) => state.userData,
		}),
		...mapState("user/addModifyUser", {
			activeRecord: (state) => state.activeRecord,
			isFormProcessing: (state) => state.isFormProcessing,
			didSaveDraft: (state) => state.didSaveDraft,
			isActionAdd: (state) => state.isActionAdd,
            formErrors: (state) =>state.formErrors
		}),
		...mapState(["userData"]),
		...mapState("user/list", {
			users: (state) => state.tableData,
		}),
		isDisabled() {
			let disabled = null;
			this.isActionAdd ? (disabled = false) : (disabled = true);
			return disabled;
		},
	},
	updated() {
            if(!this.activeRecord.isVpanel){
                this.setVar({
                variable: "funeral_id",
                value: this.$route.params.id,
            });

            this.setVar({
                variable: "organizer",
                value: this.userData.name,
            });

            this.setVar({
                variable: "user_id",
                value: this.userData.id,
            });
        }

	},
};
</script>
