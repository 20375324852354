<template>
  <header class="d-flex justify-content-between align-items-center border-bottom flex-shrink-0">
    <div class="position-relative logo-wrapper">
      <div class="d-flex align-items-center p-1">
        <img
          src="/images/custom/site/favicons/favicon-32x32.png"
          :alt="`${companyName} - Collaboration Portal`"
        />
        <p class="font-weight-bold ml-1 my-0 text-14">Collaboration Portal</p>
      </div>
      <a
        class="absolute-fill text-throw"
        href="/dashboard"
        :title="`${companyName} - Collaboration Portal`"
      >{{ companyName }} - Collaboration Portal</a>
    </div>

    <div class="pr-1 d-flex align-items-center">
      <a href="/locke-planning-center" target="_blank" class="tooltip-btn btn btn-light-tan btn-sm rounded-pill text-uppercase align-items-center mr-1">Planning Center</a>
      <a href="/" target="_blank" class="tooltip-btn btn btn-light-tan btn-sm rounded-pill text-uppercase align-items-center mr-1">Locke Website</a>
      <div class="user-button" :class="{'show-dropdown':showDropdown}">
        <button class="portal-header-btn" tabindex="-1" @click.prevent="toggleDropdown()">
          <span class="fas fa-user-circle"></span>
        </button>

        <div class="user-button-dropdown wrap-gap" @click="toggleDropdown()">
          <p class="dropdown-header">{{ userData.name }}</p>
          <RouterLink
            to="/dashboard/profile"
            title="Opens your Profile Page"
            :class="{current:onProfilePage}"
          >Profile</RouterLink>

          <button @click.prevent="logout()" title="Logs you out of the Collaboration Portal">
            Log out
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: ['companyName'],
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    ...mapState([
      'userData',
    ]),
    currentPage() {
      return this.$route.name;
    },
    onProfilePage() {
      return this.currentPage === 'ProfileIndex';
    },
  },
  methods: {
    ...mapActions('portal', [
      'logout',
    ]),
    toggleDropdown() {
      if (this.showDropdown) document.activeElement.blur();
      this.showDropdown = !this.showDropdown;
    },
    openProfile() {
      if (!this.onProfilePage) {
        this.$router.push({ name: 'ProfileIndex' });
        this.toggleDropdown();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .portal-header-btn {
    position: relative;
    color: hsl(0deg,0%,20%);
    outline: none;
    border: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-size: 22px;
    transition: 100ms ease-out;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--primary);
      border-radius: 50rem;
      opacity: 0;
      transition: 100ms ease-out;
    }

    &:after {
      content: "";
      position: relative;
      height: 5px;
      margin-left: 0;
      width: 0;
      background-color: currentColor;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      transition: 100ms ease-out;

      .user-button.show-dropdown &, .user-button:focus-within & {
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
      }
    }

    .user-button.show-dropdown &, .user-button:focus-within &, &:hover {
      color: var(--primary);

      &:before { opacity: .1; }
      &:after {
        margin-left: 5px;
        width: 10px;
      }
    }
  }

  .logo-wrapper {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--primary);
      opacity: 0;
      transition: 100ms ease-out;
    }

    &:hover:before {
      opacity: .1;
    }
  }

  .user-button {
    position: relative;
  }

  .user-button-dropdown {
    margin: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    padding: .25rem;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
    transition: 100ms ease-out;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    flex-direction: column;
    min-width: 200px;

    .user-button.show-dropdown &, .user-button:focus-within & {
      top: calc(100% + 4px);
      opacity: 1;
      pointer-events: all;
    }

    > * {
      text-align: left;
      text-decoration: none;
      font-size: 14px;
      padding: .5rem;
      color: hsl(0deg, 0%, 20%);
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      border: 0;
      background-color: transparent;
      outline: none;
      display: block;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: 100ms ease-out;
        background-color: var(--primary);
        top: 0;
        height: 100%;
      }

      &:hover, &:focus, &.current {
        color: var(--primary);
        &:before { opacity: .1; }
      }

      &.dropdown-header {
        color: hsl(0deg, 0%, 20%) !important;
        &:before { content: none; }
        border-radius: 0;
        border-bottom: 1px solid hsl(0deg, 0%, 90%);
        margin-top: 0;
        font-weight: bold;
      }
    }
  }
</style>
