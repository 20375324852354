<template>
    <vue-modal
        :visible="isModalOpen"
        @on-close="closeRecycleModal"
        id="funeral-recycle-modal"
    >
        <template #header>
            <h5 class="modal-title">
                <span class="fas fa-trash mr-1 text-tertiary"></span>Confirm
                Funeral Delete
            </h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">
                Are you sure you want to delete this funeral?
            </p>
            <p>
                This action is permanent. Consider marking the funeral as inactive.

            </p>
            <p> Please note this will not delete the funeral in Passare.
            </p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button
                    type="button"
                    class="btn btn-outline-primary cancel-btn"
                    @click="closeModal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    class="btn btn-primary confirm-btn ml-2"
                    @click="recycleFuneral"
                    :disabled="isModalProcessing"
                >
                    {{
                        isModalProcessing
                            ? "Processing..."
                            : "Delete Funeral"
                    }}
                </button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "RecycleFuneralModal",

    computed: {
        ...mapState("funeral", {
            activeRecord: state => state.addModify.activeRecord,
            isModalOpen: state => state.recycleFuneralModal.isModalOpen,
            isModalProcessing: state =>
                state.recycleFuneralModal.isModalProcessing
        })
    },
    methods: {
        ...mapActions("funeral", {
            closeModal: "recycleFuneralModal/closeModal",
            resetFuneral: "addModify/resetFuneral",
             recycleRecord: "recycleFuneralModal/recycleRecord"
        }),
        closeRecycleModal() {
            this.resetFuneral();
            this.closeModal();
        },
        recycleFuneral() {
            this.recycleRecord({
                ajaxRoute:
                    "/vpanel/modules/obituary/funeral/destroy/" +
                    this.activeRecord.id,
                recordId: this.activeRecord.id,
                dispatchAction: "funeral/list/removeRecordFromDataTable"
            });
        }
    }
};
</script>
