<template>
  <FuneralBioDynamicSection
    name="Financial"
    property="financial_info"
    :default-obj="defaultObj"
    :section="section"
    :sub-title="subTitle"
    v-slot="slotProps"
  >
    <div class="input-grid">
      <VueSelect
        label="Financial Institution Type"
        name="finance_type"
        v-model="slotProps.item.finance_type"
        :options="financialTypeOptions"
      />
      <VueSelect
        label="Person to Contact"
        name="finance_contact"
        v-model="slotProps.item.finance_contact"
        :options="financialContactOptions"
      />
      <VueInput
        label="Name of Contact"
        name="finance_contact_name"
        v-model="slotProps.item.finance_contact_name"
      />
      <VueInput
        label="Policy No"
        name="policy_number"
        v-model="slotProps.item.policy_number"
      />
    </div>

    <VueTextarea
      label="Description"
      name="Hobby Description"
      v-model="slotProps.item.finance_description"
      class="mt-2 mx-0 mb-0"
    />
  </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentFinancial',
  props: ['section'],
  data() {
    return {
      subTitle: "Whether you organize financial information on our portal or on paper, leaving this information for others can be immensely helpful. Don't forget to make sure to make usernames and passwords for your digital world available. Ask us for help on how you might do this.",
      defaultObj: {
        finance_type: '',
        finance_contact: '',
        finance_contact_name: '',
        policy_number: '',
        finance_description: '',
      },
      financialTypeOptions: [
        { name: 'Bank', value: 'bank' },
        { name: 'Insurance', value: 'insurance' },
        { name: 'Stocks and Bonds', value: 'stocks_and_bonds' },
        { name: 'Pension Plans or Annuities', value: 'pension_plans_or_annuities' },
        { name: 'Real Estate', value: 'real_estate' },
        { name: 'Auto Titles', value: 'auto_titles' },
        { name: 'Will', value: 'will' },
        { name: 'Deeds', value: 'deeds' },
        { name: 'Other', value: 'other' },
      ],
      financialContactOptions: [
        { name: 'Lawyer', value: 'lawyer' },
        { name: 'Agent', value: 'agent' },
        { name: 'Doctor', value: 'doctor' },
        { name: 'Accountant', value: 'accountant' },
        { name: 'Real Estate', value: 'real_estate' },
        { name: 'Other', value: 'other' },
      ],
    };
  },
};
</script>
