<template>
  <FuneralBioDynamicSection
    name="Occupation"
    property="occupation_info"
    :default-obj="defaultObj"
    :section="section"
    sub-title="List Important jobs and types of work during career."
    v-slot="slotProps"
  >
    <div class="input-grid">
      <VueInput name="Occupation" v-model="slotProps.item.occupation" />
      <VueInput name="Years of Service" v-model="slotProps.item.occupation_years_of_service" />
    </div>
    <VueTextarea
      class="mt-2 mx-0 mb-0"
      name="Occupation Description"
      v-model="slotProps.item.occupation_description"
    />
  </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentOccupations',
  props: ['section'],
  data() {
    return {
      defaultObj: {
        occupation: '',
        occupation_years_of_service: '',
        occupation_description: '',
      },
    };
  },
};
</script>
