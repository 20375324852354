<template>
    <vue-modal
        :visible="recycleModalOpen"
        @on-close="closeRecycleModal"
        id="funeral-recycle-modal"
    >
        <template #header>
            <h5 class="modal-title"><span class="fas fa-trash-alt mr-1 text-tertiary"></span>Confirm Funeral Delete</h5>
        </template>

        <div class="form-errors"></div>

        <div class="d-flex flex-column align-items-center my-3">
            <span class="fas fa-exclamation-triangle text-danger fa-5x mb-3"></span>
            <p class="text-center font-weight-bold text-20 m-0">Are you sure you want to delete this funeral?</p>
            <p class="text-center m-0">This action is permanent.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-outline-primary border cancel-btn"
                    @click="closeRecycleModal"
                >Cancel</button>

                <button
                    type="button"
                    class="btn btn-primary confirm-btn ml-2"
                    @click="recycleFuneral"
                    :disabled="isModalProcessing"
                >{{ isModalProcessing ? "Processing..." : "Delete Funeral" }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
    name: "SiteRecycleFuneralModal",
    computed: {
        ...mapState([
            "userData",
        ]),
        ...mapState("siteFuneral/addModify", [
            'activeRecord'
        ]),
        ...mapState("siteFuneral/siteRecycleFuneralModal", [
            'isModalProcessing',
        ]),
        ...mapState("siteFuneral/list", [
            'recycleModalOpen',
        ])
    },
    methods: {
        ...mapMutations("siteFuneral/list", [
            'SET_RECYCLE_MODAL_OPEN'
        ]),
        ...mapActions([
            'fetchData'
        ]),
        ...mapActions("siteFuneral/siteRecycleFuneralModal", [
            'openModal',
            'recycleRecord',
        ]),
        ...mapActions("siteFuneral/addModify", [
            'resetFuneral'
        ]),
        closeRecycleModal() {
            this.resetFuneral();
            this.SET_RECYCLE_MODAL_OPEN(false);
        },
        recycleFuneral() {
            axios.post(`/dashboard/funeral/user-destroy`, {funeral_id: this.activeRecord.funeral_id, user_id: this.userData.id})
                 .then(res => this.fetchData())
                 .catch(err => console.error(err));

            this.SET_RECYCLE_MODAL_OPEN(false);
        }
    }
};
</script>
