<template>
    <div>
        <ChatRoomListItem
            v-show="searchedChats.length"
            v-for="(chatRoom, index) in searchedChats"
            :key="index"
            :chat-room="chatRoom"
        />
        <p class="mt-3 mb-0 text-center" v-if="!searchedChats.length">
            No conversations found
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ChatRoomListItem from "./ChatRoomListItem";
export default {
    props: ["searchValue"],
    components: {
        ChatRoomListItem
    },
    computed: {
        ...mapState({
            chatRoomList: state => state.chatrooms,
        }),
        searchedChats() {
            if (this.chatRoomList && this.searchValue) {
                return this.chatRoomList.filter(chatRoom => {

                    return chatRoom.funeral.name.toString().toLowerCase().search(this.searchValue.toLowerCase()) !== -1;
                });
            } else {
                return this.chatRoomList;
            }
        }
    }
};
</script>
