<template>
    <div class="available-contacts">
        <div class="contact-list">
            <ContactListItem
                v-for="(contact, index) in removeNull"
                :key="index"
                :contact="contact"
            />
        </div>
    </div>
</template>

<script>
import ContactListItem from "./ContactListItem";
export default {
    components: {
        ContactListItem
    },
    props: ['contactList'],
    computed: {
        removeNull() {
            return this.contactList.filter(contact => contact !== null);
        }
    }
};
</script>

<style lang="scss" scoped>
.available-contacts {
    padding-bottom: 0.5rem;
    background-image: linear-gradient(white calc(100% - 0.5rem), transparent);
}

.toggle-available {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    padding: .5rem;
    margin: 0;
    border-top: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .arrow {
        transform: rotate(0);
        transition: 200ms ease-out;
    }

    &.toggled {
        height: auto;

        .arrow { transform: rotate(180deg); }
    }
}

.contact-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
