<template>
  <FuneralBioDynamicSection
    name="Education"
    property="education_info"
    :default-obj="defaultObj"
    :section="section"
    sub-title="List Educational background. Include highest level of education."
    v-slot="slotProps"
  >
    <div class="input-grid">
      <VueSelect
        name="Education Type"
        :options="educationOptions"
        v-model="slotProps.item.education_type"
      />
      <VueInput name="Field of Study" v-model="slotProps.item.field_of_study" />
      <VueInput name="Degree" v-model="slotProps.item.degree_earned" />
    </div>
    <VueTextarea
      class="mt-2 mx-0 mb-0"
      name="Description"
      v-model="slotProps.item.education_description"
    />
  </FuneralBioDynamicSection>
</template>

<script>
export default {
  name: 'DecedentEducation',
  props: ['section'],
  data() {
    return {
      defaultObj: {
        education_type: '',
        field_of_study: '',
        degree_earned: '',
        description: '',
      },
      educationOptions: [
        {
          name: 'Elementary',
          value: 'elementary',
        },
        {
          name: 'Secondary',
          value: 'secondary',
        },
        {
          name: 'College',
          value: 'college',
        },
      ],
    };
  },
};
</script>
