<template>
    <button type="button" class="btn btn-primary m-0" @click.prevent="openModal">
        Add User
    </button>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "AddNewPortalUserButton",
    methods: {
        ...mapActions("portal/addModifyPortal", [
            "openModal",
            "closeModal"
        ])
    }
};
</script>
