<template>
    <div class="condolence-list-item">
        <p class="condolence">{{ condolence.content }}</p>
        <div class="bottom">
            <p class="name">{{ condolence.name }}</p>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";

export default {
    props: ["condolence"],
};
</script>

<style lang="scss" scoped>
.condolence-list-item {
    margin: 1rem 0;
    padding: 1rem;
    background-color: white;
    border: 1px solid var(--light);
    box-shadow: 6px 6px 10px rgba(black, 0.05);
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;

    .condolence {
        margin: 0 0 1rem;
    }

    .bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin: -0.25rem -0.5rem;
        font-size: 14px;

        .name {
            font-weight: bold;
            color: var(--blue);
            margin: 0.25rem 0.5rem;
            line-height: 1;
        }
    }

    .reply {
        padding: 1rem;
        margin: 1rem -1rem -1rem;
        background-color: hsl(0deg, 0%, 90%);
        box-shadow: inset 0px 5px 10px -10px;
    }
}
</style>
