<template>
  <div class="dashboard-wrapper row flex-grow-1 overflow-md-auto align-items-start">
    <template v-if="hasShownFunerals">
      <div class="col-md">
        <SiteFuneralList dashboard-view />
      </div>
      <div class="col-md text-14">
        <PortalCalendar
          :colors="['blue', 'teal', 'emerald', 'moss', 'yellow', 'lavendar']"
          :schedules="schedules"
        />
      </div>
    </template>
    <div class="col-md" v-else>
      <div class="alert alert-info">
        <p>The Collaboration Portal was designed to allow us to collaborate with you. We will take steps to establish that connection with you. We monitor new Collaboration Portal registrations and will respond as quickly as possible during normal business hours.</p>

        <p class="mb-0">If you have immediate need to begin, please call us at <a href="tel:3192333146" title="Opens our contact line in your default calling application">(319) 233-3146.</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PortalCalendar from '@v/Modules/Portal/Custom/Site/components/PortalCalendar.vue';

export default {
  name: 'DashboardIndex',
  components: {
    PortalCalendar,
  },
  computed: {
    ...mapState(['userData', 'schedules', 'funerals']),
    hasShownFunerals() {
      return Object.values(this.funerals).some((f) => !f.is_hidden);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../../../sass/_vue-import.scss';

.dashboard-wrapper {
  @include desktop {
    > *, &, .funeral-list {
      max-height: calc(100vh - (32px * 2) - 49px);
    }

    .funeral-list {
      overflow: auto;
    }
  }

  @include not-desktop {
    overflow: auto;

    .funeral-list {
      overflow: none;
    }
  }
}

.funeral-list {
  padding: 1rem;
}
</style>
