<template>
    <div class="files">
        <div class="d-flex justify-content-between mb-2">
            <p class="m-0">Files</p>
            <p class="m-0">{{ activeFilesList.length }} / 5</p>
        </div>

        <div class="alert alert-danger" v-if="fileValidationHtml" v-html="fileValidationHtml"></div>
        <div class="alert alert-success" v-if="fileUploadSuccessful">File processed successfully!</div>
        <ChatLoader v-if="fileProcessing" />

        <div v-if="!fileProcessing">
            <div class="d-flex align-items-center my-2" v-for="(file, index) in activeFilesList" :key="`file_${index}`">
                <p class="my-0 mr-2 cursor-pointer" @click="removeFile(file)">
                    <FontAwesomeIcon :icon="iconTrashAlt" />
                </p>
                <div>
                    <a :href="file.secure_file_path" title="Internal link to file path" >{{ file.file_name }}</a>
                    <p class="m-0 lh-xs"><small>Expires {{ timeUntilExpiration(file.uploaded_date) }}</small></p>
                </div>
            </div>
        </div>

        <form enctype="multipart/form-data" @submit.prevent="uploadNewFile" v-if="activeFilesList.length < 5">
            <div class="form-group mb-1">
                <label for="upload_new_file">Upload New File</label>
                <input
                    class="form-control-file"
                    type="file"
                    name="upload_file"
                    id="upload_new_file"
                    title="Upload New File"
                    @change="validateFile"
                />
            </div>
            <p class="lh-xs"><small>Files will only be retained 1 week after upload.</small></p>
            <button
                class="btn btn-sm btn-primary"
                v-if="fileIsValid && !fileProcessing"
            >Upload file</button>
        </form>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { mapState, mapActions } from 'vuex';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export default {
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            fileValidationHtml: '',
            fileIsValid: false,
            fileProcessing: false,
            fileUploadSuccessful: false,
            iconTrashAlt: faTrashAlt
        }
    },
    computed: {
        ...mapState('chat/chat', {
            showFileManager: state => state.show_file_manager,
            activeChatRoomId: state => state.active_chat_room_id,
            activeFilesList: state => state.active_files_list,
        })
    },
    methods: {
        ...mapActions('chat/chat', [
            'sendChatMessage',
            'setActiveFilesList'
        ]),
        validateFile() {
            let file = event.target.files[0];
            let fileSize = file.size / 1000000;
            this.fileValidationHtml = '';
            this.fileIsValid = false;
            this.fileUploadSuccessful = false;

            if (fileSize > 4) {
                event.target.value = '';
                return this.fileValidationHtml = 'File size is over 4MB, please consider sending document in an email.';
            }

            return this.fileIsValid = true;
        },
        uploadNewFile() {
            const vm = this;
            const thisEvent = event;

            let formData = new FormData(thisEvent.target);
            formData.append('chat_id', this.activeChatRoomId);

            this.fileProcessing = true;
            this.fileIsValid = false;
            this.fileUploadSuccessful = false;
            this.fileValidationHtml = "";

            axios.post('/dashboard/messenger/upload-file', formData)
                .then(res => {
                    this.fileProcessing = false;
                    this.fileUploadSuccessful = true;
                    thisEvent.target.reset();

                    this.setActiveFilesList(res.data.files)
                    this.sendChatMessage('A file was shared open the file manager to download');
                })
                .catch(error => {
                    console.error(error);

                    this.fileProcessing = false;
                    this.fileIsValid = true;
                    this.fileUploadSuccessful = false;

                    if (error.response.status == 422) {
                        let errorList = document.createElement('ul');

                        let errorArray = error.response.data.errors.upload_file;
                        for (let index = 0; index < errorArray.length; index++) {
                            const errorLine = errorArray[index];
                            errorList.insertAdjacentHTML('afterbegin', `<li>${errorLine}</li>`)
                        }

                        this.fileValidationHtml = errorList.outerHTML;
                    } else {
                        logApplicationError(error);
                        this.fileValidationHtml = "The file could not be uploaded, please try again later.";
                    }

                })
        },
        removeFile(file) {
            const vm = this;
            this.fileProcessing = true;

            axios.post('/dashboard/messenger/delete-file', {file_id: file.id})
                .then(res => {
                    this.fileProcessing = false;
                    this.fileUploadSuccessful = true;

                    this.setActiveFilesList(res.data.files)
                })
                .catch(error => {
                    this.fileProcessing = false;
                    this.fileIsValid = true;
                    this.fileUploadSuccessful = false;
                    this.fileValidationHtml = "The file could not be deleted, please try again later.";

                    console.error(error);
                    logApplicationError(error);
                })

        },
        timeUntilExpiration(uploadDate) {
            let expiration = dayjs(uploadDate).add(1, 'w');
            return expiration.fromNow();
        }
    }
}
</script>

<style lang="scss" scoped>
 //   @import '../../../../../sass/_vue-import.scss';

    .files {
        padding: 1rem;
        height: 100%;
        background-color: white;
    }

    form {
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 1px solid #ddd;
    }

    // @include not-mobile {
    //     .files {
    //         width: 300px;
    //         margin-left: auto;
    //         border-left: 1px solid #ddd;
    //     }
    // }
</style>