<template>
	<div class="vp-tab-nav">
		<router-link
			:to="{ name: 'PortalIndex' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the user portal page"
			exact
        >Portal</router-link>
        <router-link
			to="/vpanel/modules/cart"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the obituary page"
			exact
        >Cart</router-link>
    </div>
</template>

<script>
export default {
	name: "PortalSecondaryNav",
};
</script>