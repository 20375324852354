<template>
	<form
		id="add-modify-funeral-form"
		class="funeral-form needs-validation"
		name="add-modify-funeral-form"
		novalidate
		@submit.prevent="submitForm"
	>
		<div class="form-errors"></div>
		<input
			type="hidden"
			name="_token"
			:value="activeRecord._token"
		/>
		<input
			type="hidden"
			name="id"
			:value="activeRecord.id"
			v-if="!isActionAdd"
		/>
		<div class="d-flex flex-column justify-content-between align-items-stretch h-100">
			<div class="document-form-fields d-flex flex-column align-items-stretch">
				<h5>Basic Information</h5>

				<div class="form-group has-label">
					<div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
						<label for="title">First Name <span class="required">*</span></label>
					</div>
					<input
						type="text"
						class="form-control"
						name="firstname"
						id="firstname"
						v-model="activeRecord.funeral_bio.first_name"
						title="Enter a first name"
						required
					/>
					<p class="invalid-feedback">Please enter a first name.</p>
				</div>
				<div class="form-group has-label">
					<div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
						<label for="title">Last Name <span class="required">*</span></label>
					</div>
					<input
						type="text"
						class="form-control"
						name="firstname"
						id="firstname"
						v-model="activeRecord.funeral_bio.last_name"
						title="Enter a last name"
						required
					/>
					<p class="invalid-feedback">Please enter a last Name.</p>
				</div>

				<div class="form-group flex-column">
					<select
						id="branch"
						name="branch"
						v-model="activeRecord.branch"
						class="form-control"
						title="Please select branch."
                        required
					>
						<option
							v-for="(branch, index) in branches"
							:key="index"
							:value="branch.uuid"
						>{{ branch.name }}</option>
					</select>
					<label for="branch">Branch <span class="required">*</span></label>
					<p class="invalid-feedback">Please select a branch.</p>
				</div>
				<div class="form-group flex-grow-1">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<span class="required mr-3">Active?</span>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Please select a status for this funeral."
						><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
					</div>
					<fieldset>
						<legend class="label sr-only">Active?</legend>
						<div class="form-check form-check-inline form-check-radio">
							<label
								class="form-check-label"
								for="active-false"
							>
								<input
									class="form-check-input"
									type="radio"
									title="is_active"
									required
									name="is_active"
									id="active-false"
									value="0"
									v-model="activeRecord.is_active"
									data-invalid-feedback="Please select visibility for this funeral."
								/>
								<span class="form-check-sign"></span>
								Inactive
							</label>
						</div>

						<div class="form-check form-check-inline form-check-radio">
							<label
								class="form-check-label"
								for="active-true"
							>
								<input
									class="form-check-input"
									title="is_active"
									type="radio"
									name="is_active"
									id="active-true"
									value="1"
									v-model="activeRecord.is_active"
									data-invalid-feedback="Please select a visibility status for this funeral."
								/>
								<span class="form-check-sign"></span>
								Active
								<div class="invalid-feedback">
									Please select visibility for this funeral.
								</div>
							</label>
						</div>
					</fieldset>
				</div>

			</div>
		</div>
		</div>
	</form>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
	name: "AddModifyFuneralForm",
	props: ["isActionAdd"],

	computed: {
		getHeader() {
			let action = this.isActionAdd ? "Add a New " : "Modify ";
			return action + "Funeral";
		},
		...mapState("funeral/list", {
			// moduleConfig: state => state.moduleConfig,
			// cspNonce: state => state.cspNonce
		}),
		...mapState("funeral/addModify", {
			activeRecord: (state) => state.activeRecord,
			isFormProcessing: (state) => state.isFormProcessing,
			didSaveDraft: (state) => state.didSaveDraft,
		}),
		...mapState("obituary/list", {
			branches: (state) => state.branches,
		}),

		...mapState("funeral/list", {
			funerals: (state) => state.tableData,
		}),
	},
	methods: {},
};
</script>
